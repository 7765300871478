import { action, observable, toJS } from 'mobx';
import debounce from 'lodash/debounce';

import store from 'client/store';

export const URL = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address';
export const SUGGEST_COUNT = 10;

export default class SearchStore {

	@observable searchStr = '';
	@observable active = false;
	@observable isLoading = false;
	@observable fiasAddress = null;
	@observable currentAddress = null;
	@observable currentCase = null;
	@observable cases = [];
	@observable step = 1;
	@observable suggestions = [];
	@observable page = 1;
	
	constructor() {
		this.doSearch = debounce(this.doSearch, 500, { leading: true, trailing: true });
	}
	
	@action onChange = e => {
		this.searchStr = e.target.value;
		this.fiasAddress = null;
		this.currentAddress = null;
		this.cases = [];
		this.step = 1;
		this.checkActive();
		this.doSearch();
	}
	
	checkActive = () => {
		this.active = this.searchStr.trim().length > 0;
	};
	
	select = async obj => {
		console.log('select', toJS(obj));
		this.isLoading = true;
		if (obj.__type === 'case') {
			this.active = false;
			store.route.push({ path: `/cases/${obj.id}` });
			return;
			this.currentCase = await store.model.Case.findById(obj.id, {
				include: [
					{
						relation: 'logs',
						scope: {
							order: 'date desc',
						}
					},
					{ relation: 'address' },
				],
			});
			this.currentAddress = this.currentCase.address;
			console.log('currentAddress', this.currentAddress);
			if (this.currentAddress && this.currentAddress.geo && store.map.map) {
				store.map.gotoPoint(this.currentAddress.geo.coordinates);
			}
			this.fiasAddress = null;
			if (this.loadCase) this.loadCase();
			await this.getCases();
			this.step = 3;
		}
		else if (obj.__type === 'fias') {
			const res = await this.getFromFias(obj['unrestricted_value'], 1);
			this.fiasAddress = res.length > 0 ? res[0] : null;
			this.currentAddress = null;
			this.step = 2;
		}
		else {
			this.fiasAddress = null;
			this.currentAddress = obj;
			await this.getCases();
			this.step = 2;
		}
		if (this.currentAddress && this.currentAddress.geo && store.map.map) {
			store.map.gotoPoint(this.currentAddress.geo.coordinates && store.map.map);
		}
		if (this.fiasAddress && this.fiasAddress.data['geo_lat'] && this.fiasAddress.data['geo_lon'] && store.map.map) {
			const lon = parseFloat(this.fiasAddress.data['geo_lon']);
			const lat = parseFloat(this.fiasAddress.data['geo_lat']);
			store.map.gotoPoint([lon, lat]);
		}
		this.isLoading = false;
		// console.log('fiasAddress', toJS(this.fiasAddress));
		// console.log('currentAddress', toJS(this.currentAddress));
	};
	
	@action back = e => {
		e.preventDefault();
		if (this.step > 1) this.step--;
		setTimeout(this.reset, 250);
	};
	
	@action reset = () => {
		this.page = 1;
		if (this.step === 1) {
			this.cases = [];
			this.fiasAddress = null;
			this.currentAddress = null;
		}
		else if (this.step === 2) {
			this.currentCase = null;
		}
	};
	
	doSearch = async () => {
		this.isLoading = true;
		const trimmed = this.searchStr.trim();
		const words = trimmed.replace(',', ' ').replace(/\s+/g, ' ').split(' ');
		// console.log('doSearch', trimmed);
		this.page = 1;
		
		let suggestions = await store.model.ViewCase.find({
			where: {
				or: [
					{ addressName: { ilike: `%${words.join('%')}%` }},
					{ phone: { ilike: `${trimmed}%` }},
				]
			},
			limit: 100,
		});
		suggestions.forEach(s => s.__type = 'case');
		
		this.suggestions = suggestions;
		this.isLoading = false;
	};
	
	onPageChange = page => this.page = page;
	
	getFromFias = async (query, count = 10) => {
		const data = { query, count };
		try {
			const res = await fetch(URL, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
					'Authorization': `Token ${store.fiasApiKey}`,
				},
				body: JSON.stringify(data),
			});
			const json = await res.json();
			// console.log('json', json);
			json.suggestions.forEach(s => s.__type = 'fias');
			// console.log('json.suggestions', json.suggestions);
			return json.suggestions;
		}
		catch(e) {
			console.error(e);
		}
		return [];
	};
	
	createAddress = async obj => {
		// console.log('createAddress', toJS(obj));
		this.isLoading = true;

		const address = new store.model.Address();
		address.name = obj['unrestricted_value'];
		address.street = obj.data.street;
		address.building = obj.data.house;
		const lat = parseFloat(obj.data['geo_lat']);
		const lon = parseFloat(obj.data['geo_lon']);
		address.geo = { type: 'Point', coordinates: [lon, lat] };

		const cities = await store.model.City.find({ where: { name: obj.data.city }});
		if (cities.length > 0) {
			address.city = cities[0];
		}
		else {
			const city = new store.model.City();
			city.name = obj.data.city;
			await city.save();
			address.city = city;
		}

		let where = { name: { ilike: `%${obj.data['area_with_type']}%` }};
		if (obj.data.city === 'Якутск' || obj.data.city === 'Жатай') {
			where = { name: { ilike: `%${obj.data.city}%` }};
		}
		const counties = await store.model.County.find({ where });
		if (counties.length > 0) {
			address.county = counties[0];
		}
		else {
			console.warn('County not found!', obj.data['area_with_type']);
/*
			const county = new store.model.County();
			county.name = obj.data['area_with_type'];
			await county.save();
			address.county = county;
*/
		}
		
		await address.save();
		this.doSearch();
		
		this.fiasAddress = null;
		this.currentAddress = address;
		this.cases = [];

		this.isLoading = false;
	};
	
	getCases = async () => {
		if (!this.currentAddress) return;
		this.cases = await this.currentAddress.cases.find({
			include: [
				{
					relation: 'logs',
					scope: {
						order: 'date desc',
					}
				},
			],
		});
	};
	
	@action gotoAddress = async (address) => {
		console.log('gotoAddress', toJS(address));
		this.active = true;
		this.isLoading = true;
		this.currentAddress = address;
		await this.getCases();
		this.currentCase = null;
		this.step = 2;
		this.isLoading = false;
	};
	
	@action gotoCase = async (_case) => {
		console.log('gotoCase', toJS(_case));
		this.active = true;
		this.isLoading = true;
		this.fiasAddress = null;
		if (!this.currentAddress) {
			this.currentAddress = await _case.address;
			await this.getCases();
		}
		this.currentCase = _case;
		this.step = 3;
		this.isLoading = false;
		if (this.loadCase) this.loadCase();
	};
	
	createCase = async () => {
		console.log('createCase', this.currentAddress);
		this.currentCase = new store.model.Case();
		this.currentCase.addressId = this.currentAddress.id;
		this.currentCase.cityId = this.currentAddress.cityId;
		this.currentCase.countyId = this.currentAddress.countyId;
		const now = new Date();
		this.currentCase.date = now;
		this.fiasAddress = null;
		this.step = 3;
		if (this.loadCase) this.loadCase();
	};
	
}
