import IconDefault from 'client/img/icons/marker.svg';
import LayerAutoServiceDefault from 'client/img/icons/layers/Автосервисы.svg';
import LayerAutoServiceAzs from 'client/img/icons/layers/Автосервисы_АЗС.svg';
import LayerAutoServiceParking from 'client/img/icons/layers/Автосервисы_Стоянка (парковка) автомобилей.svg';
import LayerAgricultureBird from 'client/img/icons/layers/Агрокультура_Птицефабрика.svg';
import LayerAgricultureCompany from 'client/img/icons/layers/Агрокультура_Предприятие по разведению молочного крупного рогатого скота, производство сырого моло.svg';
import LayerAuthorityServiceVet from 'client/img/icons/layers/Административные здания_Ветеринарная лечебница.svg';
import LayerAuthorityServiceBuilding from 'client/img/icons/layers/Административные здания_Административное здание.svg';
import LayerAuthorityServiceShop from 'client/img/icons/layers/Административные здания_Административное здание-1.svg';
import LayerAuthorityServiceWorkshop from 'client/img/icons/layers/Административные здания_Мастерская.svg';
import LayerAirTransportObjAir from 'client/img/icons/layers/Аэропорты.svg';
import LayerAirTransportObjLanding from 'client/img/icons/layers/Аэропорты_Посадочная площадка.svg';
import LayerBathObjIcon from 'client/img/icons/layers/Баня.svg';
import LayerRecreationIcon from 'client/img/icons/layers/Гостиницы.svg';
import LayerThermalFacilityIcon from 'client/img/icons/layers/Источник тепловой энергии.svg';
import LayerThermalFacilityBoiler from 'client/img/icons/layers/Источник тепловой энергии_Котельная.svg';
import LayerSewerFacilityKOC from 'client/img/icons/layers/Канализационный объект_Очистные сооружения (КОС).svg';
import LayerSewerFacilityKHC from 'client/img/icons/layers/Канализационный объект_КНС2.svg';
import LayerCemeteryIcon from 'client/img/icons/layers/Кладбища.svg';
import LayerShopObjIcon from 'client/img/icons/layers/Магазины.svg';
import LayerHealthB from 'client/img/icons/layers/Медицинская организация_Больница.svg';
import LayerHealthOrg from 'client/img/icons/layers/Медицинская организация_Лечебно-профилактическая медицинская организация.svg';
import LayerHealthPol from 'client/img/icons/layers/Медицинская организация_Поликлиника.svg';
import LayerHealthCentral from 'client/img/icons/layers/Медицинская организация_Центральная районная больница.svg';
import LayerPublicIcon from 'client/img/icons/layers/Места публичного отдыха.svg';
import LayerEnvMonitoringS1 from 'client/img/icons/layers/Метеостанции_Электростанция_Электростанция солнечная (СЭС)-1.svg';
import LayerEnvMonitoringS from 'client/img/icons/layers/Метеостанции_Электростанция_Электростанция солнечная (СЭС).svg';
import LayerBridgeObjIcon from 'client/img/icons/layers/Мост.svg';
import LayerOilFacilityIcon from 'client/img/icons/layers/Нефтебазы.svg';
import LayerOilFacilityAzs from 'client/img/icons/layers/Нефтебазы_АЗС.svg';
import LayerEducationIcon from 'client/img/icons/layers/Образовательные учреждения.svg';
import LayerEducationChild from 'client/img/icons/layers/Образовательные учреждения_Дошкольная образовательная организация.svg';
import LayerTelecomFacilityBase from 'client/img/icons/layers/Объект связи_Базовая станция.svg';
import LayerTelecomFacilityMobile from 'client/img/icons/layers/Объект связи_Вышка сотовой связи.svg';
import LayerTelecomFacilityMail from 'client/img/icons/layers/Объект связи_Объекты почтовой связи.svg';
import LayerTelecomFacilityDigital from 'client/img/icons/layers/Объект связи_Цифровое телевидение.svg';
import LayerHeritageIcon from 'client/img/icons/layers/Объекты культурного наследия.svg';
import LayerCultureIcon from 'client/img/icons/layers/Объекты культурно-досугового типа.svg';
import LayerHairSalonObjIcon from 'client/img/icons/layers/Парикмахерские.svg';
import LayerWaterTransportObjIcon from 'client/img/icons/layers/Причал.svg';
import LayerManufacturingCompany from 'client/img/icons/layers/Производственный объект_Предприятие микробиологической, пищевой, пищевкусовой промышленности.svg';
import LayerManufacturingSXPK from 'client/img/icons/layers/Производственный объект_СХПК _Байылыат_.svg';
import LayerManufacturingMaterials from 'client/img/icons/layers/Производственный объект_Цех по изготовлению стройматериалов.svg';
import LayerSaunaObjIcon from 'client/img/icons/layers/Сауна.svg';
import LayerEmergencyServiceObjIcon from 'client/img/icons/layers/Служба спасения.svg';
import LayerSocialIcon from 'client/img/icons/layers/Социальные объекты.svg';
import LayerSportIcon from 'client/img/icons/layers/Спортивные объекты.svg';
import LayerSportArea from 'client/img/icons/layers/Спортивные объекты_Спортплощадка.svg';
import LayerSportStadium from 'client/img/icons/layers/Спортивные объекты_Стадион.svg';
import LayerTransportObjIcon from 'client/img/icons/layers/Транспортные объекты_Мост.svg';
import LayerElectricTransformerIcon from 'client/img/icons/layers/Трансформаторная подстанция.svg';
import LayerElectricPowerStationDes from 'client/img/icons/layers/Электростанция_Электростанция дизельная (ДЭС).svg';
import LayerElectricPowerStationSes from 'client/img/icons/layers/Электростанция_Электростанция солнечная (СЭС).svg';

export const layerIcon = {
	default: IconDefault,
	LayerAutoService: {
		default: LayerAutoServiceDefault,
		АЗС: LayerAutoServiceAzs,
		'Стоянка (парковка) автомобилей': LayerAutoServiceParking,
	},
	LayerAgriculture: {
		default: LayerAgricultureCompany,
		Птицефабрика: LayerAgricultureBird,
	},
	LayerAuthorityService: {
		default: LayerAuthorityServiceBuilding,
		Ветлечебница: LayerAuthorityServiceVet,
		'Ветеринарная лечебница': LayerAuthorityServiceVet,
		Магазин: LayerAuthorityServiceShop,
		'Объекты торговли': LayerAuthorityServiceShop,
		'Объекты торговли, общественного питания': LayerAuthorityServiceShop,
		'Мастерская МУП "Оленекский"': LayerAuthorityServiceWorkshop,
	},
	LayerAirTransportObj: {
		Аэропорт: LayerAirTransportObjAir,
		'Посадочная площадка': LayerAirTransportObjLanding,
	},
	LayerBathObj: {
		default: LayerBathObjIcon,
	},
	LayerRecreation: {
		default: LayerRecreationIcon,
	},
	LayerThermalFacility: {
		default: LayerThermalFacilityIcon,
		Котельная: LayerThermalFacilityBoiler,
	},
	LayerSewerFacility: {
		default: LayerSewerFacilityKOC,
		'КНС1': LayerSewerFacilityKHC,
		'КНС2': LayerSewerFacilityKHC,
	},
	LayerCemetery: {
		default: LayerCemeteryIcon
	},
	LayerShopObj: {
		default: LayerShopObjIcon
	},
	LayerHealth: {
		default: LayerHealthOrg,
		'Центральная районная больница':LayerHealthCentral,
		'Больница': LayerHealthB,
		'Поликлиника': LayerHealthPol,
	},
	LayerPublic: {
		default: LayerPublicIcon
	},
	LayerEnvMonitoring: {
		default: LayerEnvMonitoringS,
		'Метеостанция': LayerEnvMonitoringS1,
	},
	LayerBridgeObj: {
		default: LayerBridgeObjIcon
	},
	LayerOilFacility: {
		default: LayerOilFacilityIcon,
		'АЗС1': LayerOilFacilityAzs
	},
	LayerEducation: {
		default: LayerEducationIcon,
		'Дошкольная образовательная организация': LayerEducationChild
	},
	LayerTelecomFacility: {
		default: LayerTelecomFacilityBase,
		'Почта': LayerTelecomFacilityMail,
		'Объекты почтовой связи': LayerTelecomFacilityMail,
		'Цифровое телевидение': LayerTelecomFacilityDigital,
		'Вышка сотовой связи': LayerTelecomFacilityMobile,
	},
	LayerHeritage: {
		default: LayerHeritageIcon
	},
	LayerCulture: {
		default: LayerCultureIcon
	},
	LayerHairSalonObj: {
		default: LayerHairSalonObjIcon
	},
	LayerWaterTransportObj: {
		default: LayerWaterTransportObjIcon
	},
	LayerManufacturing: {
		default: LayerManufacturingCompany,
		'СХПК "Байылыат"': LayerManufacturingSXPK,
		'Цех по изготовлению стройматериалов': LayerManufacturingMaterials
	},
	LayerSaunaObj: {
		default: LayerSaunaObjIcon
	},
	LayerEmergencyServiceObj: {
		default: LayerEmergencyServiceObjIcon
	},
	LayerSocial: {
		default: LayerSocialIcon
	},
	LayerSport: {
		default: LayerSportIcon,
		'Спортплощадка': LayerSportArea,
		'Стадион': LayerSportStadium
	},
	LayerTransportObj: {
		default: LayerTransportObjIcon
	},
	LayerElectricTransformer: {
		default: LayerElectricTransformerIcon
	},
	LayerElectricPowerStation: {
		default: LayerElectricPowerStationDes,
		'СЭС': LayerElectricPowerStationSes,
		'Солнечная электростанция': LayerElectricPowerStationSes,
		'Электростанция солнечная (СЭС)': LayerElectricPowerStationSes,
	}
};
