import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Loader, Row } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import { TextField } from 'smartDistrict/components';

@observer
export default class Popup extends React.Component {
	
	@observable record = null;
	
	constructor(props) {
		super(props);
		this.init();
	}
	
	init = async () => {
		this.record = await store.model.LayerHairSalonObj.findById(this.props.record.id);
	};
	
	gotoRecord = (e) => {
		e.preventDefault();
		store.route.push({ path: `/smartdistrict/dictionaries/layerhairsalonobj/${this.record.id}` });
	};
	
	render() {
		if (!this.record) return <Loader size={18} />;

		return <>
			<div className="title">
				<h2>{t('LayerHairSalonObj.title')}: <span className='id' onClick={this.gotoRecord} >#{this.record.id}</span></h2>
			</div>
			<Row>
				<TextField label={t('name')} value={this.record.name} />
				<TextField label={t('description')} value={this.record.description} />
			</Row>
			<Row>
				<TextField label={t('phoneNumber.plural')} value={this.record.phoneNumber} />
				<TextField label={t('address.title')} value={this.record.address} />
			</Row>
			<Row>
				<TextField label={t('schedule')} value={this.record.schedule} />
			</Row>
		</>;
	}
	
}
