import React from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { format, endOfDay } from 'date-fns';
import Chart from 'chart.js';

import t from 'i18n';

@inject('dashboardStore') @observer
export default class Dynamics extends React.Component {

	loaded = false;

	onChart1Mount = el => {
		if (!this.props.dashboardStore.data) return;
		if (!el) {
			if (this.chart) this.chart.destroy();
			return;
		}

		const { type } = this.props;
		
		const style = {
			// borderWidth: 0,
			// pointRadius: 0.5,
			// barPercentage: 0.9,
			// categoryPercentage: 1.0,
			// barThickness: 40,
		};

		const days = this.props.dashboardStore.data.dynamics[type] || [];

		const datasets = [
			{
				data: days.map(day => day.activeCount),
				label: t('dashboard.active'),
				lineTension: 0,
				borderColor: "#d00",
				// backgroundColor: 'rgba(221,0,0, 0.2)',
				backgroundColor: 'rgba(221,0,0, 0)',
				...style,
			},
			{
				data: days.map(day => day.extinguishedCount),
				label: t('dashboard.extinguished'),
				borderColor: "#080",
				lineTension: 0,
				borderDash: [5, 5],
				// backgroundColor: 'rgba(68,204,0, 0.5)',
				backgroundColor: 'rgba(68,204,0, 0)',
				...style,
			},
		];

		el.parentNode.scrollLeft = el.scrollWidth;

		this.chart = new Chart(el, {
			type: 'line',
			data: {
				labels: days.map((day, i) => {
					const date = new Date(day.day);
					// return date.getDay() === todaysDay ? format(date, 'dd.MM') : '';
					return format(date, 'dd.MM');
				}),
				datasets,
			},
			options: {
				events: [],
				tooltips: {
					enabled: false,
				},
				hover: {
					mode: null,
				},
				responsive: false,
				maintainAspectRatio: false,
				animation: {
					duration: 0,
					onComplete: function () {
						if (!this.loaded) {
							this.loaded = true;
							const ctx = this.chart.ctx;
							ctx.font = 'Roboto';
							ctx.textAlign = "center";
							ctx.textBaseline = "bottom";
							this.data.datasets.forEach(function (dataset) {
								const key = Object.keys(dataset._meta)[0];
								const data = dataset._meta && dataset._meta[key] ? dataset._meta[key].data : null;
								// console.log('dataset', dataset);
								if (!data) return;
								ctx.fillStyle = dataset.borderColor;
								dataset.data.forEach((value, i) => {
									const view = data[i]._view;
									// console.log(i, 'view', view);
									ctx.fillText(value, view.x, view.y - 10);
								});
							})
						}
					},
				},
				layout: {
					padding: {
						top: 30,
						bottom: 20,
					},
				},
				legend: {
					display: false,
				},
				title: {
					display: false,
					text: t('dashboard.firesCount'),
				},
				scales: {
					xAxes: [
						{
							gridLines: {
								display: false,
								drawBorder: false,
							},
							ticks: {
								display: true,
								autoSkip: true,
								maxRotation: 0,
								minRotation: 0
							}
						}
					],
					yAxes: [
						{
							gridLines: {
								display: true,
								drawBorder: false,
							},
							ticks: {
								display: false,
								beginAtZero: true,
								callback: function(value) {if (value % 1 === 0) {return value;}}
							},
						}
					]
				}
			},
		});
	}

	onChart2Mount = el => {
		if (!this.props.dashboardStore.data) return;
		if (!el) {
			if (this.areaChart) this.areaChart.destroy();
			return;
		}

		const data = this.props.dashboardStore.data;
		
		const style = {
			borderWidth: 0,
			pointRadius: 0.5,
			barPercentage: 0.9,
			categoryPercentage: 1.0,
			// barThickness: 40,
		};
		
		const { type } = this.props;
		const days = data.dynamics[type] || [];

		const datasets = [
			{
				data: days.map(day => day.activeArea),
				label: t('dashboard.active'),
				borderColor: "#d00",
				backgroundColor: 'rgba(221,0,0, 1)',
				...style,
			},
			{
				data: days.map(day => day.extinguishedArea),
				label: t('dashboard.extinguished'),
				borderColor: "#080",
				borderDash: [5, 5],
				backgroundColor: 'rgba(68,204,0, 1)',
				...style,
			},
		];

		el.parentNode.scrollLeft = el.scrollWidth;

		const allAreas = [
			...data.dynamics.forest.map(day => day.activeArea),
			...data.dynamics.oopt.map(day => day.activeArea),
			...data.dynamics.other.map(day => day.activeArea),
			...data.dynamics.forest.map(day => day.extinguishedArea),
			...data.dynamics.oopt.map(day => day.extinguishedArea),
			...data.dynamics.other.map(day => day.extinguishedArea),
		];
		const maxArea = Math.max.apply(null, allAreas);
		console.log('maxArea', maxArea);

		this.areaChart = new Chart(el, {
			type: 'bar',
			data: {
				labels: days.map((day, i) => {
					const date = new Date(day.day);
					// return date.getDay() === todaysDay ? format(date, 'dd.MM') : '';
					return format(date, 'dd.MM');
				}),
				datasets,
			},
			options: {
				events: [],
				tooltips: {
					enabled: false,
				},
				hover: {
					mode: null,
				},
				responsive: false,
				maintainAspectRatio: false,
				animation: {
					duration: 0,
					onComplete: function () {
						if (!this.loaded) {
							this.loaded = true;
							const ctx = this.chart.ctx;
							ctx.font = 'Roboto';
							ctx.textAlign = "center";
							ctx.textBaseline = "bottom";
							this.data.datasets.forEach(function (dataset) {
								const key = Object.keys(dataset._meta)[0];
								const data = dataset._meta && dataset._meta[key] ? dataset._meta[key].data : null;
								// console.log('dataset', dataset);
								if (!data) return;
								ctx.fillStyle = dataset.borderColor;
								dataset.data.forEach((value, i) => {
									const view = data[i]._view;
									// console.log(i, 'view', view);
									if (value > 0) ctx.fillText(value.toFixed(1), view.x, view.y - 5);
								});
							})
						}
					},
				},
				legend: {
					display: false
				},
				layout: {
					padding: {
						top: 30,
						// bottom: 20,
					},
				},
				title: {
					display: false,
					text: t('dashboard.totalArea'),
				},
				scales: {
					xAxes: [
						{
							ticks: {
								autoSkip: true,
								maxRotation: 0,
								minRotation: 0
							}
						}
					],
					yAxes: [
						{
							gridLines: {
								display: true,
								drawBorder: false,
							},
							ticks: {
								beginAtZero: true,
								max: maxArea * 1.1,
							},
							afterTickToLabelConversion: function(scaleInstance) {
								scaleInstance.ticks[0] = null;
								// scaleInstance.ticks[scaleInstance.ticks.length - 1] = null;
								
								scaleInstance.ticksAsNumbers[0] = null;
								// scaleInstance.ticksAsNumbers[scaleInstance.ticksAsNumbers.length - 1] = null;
							}
						}
					]
				}
			},
		});
	}

	render() {
		const { dashboardStore, type } = this.props;

		return <div className="dynamics">
			<h2>{t(`dashboard.dynamicsType.${type}`)}</h2>
			{!dashboardStore.isLoading && <>
				<div className="fires-count">
					<div className="fires-count-chart">
						<canvas ref={this.onChart1Mount} width={640} height={200} />
					</div>
					<div className="legend">
						<span className="color red" /> {t('dashboard.active')}
						<span className="color green" /> {t('dashboard.extinguished')}
					</div>
				</div>
				<div className="fires-area">
					<div className="fires-area-chart">
						<canvas ref={this.onChart2Mount} width={640} height={300} />
					</div>
					<div className="legend">
						<span className="color red" /> {t('dashboard.activeArea')}
						<span className="color green" /> {t('dashboard.extinguishedArea')}
					</div>
				</div>
			</>}
		</div>;
	}
	
}
