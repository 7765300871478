import qs from 'qs';
import { createBrowserHistory } from 'history';
import { observable, toJS } from 'mobx';
import i18next from 'i18next';

import { Rest, ModelStore } from '@smartplatform/model-store';
import { RouteStore } from '@smartplatform/route-store';
import { LocalStore } from '@smartplatform/local-store';
import { localSettings } from '@smartplatform/map';

import SearchStore from './components/search/store';
import UIStore from './uiStore';
import { getCurrentModule } from 'client/tools';
import { defaultsDeep } from "lodash";

/**
 * Глобальный контейнер сторов
 */
export class AppStore {

	local; // Локальное хранилище
	model; // Хранилище моделей
	route; // Хранилище маршрута браузера
	api; // API обмена с сервером
	admin; // Хранилище @admin
	ui; // ui
	config; // Конфигурация региона

	subscribers = [];
	
	fiasApiKey = '842709bce84734ee40403e8ce6ef9bb80e722fa5';

	@observable module = null;
	@observable isPublic = false;
	@observable appKey = 0; // key для обновления приложения

	constructor() {
		this.history = createBrowserHistory();
		this.ui = new UIStore();

		this.module = getCurrentModule();

		this.local = new LocalStore();
		this.local.extend({
			language: 'ru',
			itemsPerPage: 10,
		});
		this.local.save();

		this.rest = new Rest();
		this.model = new ModelStore({
			transport: this.rest,
			cache: false,
			autoConnect: false,
		});

		this.search = new SearchStore();
		
		this.route = new RouteStore({
			history: this.history,
			decode: qs.parse,
			encode: qs.stringify,
		});
	}

	init = async (options) => {
		i18next.changeLanguage(this.local.language || 'ru');
		this.config = APP_CONFIG;
		const { isPublic } = options || {};
		this.isPublic = isPublic;
		if (isPublic) {
			this.ui.topBarHeight = 0;
			this.ui.sideBarWidth = 0;
		}

		await this.model.connect();
		// await this.covid.init();
	};

	subscribeOnHMR = callback => {
		this.subscribers.push(callback);
	};
	
	unsubscribeOnHMR = callback => {
		const index = this.subscribers.findIndex(cb => cb === callback);
		if (index !== -1) this.subscribers.splice(index, 1);
	};
	
	fireHMR = () => {
		console.log('HMR!');
		this.subscribers.forEach(cb => cb());
	};
	
}

window.APP_STORE = new AppStore();

export default window.APP_STORE;
