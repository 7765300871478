import React from 'react';
import { observer, inject } from 'mobx-react';

import { Select } from '@smartplatform/ui';
import FilterLayout from './FilterLayout';
import Hint from './Hint';
import { silamLayers, gfsLayers } from '../weather';
import t from 'i18n';

@inject('settings') @observer
export default class WeatherFilter extends React.Component {

	setLayer = (code) => {
		console.log('set', code, this.props.layer);
		this.props.layer.setLayer(code);
	};

	render() {
		const weather = this.props.settings.weather;
		const weatherLayer = this.props.layer;

		const isSilamLayer = weatherLayer.isSilamLayer(weather.layer);
		const silamItems = Object.keys(silamLayers).map(code => ({ label: silamLayers[code].name, value: code }));

		const isLoading = weather.show && weatherLayer.layer ? weatherLayer.layer.isLoading : false;
		const error = weather.show && weatherLayer.layer ? weatherLayer.layer.error : undefined;

		return <FilterLayout layerName="weather" onToggle={this.props.toggleFunc} title={t('weather.title')} error={error} isLoading={isLoading}>
			{Object.keys(gfsLayers).map(code => <div key={code} className="radio-select">
				<input
					type="radio"
					name="weather-layer"
					checked={weather.layer === code}
					disabled={!weather.show}
					onChange={() => this.setLayer(code)}
				/>
				<span>{gfsLayers[code].name}</span>
				<Hint text={gfsLayers[code].description}/>
			</div>)}

			<div className="radio-select">
				<input
					type="radio"
					name="weather-layer"
					checked={isSilamLayer}
					disabled={!weather.show}
					onChange={() => this.setLayer('pm25')}
				/>
				<span>{t('silam.title')}</span>
				{isSilamLayer && <div className="sub-select">
					<Select
						items={silamItems}
						value={weather.layer}
						onChange={weatherLayer.setLayer}
						isRequired
						size="sm"
					/>
					<Hint text={silamLayers[weather.layer].description}/>
				</div>}
			</div>
		</FilterLayout>;
	}

}
