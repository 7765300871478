import React from 'react';
import { observer } from 'mobx-react';

import Header from './header/Header';
import SideBar from './sidebar/SideBar';
import './style.scss';

@observer
export default class Layout extends React.Component {

	render() {
		return <div className="main-layout">
			<Header />
			<SideBar />
			{this.props.children}
		</div>;
	}
	
}
