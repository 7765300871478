import React from 'react';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import { Table, Column, Pager, Button } from '@smartplatform/ui';
import store from '../../../store';
import t from '../../../../i18n';

const PER_PAGE = 20;

@observer
export default class List extends React.Component {
	@observable isLoading = true;
	@observable records = [];

	@observable filter = {
		order: 'id asc',
		skip: 0,
		limit: PER_PAGE,
		where: {
			_search: '',
		},
	};

	constructor(props) {
		super(props);
		this.init();
	}

	@action init = async () => {
		this.isLoading = true;
		this.records = await store.model.Organization.find(this.filter);
		this.isLoading = false;
	};

	@action onSearch = async (value) => {
		this.filter.where._search = value;
		this.records = await store.model.Organization.find(this.filter);
	};

	create = () => {
		this.props.history.push(`/admin/organizations/create`);
	};

	edit = (record) => {
		this.props.history.push(`/admin/organizations/${record.id}`);
	};

	reload = (e) => {
		e.preventDefault();
		this.init();
	};

	onPageChange = async (page) => {
		this.page = page;
		this.filter.skip = (this.page - 1) * PER_PAGE;
		this.init();
	};

	renderGeo = (organization) => JSON.stringify(organization.geo);

	render() {
		if (this.isLoading) return '...';

		return (
			<div className='block'>
				<div className='block-header'>Организации</div>
				<div className='table-list-panel'>
					<input type='text' value={this.search} onChange={(e) => this.onSearch(e.target.value)} placeholder='Поиск' />
					&nbsp;
					<Button onClick={this.create} className='btn-primary' icon={<FontAwesomeIcon icon={faPlus} />}>
						{t('create')}
					</Button>
					&nbsp;
					<Link to='#' onClick={(e) => this.reload(e)} className='btn-icon btn-link-type'>
						{<FontAwesomeIcon icon={faSyncAlt} spin={this.isLoading} />}
					</Link>
				</div>

				<div className='table-list-pager'>
					<Pager itemsPerPage={PER_PAGE} totalCount={this.records.totalCount} current={this.page} onChange={this.onPageChange} />
				</div>

				<Table className='table-list-table' rows={this.records} onRowClick={this.edit}>
					<Column width={50} property='id' label='ID' />
					<Column property='shortName' label='Наименование' />
					<Column property='name' label='Юридическое наименование' />
					<Column property='headName' label='Руководитель' />
					<Column property='inn' label='ИНН' />
					<Column property='kpp' label='КПП' />
					<Column property='address' label='Юридический адрес' />
					<Column property='geo' computed={this.renderGeo} label='Координаты' />
					<Column property='phoneNumber' label='Телефон' />
					<Column property='email' label='E-mail' />
					<Column property='site' label='Сайт' />
					<Column property='schedule' label='Режим работы' />
				</Table>
			</div>
		);
	}
}
