import React from 'react';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';

import {
	Form,
	Row,
	Field,
	FileInput,
	RelationSelect,
} from '@smartplatform/ui';

import store from '../../../store';
import t from '../../../../i18n';

@observer
export default class Edit extends React.Component {
	
	@observable record = null;
	@observable acls = [];
	@observable isRole = false;

	@observable password = '';
	@observable passwordRepeat = '';
	@observable errors = [];

	constructor(props) {
		super(props);
		this.loadData();
	}

	@action loadData = async() => {
		this.id = this.props.match.params.id;
		if (this.id) {
			this.record = await store.model.User.findById(this.id);
			console.log('user', this.record);
		}
		else {
			this.record = new store.model.User();
		}
	};

	@action changeAcls = (acls) => {
		this.acls = acls;
	};

	back = () => {
		this.props.history.push(`/admin/users`);
	};

	onChangePassword = e => this.password = e.target.value;
	onChangePasswordRepeat = e => this.passwordRepeat = e.target.value;

	validate = user => {
		this.errors = [];
		if (user.email.length === 0) {
			this.errors.push(t('email.input'));
		}
		if (user.id) {
			if (this.password.length > 0 && this.passwordRepeat.length > 0 && this.password !== this.passwordRepeat) {
				this.errors.push(t('password.noMatch'));
			}
		}
		else {
			if (this.password.length === 0) {
				this.errors.push(t('password.input'));
			}
			if (this.passwordRepeat.length === 0) {
				this.errors.push(t('password.repeat'));
			}
			if (this.password.length > 0 && this.passwordRepeat.length > 0 && this.password !== this.passwordRepeat) {
				if (this.password !== this.passwordRepeat) {
					this.errors.push(t('password.noMatch'));
				}
			}
		}
	};
	
	beforeSave = async user => {
		this.validate(user);
		if (this.errors.length === 0) {
			if (user.id) {
				if (this.password.length > 0) {
					user.password = this.password;
				}
				else {
					user.password = undefined;
				}
			}
			else {
				user.password = this.password;
			}
		}
		return this.errors.length === 0;
	};

	onSave = () => {
		this.back();
	};
	
	onClose = () => store.route.push({ path: '/' });
	
	render() {
		if (!this.record) return '...';

		let writeAllowed;

		if (this.id && store.model.user.id === this.id) {
			writeAllowed = true;
		}

		return <div className="block">
			{/*<div className="block-header">Пользователи</div>*/}
			<Form
				record={this.record}
				onDelete={this.back}
				stay
				writeAllowed={writeAllowed}
				beforeSave={this.beforeSave}
				onSave={this.onSave}>
				<Row>
					<Field property="lastName" label={t('user.lastName')}/>
					<Field property="firstName" label={t('user.firstName')}/>
					<Field property="middleName" label={t('user.middleName')}/>
				</Row>
				<Row>
					<Field property="username" label={t('user.name')}/>
					<Field property="email" required={true} label={t('user.email')}/>
					<Field property="emailVerified" label={t('user.emailVerified')}/>
				</Row>
				<Row>
					<Field property="telegram" label={t('user.telegram')}/>
					<Field relation="organization" property="name" label="Организация">
						<RelationSelect className="drop-down-md" />
					</Field>
					<div/>
				</Row>
				<Row>
					<div className="form-field password">
						<label>{t('password.new')}</label>
						<input
							type="password"
							autoComplete="new-password"
							value={this.password || ''}
							onChange={this.onChangePassword}
						/>
						<div className="password-hint">
							<i className="admin-icon-info-circled"/>
							{t('password.newHint')}
						</div>
					</div>
					<div className="form-field">
						<label>{t('password.repeat')}</label>
						<input
							type="password"
							value={this.passwordRepeat || ''}
							onChange={this.onChangePasswordRepeat}
						/>
					</div>
					<div/>
				</Row>

				 <FileInput property="avatar" label={t('user.avatar')} />
				{this.record.avatar && <div>
					<img src={this.record.downloadFile('avatar')} width={200} height={200} style={{ objectFit: 'contain' }} />
				</div>}
			</Form>

			<div className="form-errors">{this.errors.map((e, i) => <div key={i}>{e}</div>)}</div>
		</div>;
	}
}
