import React from 'react';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import { Table, Column, Select, Button, Pager } from '@smartplatform/ui';
import { fio } from 'client/tools';
import { DeleteButton } from 'components';
import store from 'client/store';
import t from 'i18n';
import data from './data';

const mapToSelect = row => ({ value: row.id, label: row.name });

const PER_PAGE = 20;

@observer
class RoleMappingList extends React.Component {

	@observable records = null;
	@observable roles = null;
	@observable users = null;
	@observable isLoading = false;
	@observable filter = {
		order: 'id asc',
		skip: 0,
		limit: PER_PAGE,
	};
	
	constructor(props) {
		super(props);
		store.ui.title = t('roleMapping.plural');
		this.init();
	}
	
	@action init = async () => {
		this.isLoading = true;
		this.records = await store.model.RoleMapping.find(this.filter);
		this.roles = await store.model.Role.find({ order: 'name asc' });
		this.users = await store.model.User.find();
		this.isLoading = false;
	};
	
	principalTypeComputed = (record) => {
		let principal = data.PRINCIPAL_TYPE.find(pt => pt.id === record.principalType);
		return principal ? principal.name : '-';
	};
	
	onPrincipalIdChange = (id, record) => {
		record.principalId = id;
		record.save();
	};
	
	onRowClick = record => {
		store.route.push({ path: `/admin/rolemappings/${record.id}` });
	};
	
	create = () => {
		store.route.push({ path: `/admin/rolemappings/create` });
	};
	
	reload = (e) => {
		e.preventDefault();
		this.init();
	};
	
	onPageChange = async (page) => {
		this.page = page;
		this.filter.skip = (this.page - 1) * PER_PAGE;
		this.init();
	};
	
	delete = async (record) => {
		await record.delete();
		this.init();
	};
	
	render () {
		if (!store.model.Role) {
			return <div>Access denied</div>;
		}
		
		if (this.isLoading) return '...';

		const types = {
			'ROLE': this.roles.map(role => ({ label: role.name, value: role.name })),
			'USER': this.users.map(user => ({ label: fio(user), value: '' + user.id })),
			'APP': [],
		};

		return <div className="fixed-page">
			<Button onClick={this.create} variant="primary" icon={<FontAwesomeIcon icon={faPlus}/>}>{t('create')}</Button>
			<Pager itemsPerPage={PER_PAGE} totalCount={this.records.totalCount} current={this.page} onChange={this.onPageChange} />
			
			<Table rows={this.records} className="table-list-table" onRowClick={this.onRowClick}>
				<Column property="id" label={t('ID')} width={30} />
				<Column computed={this.principalTypeComputed} clickable={true} property="principalType" label={t('acl.principalType')} />
				<Column property="principalId" label={t('acl.principalId')} saveOnChange>
					{({ record }) => {
						let principal = null;
						if (record.principalType) {
							principal = types[record.principalType].find(obj => obj.value === record.principalId)
						}
						return principal ? principal.label : '-';
					}}
				</Column>
				<Column relation="role" property="name" label={t('role.title')} />
				<Column clickable={false}>
					{({ record }) => <DeleteButton onConfirm={() => this.delete(record)} />}
				</Column>
			</Table>
		</div>
	}
}

export default RoleMappingList;
