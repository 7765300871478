import React from 'react';
import { observable, action } from 'mobx';
import { inject, observer } from 'mobx-react';

import { Form, Field, Select } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import data from './data';

@observer
export default class Edit extends React.Component {

	@observable record = null;
	@observable users = [];
	@observable isLoading = false;
	
	constructor (props) {
		super(props);
		this.init();
	}
	
	@action init = async () => {
		this.isLoading = true;
		
		if (this.props.match.params.id) {
			this.record = await store.model.RoleMapping.findById(this.props.match.params.id);
		} else {
			this.record = new store.model.RoleMapping();
		}
		
		this.users = await store.model.User.find();
		this.isLoading = false;
	};
	
	back = () => {
		this.props.history.push(`/admin/rolemappings`);
	};
	
	render () {
		if (this.isLoading) return '...';
		
		const users = this.users.map(u => {
			const fio = u.lastName ? u.lastName + ' ' + (u.firstName || '') + ' ' +(u.middleName || '') : '';
			const label = fio || u.username || u.email || u.id;
			return {value: '' + u.id, label };
		});
		
		return !this.isLoading && (
			<Form
				record={this.record}
				stay
				onDelete={this.back}
				onSave={this.back}
			>
				<Field property="principalType" label={t('acl.principalType')}>
					<Select items={data.PRINCIPAL_TYPE.map(pt => ({ value: pt.id, label: pt.name }))} />
				</Field>
				
				{this.record.principalType === 'USER' && this.users && (
					<Field property="principalId" label={t('user.title')}>
						<Select items={users} position="bottomLeft"/>
					</Field>
				)}
				
				<Field relation="role" property="name" label={t('role.title')} />
			</Form>
		);
	}
}
