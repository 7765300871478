import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';

import FilterLayout from './FilterLayout';
import { modelsConfig } from '../models';
import store from 'client/store';
import { closedLayers, publicLayers } from '../../layers';
import t from 'i18n';
import { observable } from 'mobx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons';

const OTHER = {
	name: 'Остальное',
	icon: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMzJweCIgaGVpZ2h0PSIzMnB4IiB2aWV3Qm94PSIwIDAgMzIgMzIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDQzLjIgKDM5MDY5KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5NZXRhY2F0ZWdvcnkgLyBPdGhlcjwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxkZWZzPjwvZGVmcz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJOZXctbWV0YS1kcmFmdCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTg4NC4wMDAwMDAsIC04NC4wMDAwMDApIj4KICAgICAgICAgICAgPGcgaWQ9IlNsaWNlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4NC4wMDAwMDAsIDg0LjAwMDAwMCkiPjwvZz4KICAgICAgICAgICAgPGcgaWQ9Ikljb24iIHRyYW5zZm9ybT0idHJhbnNsYXRlKDUxLjAwMDAwMCwgNDIuMDAwMDAwKSIgZmlsbD0iIzgwODA4MCI+CiAgICAgICAgICAgICAgICA8ZyBpZD0iTWV0YSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjguMDAwMDAwLCAwLjAwMDAwMCkiPgogICAgICAgICAgICAgICAgICAgIDxnIGlkPSJPdGhlciIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoODExLjAwMDAwMCwgNTYuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgICAgIDxjaXJjbGUgaWQ9Ik92YWwiIGN4PSIxMCIgY3k9IjIiIHI9IjIiPjwvY2lyY2xlPgogICAgICAgICAgICAgICAgICAgICAgICA8Y2lyY2xlIGlkPSJPdmFsIiBjeD0iMiIgY3k9IjIiIHI9IjIiPjwvY2lyY2xlPgogICAgICAgICAgICAgICAgICAgICAgICA8Y2lyY2xlIGlkPSJPdmFsIiBjeD0iMTgiIGN5PSIyIiByPSIyIj48L2NpcmNsZT4KICAgICAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==',
};

@inject('settings', 'mainStore')
@observer
export default class ModelsFilter extends React.Component {
	@observable mapLayer = null;
	@observable categories = null;
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.isLoading = true;
		this.mapLayer = await store.model.MapLayer.find({
			include: [
				{
					relation: 'category',
				},
			],
		});
		this.categories = await store.model.LayerCategory.find();
		this.isLoading = false;
	};

	onToggle = () => {
		this.props.layer.load();
	};

	renderModelFilter = (modelConfig) => {
		const { modelName, filterTitle } = modelConfig;
		return <FilterLayout key={modelName} layerName={modelName} layerGroup='models' title={filterTitle} onToggle={this.onToggle} />;
	};

	renderCategories = (config) => {
		const { toggleCategory } = this.props.mainStore;
		const { open } = this.props.settings.categories;
		return Object.entries(config)
			.sort(([a], [b]) => (b === OTHER.name ? -1 : 1))
			.map(([key, value]) => {
				const isOpen = open.indexOf(key) !== -1;
				let icon = this.categories.find((cat) => cat.name === key)?.icon;
				if (key === OTHER.name) {
					icon = OTHER.icon;
				}
				return (
					<Fragment key={key}>
						<div className='filters-group cursor-pointer' onClick={() => toggleCategory(key)}>
							{icon && <img src={icon} height={13} alt='' className='pr-1' />}
							<span>{key}</span>
							<div className={`wrap-icon ${!isOpen ? 'minimized' : ''}`}>
								<FontAwesomeIcon icon={faCaretUp} />
							</div>
						</div>
						{isOpen && value.map(this.renderModelFilter)}
					</Fragment>
				);
			});
	};

	getConfigByCategory = (modelsConfig, layers) => {
		const configs = modelsConfig.filter((config) => layers.includes(config.modelName));
		const categories = { [OTHER.name]: [] };
		for (const layer of this.mapLayer) {
			const { category } = layer;
			const layerConfig = configs.find((config) => config.modelName.toLowerCase() === layer.name);
			if (!layerConfig) continue;
			if (category) {
				if (!categories[category.name]) {
					categories[category.name] = [];
				}
				categories[category.name].push(layerConfig);
			} else {
				categories[OTHER.name].push(layerConfig);
			}
		}
		return categories;
	};

	render() {
		if (this.isLoading) return null;
		const configs = Object.values(modelsConfig).filter((config) => !!config.showInFilters);
		const closedConfigs = this.getConfigByCategory(configs, closedLayers);
		const publicConfigs = this.getConfigByCategory(configs, publicLayers);

		return (
			<>
				{!store.isPublic && (
					<>
						<div className='filters-group'>{t('dictionaries.group.closedLayers')}</div>
						{this.renderCategories(closedConfigs)}
						<div className='filters-group'>{t('dictionaries.group.publicLayers')}</div>
					</>
				)}
				{this.renderCategories(publicConfigs)}
			</>
		);
	}
}
