import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Button } from '@smartplatform/ui';
import { Panel } from 'components';
import Editor from './Editor';
import './style.scss';
import AICategory from "./AICategory";

@observer
export default class Test extends React.Component {

	@observable text = '';
	@observable richText = '';
	@observable suggestions = [];

	onRichTextChange = (text) => {
		this.text = text;
	};

	render() {
		if (this.isLoading) return <Panel full header="Test" className="test">...</Panel>;
		
		return <Panel full header="Test" className="test">
			{/*<AICategory/>*/}
			<Editor value={this.richText} onChange={this.onRichTextChange} />
		</Panel>;
	}

}
