import React from 'react';
import { observable } from 'mobx';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Fill, Stroke, Style, Icon, Circle as CircleStyle } from 'ol/style';
import { asArray } from 'ol/color';

import { ColorUtils } from '@smartplatform/ui';
import { geoJSON, getFeatureCenter, drawGeoMarker } from '@smartplatform/map/client';
import { modelsConfig } from './models-config';
import store from 'client/store';
import icon from 'img/icons/city.svg';
import { publicLayers } from '../../layers';
import { layerIcon } from 'smartDistrict/map/models/layerIcon';

export const colors = {
	instensifies: '#a00',
	continues: '#EB5757',
	weakens: '#d60',
	notspreading: '#fc0',
	localized: '#aa0',
	resumed: '#a60',
	extinguished: '#0a0',
	abandoned: '#0af',
};

export default class ModelLayer {

	models = {};
	configs = null;

	constructor(store) {
		this.store = store;
	}

	init = async (mapStore) => {
		this.mapStore = mapStore;
		this.layer = new VectorLayer({
			format: geoJSON,
			source: new VectorSource(),
		});
		this.layer.setZIndex(10);
		mapStore.addLayer(this.layer);

		this.load();
	};

	load = async (forceUpdate = false) => {
		const modelsStatuses = this.store.layersSettings.models;

		if (this.layer) this.layer.getSource().clear();

		if (!this.configs) {
			this.configs = {};
			const configs = await store.model.LayerConfig.find({ order: 'id asc' });
			for (let config of configs) {
				if (config.icon) config.icon = await config.downloadFile('icon');
				this.configs[config.modelName] = config;
			}
			console.log('configs', this.configs);
		}
		
		const promises = [];
		for (const modelName in modelsStatuses) {
			if (store.isPublic && !publicLayers.includes(modelName)) continue;
			const config = modelsConfig[modelName];
			if (!config) continue;
			// console.log('?', modelName, config);
			const { filter } = config;
			const { where, ...rest } = filter || {};
			const _where = { geo: { neq: null }, ...where };
			const _rest = { fields: ['id', 'geo', 'name'], ...rest };
			if (config.pointGeoField) _rest.fields.push(config.pointGeoField);
			if (!!store.model[modelName] && modelsStatuses[modelName].show) {
				if (this.models[modelName] && !forceUpdate) {
					const promise = await this.models[modelName];
					promises.push(promise);
				}
				else {
					const promise = store.model[modelName].find({
						where: _where,
						..._rest,
					})
					promises.push(promise);
				}
			}
		}

		const modelsRecords = await Promise.all(promises);
		// console.log('modelsRecords', modelsRecords);

		for (let records of modelsRecords) {
			const features = [];

			if (records.length > 0) {
				const modelName = records[0].MODEL.INFO.name;
				const modelConfig = modelsConfig[modelName];

				if (!this.models[modelName]) this.models[modelName] = records;

				// TODO: iconFunc - функция, принимающая в аргументе запись?
				let { style, marker: markerIcon, markerGeo: markerIconGeo, iconFunc } = modelConfig;

				// console.log('model', modelName, records.length, modelConfig);
				let iconSrc = layerIcon.default;
				
				for (let record of records) {
					if (layerIcon[modelName]) {
						iconSrc = layerIcon[modelName][record.name] || layerIcon[modelName].default;
					}

					if (this.configs[modelName]) {
						const {
							fill = '#00008040',
							stroke = '#00000080',
							width = 2,
							icon,
						} = this.configs[modelName];
						
						if (icon) iconSrc = icon;

						style = new Style({
							image: new Icon({ src: iconSrc, anchor: [10, 31], anchorXUnits: 'pixels', anchorYUnits: 'pixels' }),
							fill: new Fill({ color: asArray(ColorUtils.hexToRgbaArray(fill)) }),
							stroke: new Stroke({ color: asArray(ColorUtils.hexToRgbaArray(stroke)), width }),
						});
					}
					else if (!style) {
						const { background } = ColorUtils.getColorForString(modelName);
						const { r, g, b } = ColorUtils.hexToRgb(background);
						style = new Style({
							image: new Icon({ src: iconSrc, anchor: [10, 31], anchorXUnits: 'pixels', anchorYUnits: 'pixels' }),
							fill: new Fill({ color: asArray([r, g, b, 0.5]) }),
							stroke: new Stroke({ color: background, width: 1 }),
						});
					}

					if (record.geo.type !== 'Point' && record.geo.type !== 'MultiPoint') {
						const geo = drawGeoMarker(record.geo, {
							data: { record },
							style,
							onlyGeo: true,
						})
						features.push(...geo);

						if (!modelConfig.onlyGeo) {
							const marker = drawGeoMarker(record[modelConfig.pointGeoField], {
								data: { record },
								style,
								icon: markerIconGeo || markerIcon,
								onlyMarker: true,
							})
							features.push(...marker);
						}
					}
					else {
						style = new Style({
							image: new Icon({ src: iconSrc, anchor: [10, 31], anchorXUnits: 'pixels', anchorYUnits: 'pixels' }),
						});
						const marker = drawGeoMarker(record.geo, {
							data: { record },
							style,
						})
						features.push(...marker);
					}
				}
				this.layer.getSource().addFeatures(features);
			}
		}

	};

	update = async (modelName) => {
		const config = modelsConfig[modelName];
		const { filter } = config;
		const { where, ...rest } = filter || {};
		const _where = { geo: { neq: null }, ...where };
		const _rest = { fields: ['id', 'geo'], ...rest };
		await store.model[modelName].find({
			where: _where,
			..._rest,
		});
	};

};
