import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import ModuleMenu from './module-menu';
import './style.scss';

@observer
export default class SideBar extends React.Component {

	static propTypes = {};
	static defaultProps = {};

	constructor(props) {
		super(props);
	}

	render() {
		return <div className="main-sidebar">
			<ModuleMenu />
		</div>;
	}

}
