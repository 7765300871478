import React from 'react';
import { observer } from 'mobx-react';
import { Switch, Route, Link } from 'react-router-dom';

import { Panel } from 'components';
import List from './List';
import Edit from './Edit';
import groups from './groups';
import t from 'i18n';
import './style.scss';

@observer
export default class Dictionaries extends React.Component {

    render() {
        const path = '/smartdistrict/dictionaries';

        const allModels = [];
        groups.forEach(group => allModels.push(...group.models));
        
        return <Panel full className="dictionaries">
            <nav>
                {groups.map((group, i) => <div key={i} className="group">
                    <div className="title">{group.title}</div>
                    <ul className='models'>
                        {group.models.map(model => {
                            const modelName = typeof model === 'string' ? model : model.name;
                            return <li key={modelName}>
                                <Link to={`${path}/${modelName}`}>{t(`${modelName}.plural`)}</Link>
                            </li>
                        })}
                    </ul>
                </div>)}
            </nav>
            <main>
                {allModels.map(model => {
                    const modelName = typeof model === 'string' ? model : model.name;
                    const _Edit = typeof model === 'string' ? Edit : model.edit;
                    return <Switch key={modelName}>
                        <Route path={`${path}/${modelName}`} exact render={props => <List {...props} model={modelName} />}/>
                        <Route path={`${path}/${modelName}/create`} render={props => <_Edit {...props} model={modelName} />}/>
                        <Route path={`${path}/${modelName}/page/:page`} render={props => <List {...props} model={modelName} />}/>
                        <Route path={`${path}/${modelName}/:id`} render={props => <_Edit {...props} model={modelName} />}/>
                    </Switch>;
                })}
            </main>
        </Panel>;
    }
}
