import CanvasLayerRenderer from 'ol/renderer/canvas/Layer';
import ViewHint from 'ol/ViewHint.js';

import { glTools } from '@smartplatform/map/client';
import vertexShader from './vertex.glsl';
import fragmentShader from './fragment.glsl';

export default class CustomWebglPointsRenderer extends CanvasLayerRenderer {

	points = [];
	initialized = false;
	pointsRendered = false;

	constructor(imageLayer) {
		super(imageLayer);

		this.options = imageLayer.getProperties();

		this.canvas = document.createElement('canvas');
		this.canvas.className = 'custom-webgl-points-layer';
		this.canvas.style.position = 'absolute';
		this.canvas.style.zIndex = this.options.zIndex || 0;
		this.canvas.style.opacity = this.options.opacity || 0.8;
		this.pointSizeFunc = this.options.pointSizeFunc;

		this.initGL();
	}

	initGL() {
		this.gl = this.canvas.getContext('webgl', { premultipliedAlpha: false });
		this.gl.enable(this.gl.DEPTH_TEST);

		this.gl.blendFunc(this.gl.ONE, this.gl.ONE_MINUS_SRC_ALPHA);
		this.gl.depthMask(this.gl.FALSE);
		this.gl.enable(this.gl.BLEND);
		this.gl.pixelStorei(this.gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, true);

		this.program = glTools.createProgram(this.gl, vertexShader, fragmentShader);

		this.gl.useProgram(this.program.program);

		this.gl.viewportWidth  = this.canvas.width;
		this.gl.viewportHeight = this.canvas.height;

		this.vertexBuffer = this.gl.createBuffer();

		this.initialized = true;
	}

	setTexture = (image, size, count) => {
		this.texture = this.gl.createTexture();
		// this.textureSize = size;
		// this.spritesCount = count;
		console.log('setTexture', image);
		this.gl.bindTexture(this.gl.TEXTURE_2D, this.texture);
		this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_WRAP_S, this.gl.CLAMP_TO_EDGE);
		this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_WRAP_T, this.gl.CLAMP_TO_EDGE);
		this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MIN_FILTER, this.gl.LINEAR);
		this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MAG_FILTER, this.gl.LINEAR);
		this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGBA, this.gl.RGBA, this.gl.UNSIGNED_BYTE, image);
		this.gl.bindTexture(this.gl.TEXTURE_2D, null);
	}

	prepareFrame(frameState) {
		const { size, pixelRatio, viewState, viewHints } = frameState;

		const width = Math.round(size[0] * pixelRatio);
		const height = Math.round(size[1] * pixelRatio);

		if (this.canvas.width !== width || this.canvas.height !== height) {
			this.canvas.width = width;
			this.canvas.height = height;
		}

		// if (!viewHints[ViewHint.ANIMATING] && !viewHints[ViewHint.INTERACTING]) {
		// }

		return true;
	}

	clearFrame() {
		const gl = this.gl;
		gl.clearColor(0.0, 0.0, 0.0, 0.0);
		gl.clear(gl.DEPTH_BUFFER_BIT | gl.COLOR_BUFFER_BIT);
		gl.viewport(0, 0, this.canvas.width, this.canvas.height);
	}

	renderFrame(frameState, target) {
		const { viewState, pixelRatio, viewHints, size, extent, coordinateToPixelTransform } = frameState;
		const { resolution, zoom } = viewState;
		const { program } = this.program;

		const gl = this.gl;

		const width = size[0] * pixelRatio / 2;
		const height = size[1] * pixelRatio / 2;
		const ratio = size[0] / size[1];

		const scale = 2 / (extent[2] - extent[0]) / pixelRatio;

		const pos = [
			1 - coordinateToPixelTransform[4] / width,
			coordinateToPixelTransform[5] / height - 1,
		];

		// if (viewHints[ViewHint.ANIMATING] || viewHints[ViewHint.INTERACTING]) {
		// 	console.log('>', scale, coordinateToPixelTransform, frameState);
		// }

		this.clearFrame();

		const uResolution = gl.getUniformLocation(program, 'u_resolution');
		gl.uniform2f(uResolution, this.canvas.width, this.canvas.height);

		const pointSize = this.pointSizeFunc ? this.pointSizeFunc(viewState) : 5;
		const uViewResolution = gl.getUniformLocation(program, 'u_pointSize');
		gl.uniform1f(uViewResolution, pointSize);

		const uScale = gl.getUniformLocation(program, 'u_scale');
		gl.uniform2f(uScale, scale, scale * ratio);

		const uPos = gl.getUniformLocation(program, 'u_pos');
		gl.uniform2f(uPos, pos[0], pos[1]);

		gl.bindBuffer(gl.ARRAY_BUFFER, this.vertexBuffer);
		gl.bindTexture(gl.TEXTURE_2D, this.texture);

		gl.drawArrays(gl.POINTS, 0, this.points.length / 3);

		return this.canvas;
	}

	defaultSetup = (gl, program) => {
		const aPosition = gl.getAttribLocation(program, 'a_position');
		gl.vertexAttribPointer(
			aPosition,
			2,                                  // number of elements per attribute
			gl.FLOAT,                      // type of elements
			false,                              // normalize
			2 * Float32Array.BYTES_PER_ELEMENT, // size of an element in bytes
			0                                   // offset to this attribute
		);
		gl.enableVertexAttribArray(aPosition);
	};

	setPoints(points, customSetupFunc) {
		// console.log('setPoints', points.length / 3);

		this.points = points;
		this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexBuffer);

		const setupFunc = customSetupFunc || this.defaultSetup;
		setupFunc(this.gl, this.program.program);
		this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(points), this.gl.DYNAMIC_DRAW);
	}

	setPointSizeFunc = (func) => {
		this.pointSizeFunc = func;
	};

}
