import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { debounce } from 'lodash';
import { startOfDay, endOfDay, isBefore } from 'date-fns';

import { Pager, Select, DatePicker, RecordSelect } from '@smartplatform/ui';
import store from 'client/store';
import { formatDate, fioShort } from 'client/tools';
import aStore from './store';
import t from 'i18n';

const EXCLUDED_MODELS = ['Audit', 'Container', 'Email', 'Telegram'];

@observer
export default class List extends React.Component {

	@observable page = 1;
	@observable perPage = 20;
	@observable startDate = null;
	@observable endDate = null;
	@observable action = null;
	@observable owner = null;
	@observable model = null;

	constructor(props) {
		super(props);
		this.updateHeight = debounce(this.updateHeight, 250, { leading: false, trailing: true });
	}

	componentDidMount() {
		this.init();
		window.addEventListener('resize', this.updateHeight);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateHeight);
	}

	init = async () => {
		let where = { and: [] };

		if (this.startDate) where.and.push({ createdAt: { gte: startOfDay(new Date(this.startDate)) }});
		if (this.endDate) where.and.push({ createdAt: { lte: endOfDay(new Date(this.endDate)) }});
		if (this.model) where.and.push({ model: this.model });
		if (this.action) where.and.push({ action: this.action });
		if (this.owner) where.and.push({ ownerId: this.owner.id });

		if (where.and.length === 0) where = undefined;

		aStore.records = await store.model.Audit.find({
			where,
			include: ['owner'],
			order: 'id desc',
			limit: this.perPage,
			skip: (this.page - 1) * this.perPage,
		});
	};

	onPageChange = (page) => {
		this.page = page;
		this.init();
	};

	select = (record) => {
		store.route.push({ path: `/admin/audit/${record.id}`});
	};

	onListMount = (el) => {
		this.listEl = el;
		this.setPerPage();
	};

	updateHeight = () => {
		this.setPerPage();
		this.page = 1;
		this.init();
	};

	setPerPage = () => {
		if (this.listEl) {
			const rect = this.listEl.getBoundingClientRect();
			this.perPage = Math.round(rect.height / 30) - 1;
		}
	};

	onStartDateChange = (date) => {
		this.startDate = date;
		if (this.startDate && this.endDate && isBefore(new Date(this.endDate), new Date(this.startDate))) this.endDate = this.startDate;
		this.page = 1;
		this.init();
	};

	onEndDateChange = (date) => {
		this.endDate = date;
		if (this.startDate && this.endDate && isBefore(new Date(this.endDate), new Date(this.startDate))) this.startDate = this.endDate;
		this.page = 1;
		this.init();
	};

	onActionChange = (action) => {
		this.action = action;
		this.page = 1;
		this.init();
	};

	onOwnerChange = (owner) => {
		this.owner = owner;
		this.page = 1;
		this.init();
	};

	onModelChange = (model) => {
		this.model = model;
		this.page = 1;
		this.init();
	};

	render() {
		const models = Object.keys(store.model._models)
			.filter(modelName => !/^View.+/.test(modelName))
			.filter(modelName => !EXCLUDED_MODELS.includes(modelName))
			.sort((a, b) => a > b ? 1 : -1)
			.map(modelName => ({ label: modelName, value: modelName }));

		return <div className="audit-list">
			<div className="filters">
				<DatePicker
					value={this.startDate}
					onChange={this.onStartDateChange}
					showTimeSelect={false}
					placeholder={t('audit.startDate')}
				/>
				<DatePicker
					value={this.endDate}
					onChange={this.onEndDateChange}
					showTimeSelect={false}
					placeholder={t('audit.endDate')}
				/>
				<Select
					items={models}
					value={this.model}
					onChange={this.onModelChange}
					className="drop-down-md"
					showValue={this.model !== null ? this.model : <span className="hint">{t('audit.model')}</span>}
					position="bottomLeft"
				/>
				<Select
					items={[
						{ label: t('audit.create'), value: 'create' },
						{ label: t('audit.update'), value: 'update' },
						{ label: t('audit.delete'), value: 'delete' },
					]}
					value={this.action}
					onChange={this.onActionChange}
					className="drop-down-md"
					showValue={this.action ? t(`audit.${this.action}`) : <span className="hint">{t('audit.action')}</span>}
					position="bottomLeft"
					noSearch
				/>
				<RecordSelect
					model={store.model.User}
					value={this.owner}
					onChange={this.onOwnerChange}
					computed={fioShort}
					className="drop-down-md"
					showValue={this.owner ? fioShort(this.owner) : <span className="hint">{t('audit.owner')}</span>}
				/>
			</div>
			<Pager current={this.page} onChange={this.onPageChange} itemsPerPage={this.perPage} totalCount={aStore.records.totalCount} />
			<div className="audit-headers">
				<div className="date">{t('audit.date')}</div>
				<div className="model">{t('audit.model')}</div>
				<div className="description">{t('audit.description')}</div>
				<div className="action">{t('audit.action')}</div>
				<div className="owner">{t('audit.owner')}</div>
				{/*<div className="data">{t('audit.data')}</div>*/}
			</div>
			<div className="audit-items" ref={this.onListMount}>
				{aStore.records.map(record => <div key={record.id} className="audit-item" onClick={() => this.select(record)}>
					<div className="date">{formatDate(record.createdAt, 'dd.MM.yyyy HH:mm:ss')}</div>
					<div className="model">{record.model}</div>
					<div className="description">{store.model[record.model].INFO.description || <span>{record.model}</span>}</div>
					<div className={'action ' + record.action}>{t(`audit.${record.action}`)}</div>
					<div className="owner">{record.owner ? fioShort(record.owner) : '-'}</div>
					{/*<div className="data"><pre>{JSON.stringify(record.instance)}</pre></div>*/}
				</div>)}
			</div>
		</div>;
	}

}
