import React from 'react';
import { Fill, Stroke, Style, Icon, Circle as CircleStyle } from 'ol/style';
import { asArray } from 'ol/color';
import { ColorUtils } from '@smartplatform/ui';

import AirfieldPopup from './AirfieldPopup';
import icon from 'client/img/icons/airfield.svg';
import t from 'i18n';

const color = '#0af';

export default {
	modelName: 'Airfield',
	title: t('airfield.title'),
	filterTitle: t('airfield.plural'),
	showInFilters: true,
	style: new Style({
		fill: new Fill({ color: asArray([...ColorUtils.hexToArray(color), 0.5]) }),
		stroke: new Stroke({ color, width: 1 }),
	}),
	icon,
	marker: new Icon({ src: icon, anchor: [9, 31], anchorXUnits: 'pixels', anchorYUnits: 'pixels' }),
	priority: 2,
	renderPopup: record => <AirfieldPopup key={record.id} record={record} />,
};
