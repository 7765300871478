import CanvasLayerRenderer from 'ol/renderer/canvas/Layer';
import ViewHint from 'ol/ViewHint.js';
import { transform, get as getProj } from 'ol/proj';
import Pbf from 'pbf';
import { Tile } from 'proto/mvt.proto';

import md5 from './md5';
import { glTools } from '@smartplatform/map/client';
import vertexShader from './vertex.glsl';
import fragmentShader from './fragment.glsl';
// import Worker from './gl.worker';

export default class CustomMVTWebglRenderer extends CanvasLayerRenderer {

	firstLoad = true;
	initialized = false;

	tilesCount = 0;
	tilesLoaded = 0;
	allTilesLoaded = false;
	secondBuffer = false;
	animating = false;

	points = [];
	oldPoints = [];
	cache = {};
	count1 = 0;
	count2 = 0;

	constructor(layer) {
		super(layer);
		this.options = layer.getProperties();
		this.layer = layer;
		// this.init();
	}

	init() {
		// this.useContainer(null, null, 1);
		if (!this.container) {
			this.container = document.createElement('div');
			this.container.className = 'ol-layer';
			let style = this.container.style;
			style.position = 'absolute';
			style.width = '100%';
			style.height = '100%';
		}
		var onAppend = function(elem, f) {
			var observer = new MutationObserver(function(mutations) {
				mutations.forEach(function(m) {
					if (m.addedNodes.length) {
						f(m.addedNodes)
					}
				})
			})
			observer.observe(elem, {childList: true})
		}
		
		onAppend(this.container, function(added) {
			console.log('+++', added) // [p]
			// debugger;
		})
		this.canvas = document.createElement('canvas');
		this.canvas.className = 'custom-mvt-webgl';
		this.canvas.style.position = 'absolute';
		this.canvas.style.zIndex = this.options.zIndex || 0;
		this.canvas.style.opacity = this.options.opacity || 0.8;
		this.container.appendChild(this.canvas);

		this.tileSize = this.options.tileSize || 4096;
		this.pointSizeFunc = this.options.pointSizeFunc;
		this.bufferSetupFunc = this.options.bufferSetupFunc;

		this.initGL();

		const map = this.layer.getMapInternal();
		map.on('pointermove', this.onPointerMove);
		// this.canvas.addEventListener('mousemove', this.onMouseMove);
	}

	remove() {
		if (this.canvas) this.canvas.remove();
		// document.removeChild(this.canvas);
	}

	initGL() {
		this.gl = this.canvas.getContext('webgl', { premultipliedAlpha: false, preserveDrawingBuffer: true });

		this.gl.blendFunc(this.gl.ONE, this.gl.ONE_MINUS_SRC_ALPHA);
		this.gl.enable(this.gl.BLEND);
		this.gl.pixelStorei(this.gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, true);

		this.gl.enable(this.gl.DEPTH_TEST);
		this.gl.depthMask(this.gl.FALSE);
		this.gl.depthFunc(this.gl.ALWAYS);

		this.program = glTools.createProgram(this.gl, vertexShader, fragmentShader);

		this.gl.useProgram(this.program.program);

		this.gl.viewportWidth  = this.canvas.width;
		this.gl.viewportHeight = this.canvas.height;

		this.vertexBuffer1 = this.gl.createBuffer();
		this.vertexBuffer2 = this.gl.createBuffer();
		
		this.options.onInit && this.options.onInit(this.layer);
	}

	setTexture = (image, size, count) => {
		this.textureImage = image;
		this.texture = this.gl.createTexture();
		// this.textureSize = size;
		// this.spritesCount = count;
		// console.log('setTexture', image);
		this.gl.bindTexture(this.gl.TEXTURE_2D, this.texture);
		this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_WRAP_S, this.gl.CLAMP_TO_EDGE);
		this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_WRAP_T, this.gl.CLAMP_TO_EDGE);
		this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MIN_FILTER, this.gl.LINEAR);
		this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MAG_FILTER, this.gl.LINEAR);
		this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGBA, this.gl.RGBA, this.gl.UNSIGNED_BYTE, image);
		this.gl.bindTexture(this.gl.TEXTURE_2D, null);
	}

	prepareFrame(frameState) {
		if (!this.canvas) return false;

		const { size, pixelRatio, viewState, viewHints } = frameState;

		const width = Math.round(size[0] * pixelRatio);
		const height = Math.round(size[1] * pixelRatio);

		if (this.canvas.width !== width || this.canvas.height !== height) {
			this.canvas.width = width;
			this.canvas.height = height;
		}

		if (!this.initialized) {
			this.fetchTiles(frameState);
			this.initialized = true;
		}

		return true;
	}

	renderFrame(frameState, target) {
		if (!this.canvas) return null;

		// console.log('--- renderFrame');
		this.frameState = frameState;
		this.animating = frameState.viewHints[ViewHint.ANIMATING] || frameState.viewHints[ViewHint.INTERACTING];
		this.gl.viewport(0, 0, this.canvas.width, this.canvas.height);
		this.renderGL();

		return this.container;
	}

	clearFrame() {
		const gl = this.gl;
		gl.clearColor(0.0, 0.0, 0.0, 0.0);
		gl.clear(gl.DEPTH_BUFFER_BIT | gl.COLOR_BUFFER_BIT);
		gl.viewport(0, 0, this.canvas.width, this.canvas.height);
	}

	renderGL() {
		const { viewState, pixelRatio, size, extent, coordinateToPixelTransform } = this.frameState;
		const { program } = this.program;

		const gl = this.gl;

		const width = size[0] * pixelRatio / 2;
		const height = size[1] * pixelRatio / 2;
		const ratio = size[0] / size[1];

		const scale = 2 / (extent[2] - extent[0]) / pixelRatio;

		const pos = [
			1 - coordinateToPixelTransform[4] / width,
			coordinateToPixelTransform[5] / height - 1,
		];

		const uResolution = gl.getUniformLocation(program, 'u_resolution');
		gl.uniform2f(uResolution, this.canvas.width, this.canvas.height);

		const pointSize = this.pointSizeFunc ? this.pointSizeFunc(viewState) : 5;
		const uViewResolution = gl.getUniformLocation(program, 'u_pointSize');
		gl.uniform1f(uViewResolution, pointSize);

		const uScale = gl.getUniformLocation(program, 'u_scale');
		gl.uniform2f(uScale, scale, scale * ratio);

		const uPos = gl.getUniformLocation(program, 'u_pos');
		gl.uniform2f(uPos, pos[0], pos[1]);

		const vertexBuffer = this.secondBuffer ? this.vertexBuffer2 : this.vertexBuffer1
		gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer);

		const count = this.secondBuffer ? this.count2 : this.count1;
		// console.log('count', count, 'secondBuffer', this.secondBuffer, 'count1', this.count1, 'count2', this.count2);

		gl.bindTexture(gl.TEXTURE_2D, this.texture);

		this.clearFrame();

		gl.drawArrays(gl.POINTS, 0, count);
	}

	defaultSetup = (gl, program) => {
		const aPosition = gl.getAttribLocation(program, 'a_position');
		gl.vertexAttribPointer(
			aPosition,
			2,                                  // number of elements per attribute
			gl.FLOAT,                      // type of elements
			false,                              // normalize
			4 * Float32Array.BYTES_PER_ELEMENT, // size of an element in bytes
			0                                   // offset to this attribute
		);
		gl.enableVertexAttribArray(aPosition);
	};

	getTileGridAndZ = (frameState) => {
		const { viewState, extent } = frameState;
		const { resolution, projection } = viewState;
		const source = this.getLayer().getSource();
		const tileGrid = source.getTileGridForProjection(projection);
		const z = tileGrid.getZForResolution(resolution, source.zDirection);
		return { tileGrid, z };
	};

	fetchTiles = (frameState) => {
		const { tileGrid, z } = this.getTileGridAndZ(frameState);
		this.allTilesLoaded = false;
		this.tilesLoaded = 0;
		this.tilesCount = 0;
		this.points = [];
		this.cache = {};
		this.options.onLoadStart && this.options.onLoadStart();

		if (!this.firstLoad) {
			this.secondBuffer = !this.secondBuffer;
			// console.log('secondBuffer', this.secondBuffer);
		}
		this.firstLoad = false;

		tileGrid.forEachTileCoord(frameState.extent, z, () => this.tilesCount++);
		// console.log('fetchTiles', this.tilesCount);
		tileGrid.forEachTileCoord(frameState.extent, z, coords => this.fetchMVT(coords, tileGrid));
	};

	fetchMVT = async (coords, tileGrid) => {
		const [ z, x, y ] = coords;
		// console.log('layer', x, y, z);
		const source = this.getLayer().getSource();

		const url = source.tileUrlFunction(coords);
		// console.log('url', url);
		const hash = md5(url);

		const cache = this.cache;//!this.initialized ? this.cache : this.newCache;

		if (cache[hash]) {
			// console.log('got cache >', hash);
			return;
		}

		cache[hash] = true;

		let tile = { layers: [] };
		const tileExtent = tileGrid.getTileCoordExtent(coords);
		const tileSize = tileGrid.getTileSize();

		const start = new Date().getTime();

		const res = await fetch(url);
		const buffer = await res.arrayBuffer();
		const pbf = new Pbf(buffer);
		try {
			tile = Tile.read(pbf);
			// console.log('cached <', hash);
		}
		catch (e) {
		}

		const time = new Date().getTime() - start;

		this.processTile(tile, x, y, z, tileExtent, time);
		this.renderGL();

		this.tilesLoaded++;

		if (this.tilesLoaded === this.tilesCount) {
			this.allTilesLoaded = true;
			this.loadingNewTiles = false;
			this.options.onLoadEnd && this.options.onLoadEnd();
			this.updatePointsBuffer();
			// this.clearFrame();
			this.renderGL();
		}
	};

	processTile = (tile, x, y, z, tileExtent, time) => {
		const [ minX, minY, maxX, maxY ] = tileExtent;
		const mult = (maxX - minX) / this.tileSize;

		const difValues = {
			today: 0,
			yesterday: 1,
			earlier: 2,
		};

		// console.log('- Tile', { x, y, z }, 'time:', time + 'ms', 'layers:', tile.layers.length, 'tile:', tile);

		for (let layer of tile.layers) {
			const count = layer.features.length;
			// console.log('-- layer', { x, y, z }, layer);

			for (let i = 0; i < count; i++) {
				const feature = layer.features[i];
				const { geometry: encodedGeo } = feature;
				let dif = difValues[layer.name];

				const x = ((encodedGeo[1] >> 1) ^ (-(encodedGeo[1] & 1)));
				const y = ((encodedGeo[2] >> 1) ^ (-(encodedGeo[2] & 1)));

				const _x = minX + x * mult;
				const _y = maxY - y * mult;

				const lonLat = transform([_x, _y], 'EPSG:3857', 'EPSG:4326');
				const lon = lonLat[0];
				const lat = lonLat[1];

				this.points.push(...[lon, lat, dif, feature.id]);
			}
		}
	};

	checkZoom = () => {
		// console.log('checkZoom', this.frameState);
		this.initialized = false;
		// this.reset();
	};

	reset = () => {
		// console.log('--------------- reset');
		this.points = [];
		this.cache = {};
		// this.updatePointsBuffer();
		if (this.gl) {
			this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexBuffer1);
			this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(this.points), this.gl.DYNAMIC_DRAW);
			this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexBuffer2);
			this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(this.points), this.gl.DYNAMIC_DRAW);
		}
		this.initialized = false;
	};

	updatePointsBuffer() {
		this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.secondBuffer ? this.vertexBuffer2 : this.vertexBuffer1);
		if (this.secondBuffer) {
			this.count2 = this.points.length / 4;
		}
		else {
			this.count1 = this.points.length / 4;
		}
		const setupFunc = this.bufferSetupFunc || this.defaultSetup;
		setupFunc(this.gl, this.program.program);

		this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(this.points), this.gl.DYNAMIC_DRAW);
	}
	
	onPointerMove = (e) => {
		if (this.animating) return;
		const pixelBuffer = new Uint8Array(4);
		const [ x, y ] = e.pixel;
		this.gl.readPixels(Math.round(x), this.canvas.height - Math.round(y), 1, 1, this.gl.RGBA, this.gl.UNSIGNED_BYTE, pixelBuffer);
		const pixel = Array.from(pixelBuffer);
		this.options.onMouseMove && this.options.onMouseMove(e, pixel);
	};

	getPoints = () => this.points;

}
