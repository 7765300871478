import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import './style.scss';

const FormBlock = (props) => {
	const { title, children } = props;
	return <div className="form-block">
		<div className="title">{title}</div>
		{children}
	</div>;
}

FormBlock.propTypes = {
	title: PropTypes.string.isRequired,
};

export default observer(FormBlock);
