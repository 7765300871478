import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './style.scss';
import { Popconfirm } from '@smartplatform/ui';
import t from 'i18n';

export default class RowAddButton extends React.Component {
    static propTypes = {
        onClick: PropTypes.func.isRequired,
    };

    render() {
        return (
            <Popconfirm className="delete-row" onConfirm={this.props.onClick} content={t('delete')}>
                <FontAwesomeIcon icon={faTimes} />
            </Popconfirm>
        );
    }
}
