import React from 'react';
import { hexToGeoJson } from './geometry';
import t from 'i18n';
export { getStatusColor } from './getStatusColor';
export { getVehiclesValues } from './getVehiclesValues';
export { getPeopleValues } from './getPeopleValues';
export { calcSameRecordsValues } from './calcSameRecordsValues';
export { calcRecordsField } from './calcRecordsField';
export { getSeparatedGeoValues } from './getSeparatedGeoValues';
export * from './geometry';
export * from './getMostRepeatedElement';

export const fireName = fire => <><strong>#{fire.fireNumber}</strong> {fire.county ? fire.county.name : ''}</>;
export const cityName = city => <><span>{city.name} </span>{city.county?.name ? <em><b>{city.county.name}</b></em> : ''}</>;
export const colorName = (record, size = 10, margin = 5) => <>
	{record?.color && <span style={{ background: record.color, width: size, height: size, borderRadius: 50, marginRight: margin, display: 'inline-block' }}></span>}
	{record?.name && <span>{record.name}</span>}
</>;

// открытие таблиц в экселе
export const downloadReport = ({ id, name }) => {
	const htmlTable = document.getElementById(id);
	const html = htmlTable.outerHTML;
	const downloadLink = document.createElement('a');
	downloadLink.href = 'data:application/vnd.ms-excel, ' + encodeURIComponent(html);
	downloadLink.download = `${name}.xls`;
	downloadLink.click()
};

//
export const getYearArray = (n = 5) => {
	const currentYear = new Date().getFullYear();
	return new Array(n).fill(null).map((item, index) => currentYear - index);
};

export const MONTHES = [
	{ value: 1, label: t('month.january') },
	{ value: 2, label: t('month.february') },
	{ value: 3, label: t('month.march') },
	{ value: 4, label: t('month.april') },
	{ value: 5, label: t('month.may') },
	{ value: 6, label: t('month.june') },
	{ value: 7, label: t('month.july') },
	{ value: 8, label: t('month.august') },
	{ value: 9, label: t('month.september') },
	{ value: 10, label: t('month.october') },
	{ value: 11, label: t('month.november') },
	{ value: 12, label: t('month.december') },
];

export const getMonthLabel = (value) => MONTHES.find(month => month.value === value).label


const coordinate = (coordinate) => {
	const temp = coordinate.toFixed(4).split('.');
	return `${temp[0]}\u00B0${temp[1].slice(0, 2)}\`${temp[1].slice(2.4)}\`\``;
};

export const coordinates = (geo, option) => {
	if (geo) {
		const geoJSON = !geo.type ? hexToGeoJson(geo) : geo;
		if (geoJSON.type === 'Point') {
			if (option === 'full') return `${t('latShort')}${coordinate(geoJSON.coordinates[0])} ${t('lonShort')}${coordinate(geoJSON.coordinates[1])}`
			return [coordinate(geoJSON.coordinates[0]), coordinate(geoJSON.coordinates[1])]
		} else return '';
	}
};


export const azimuth = azimuth => typeof azimuth === 'number' ? `${Math.round(azimuth)}\u00B0` : '';
export const distance = (distance, units = false) => ((typeof distance === 'number') && (distance > 0) ? (distance / 1000).toFixed(1) : '') + ((distance > 0) && units ? ` ${t('km')}` : '');
export const area = (area, units = false) => ((typeof area === 'number') && (area > 0) ? +area.toFixed(2) : 0) + (units ? ` ${t('ga')}` : '');
export const edge = (edge, units = false) => ((typeof edge === 'number') && (edge > 0) ? +edge.toFixed(2) : 0) + (units ? ` ${t('km')}` : '');