import React from 'react';
import { inject, observer } from 'mobx-react';

@inject('dashboardStore') @observer
export default class FullReport extends React.Component {
    constructor(props) {
        super(props);
        this.store = this.props.dashboardStore;
        this.init();
    }

    init = async () => {
        this.store.getReportData();
    }

    render() {
        return <>{this.store.report && !this.store.isLoading && <div className='div-table' dangerouslySetInnerHTML={{ __html: this.store.report.content }} />}</>;
    }

}
