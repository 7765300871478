import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Loader } from '@smartplatform/ui';

@observer
export default class EditorTest extends React.Component {
    
    @observable isLoading = false;

    constructor(props) {
        super(props);
        this.init();
    }
    
    init = async () => {
        this.isLoading = true;
        const module = await import('@smartplatform/ui/src/components/editor/tinymce/Editor');
        const Editor = module.default;
        console.log({ Editor });
        this.editor = Editor;
        this.isLoading = false;
    };
    
    render() {
        if (this.isLoading) return <Loader />;
        
        const Editor = this.editor;
        return <Editor {...this.props} />;
    }
}
