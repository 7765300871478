import React from 'react';
import { observer } from 'mobx-react';

@observer
export default class Scale extends React.Component {

	render() {
		const { store, hidden, className } = this.props;

		return <div className={'map-scale-container ' + (className || '') + (hidden ? ' hidden' : '')}>
			<div className="map-scale" ref={store.onScaleMount} />
		</div>;
	}

}
