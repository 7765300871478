import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import debounce from 'lodash/debounce';
import { generatePath } from 'react-router';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import { Button, Pager, Loader, Select } from '@smartplatform/ui';
import WrappedTable from './WrappedTable';
import store from 'client/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import t from 'i18n';
import './style.scss';

@withRouter
@observer
export default class List extends React.Component {
	
	static propTypes = {
		model: PropTypes.any,
		filter: PropTypes.object,
		path: PropTypes.string,
		order: PropTypes.string,
		properties: PropTypes.object, // предустановленные данные для новой записи
		useExactModelName: PropTypes.bool, // использовать точное название модели в i18
	};

	static defaultProps = {
		order: 'id desc',
		useExactModelName: false,
	};

	@observable query = {};
	@observable records = [];
	@observable isLoading = false;
	@observable page = 1;
	@observable search = '';

	initial = true;
	reloads = 0;

	constructor(props) {
		super(props);
		this.page = this.getPage();
		this.doSearch = debounce(this.doSearch, 500, { leading: false, trailing: true });
		if (!store.local.itemsPerPage) {
			store.local.extend({ itemsPerPage: 10 });
			store.local.save();
		}
		this.query = {
			...this.props.filter,
			skip: (this.page - 1) * store.local.itemsPerPage,
			limit: store.local.itemsPerPage,
			order: props.order,
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const page = this.getPage();
		console.log('page', page, this.props.match.params);
		if (page !== this.page) {
			this.page = page;
			this.query = {
				...this.query,
				skip: (page - 1) * store.local.itemsPerPage,
				limit: store.local.itemsPerPage,
			};
		}
	}

	getPage = () => (this.props.match && this.props.match.params ? parseInt(this.props.match.params.page) || 1 : 1);

	onPageChange = (newPage, forceReload) => {
		if (forceReload) {
			console.log('reload!');
			this.reload();
		}
		else {
			let { path } = this.props;
			path = path.replace(/\/page\/.+/, '');
			if (newPage !== 1) path += '/page/' + newPage;
			this.props.history.push(path);
		}
	};

	create = () => {
		let { path } = this.props;
		this.props.history.push(path + '/create');
	};

	onRowClick = (record) => {
		let { path } = this.props;
		this.props.history.push(path + '/' + record.id);
	};

	onQueryUpdate = (query, prevQuery) => {
		this.initial = false;
		console.log('onQueryUpdate', query);
		this.query = query;
		if (prevQuery && this.page > 1 && query.skip === 0) {
			const { page, ...params } = this.props.match.params;
			let pattern = this.props.match.path;
			pattern = pattern.replace(/\/page\/:page/, '');
			let path = generatePath(pattern, { ...params });
			store.route.push({ path });
		}
	};

	onLoadStart = () => this.isLoading = true;
	onLoadEnd = () => this.isLoading = false;

	onSearch = (e) => {
		this.search = e.target.value;
		this.doSearch();
	};

	doSearch = () => {
		const trimmed = this.search.trim();
		const { _totalCount, ...query } = this.query;
		this.page = 1;
		this.query = {
			...query,
			search: trimmed.length > 0 ? trimmed : undefined,
			skip: 0,
		};
	};

	reload = () => {
		this.query = {
			...this.query,
			limit: store.local.itemsPerPage,
			__reloads: ++this.reloads
		};
	};
	
	onPageSizeChange = (value) => {
		store.local.itemsPerPage = value;
		store.local.save();
	};
	
	render() {
		const { children, model, useExactModelName } = this.props;

		const className = classNames('table-with-pager', {
			loading: this.isLoading,
			initial: this.isLoading && this.initial,
		});

		const modelLowerName = useExactModelName ? model.name : model.name.charAt(0).toLowerCase() + model.name.slice(1);

		return (
			<div className='fixed-page model-list'>
				<h1>{t(`${modelLowerName}.plural`)}</h1>
				<div className='filters'>
					<input type='text' value={this.search} onChange={this.onSearch} placeholder={t('search')} />
					<Button variant='primary' onClick={this.create} disabled={!model.INFO.WRITE}>
						<FontAwesomeIcon icon={faPlus} /> {t('add')}
					</Button>
					<Button variant='primary' onClick={this.reload}>{t('reload')}</Button>
				</div>
				{this.isLoading && this.initial && <Loader />}
				<div className={className}>
					<nav>
						<Pager
							current={this.page}
							totalCount={this.query._totalCount || 0}
							onChange={this.onPageChange}
							itemsPerPage={store.local.itemsPerPage}
							pageSizeFunc={this.onPageSizeChange}
						/>
					</nav>
					<WrappedTable
						model={model}
						query={this.query}
						filters
						onQueryUpdate={this.onQueryUpdate}
						onRowClick={this.onRowClick}
						onLoadStart={this.onLoadStart}
						onLoadEnd={this.onLoadEnd}
					>
						{children}
					</WrappedTable>
				</div>
			</div>
		);
	}
}
