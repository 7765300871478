import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Panel } from 'components';

@observer
export default class Dashboard extends React.Component {

	static propTypes = {};
	static defaultProps = {};

	constructor(props) {
		super(props);
	}

	render() {
		return <Panel left={0} top={0} width={800} height={320}>
			Dashboard
		</Panel>;
	}

}
