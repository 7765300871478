import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Loader, Row } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import { TextField } from 'smartDistrict/components';

@observer
export default class CityPopup extends React.Component {

	@observable record = null;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.record = await store.model.City.findById(this.props.record.id, {
			include: [
				{ relation: 'county', fields: ['id', 'name' ]}
			],
		});
	};

	gotoRecord = (e) => {
		e.preventDefault();
		store.route.push({ path: `/smartdistrict/airfields/${this.record.id}` });
	};

	render() {
		if (!this.record) return <Loader size={18} />;

		return <div className='city'>
			<div className='main-info'>
				<div className="title">
					<h2>{t('city.title')}: <span className='id' onClick={this.gotoRecord} >#{this.record.id}</span></h2>
				</div>
				<Row>
					<TextField label={t('name')} value={this.record.name} />
					<TextField label={t('county.title')} value={this.record.county ? this.record.county.name : '-'} />
					<div />
				</Row>
				<Row>
					<TextField label={t('city.fireDanger')} value={this.record.fireDanger} />
					<TextField label={t('city.okato')} value={this.record.okato} />
					<TextField label={t('city.oktmo')} value={this.record.oktmo} />
				</Row>
			</div>
		</div>;
	}

}
