import React from 'react';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import { Table, Column, Pager, Avatar, Button } from '@smartplatform/ui';
import { fio } from 'client/tools';
import store from 'client/store';
import t from 'i18n';

const PER_PAGE = 20;

@observer
export default class List extends React.Component {
	
	@observable isLoading = false;
	@observable records = null;
	@observable filter = {
		order: 'id asc',
		skip: 0,
		limit: PER_PAGE,
		where: {
			_search: ''
		}
	};

	constructor(props) {
		super(props);
		this.store = props.store;
		this.init();
	}

	@action init = async() => {
		this.isLoading = true;
		this.records = await store.model.Role.find(this.filter);
		this.isLoading = false;
	};

	@action onSearch = (value) => {
		this.filter.where._search = value;
		this.init();
	};

	create = () => {
		this.props.history.push(`/admin/roles/create`);
	};

	edit = (record) => {
		this.props.history.push(`/admin/roles/${record.id}`);
	};

	reload = (e) => {
		e.preventDefault();
		this.init();
	};

	onPageChange = async (page) => {
        this.page = page;
        this.filter.skip = (this.page - 1) * PER_PAGE;
        this.init();
    };

	onClose = () => store.route.push({ path: '/' });

	render () {
		if (!this.records) return '...';

		return <div className="block">
			{/*<div className="block-header">Роли</div>*/}
			<div className="table-list-panel">
				<input type="text" value={this.search} onChange={e => this.onSearch(e.target.value)} placeholder="Поиск" />
				&nbsp;
				<Button onClick={this.create} className="btn-primary" icon={<FontAwesomeIcon icon={faPlus}/>}>{t('create')}</Button>
				&nbsp;
				<Link to="#" onClick={e => this.reload(e)} className="btn-icon btn-link-type">{<FontAwesomeIcon icon={faSyncAlt} spin={this.isLoading}/>}</Link>
			</div>

			<div className="table-list-pager">
				<Pager
					itemsPerPage={PER_PAGE}
					totalCount={this.records.totalCount}
					current={this.page}
					onChange={this.onPageChange}
				/>
			</div>
			
			<Table className="table-list-table" rows={this.records} onRowClick={this.edit}>
				<Column width={60} property="id" label={t('ID')}/>
				<Column property="name" label={t('role.name')}/>
				<Column property="description" label={t('role.description')}/>
			</Table>
		</div>;
	}
}
