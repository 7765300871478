import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import store from 'client/store';

@observer
export default class TestClicker extends React.Component {

    count = 0;
    
    click = () => {
        this.count++;
        if (this.count === 3) {
            this.count = 0;
            store.route.push({ path: '/smartdistrict/test' });
        }
    };
    
    render() {
        return <div className="test-clicker" onClick={this.click}/>;
    }
}
