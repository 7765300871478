import React from 'react';
import { observer } from 'mobx-react';

import FilterLayout from './FilterLayout';
import t from 'i18n';

@observer
export default class GenShtabFilter extends React.Component {

	onToggle = () => {
		this.props.layer.setVisible();
	};

	render() {
		return <FilterLayout layerName="genShtab" title={t('genShtab.title')} onToggle={this.onToggle} />;
	}

}
