export default {
	translation: {
		accept: 'Подтвердить',
		active: 'Активен',
		cleanFilters: 'Очистить фильтры',
		week: 'Неделя',
		quarter: 'Квартал',
		year: 'Год',
		title: 'SmartDistrict',
		schedule: 'Режим работы',
		name: 'Наименование',
		noName: 'Без названия',
		shortName: 'Сокращенное наименование',
		LOGIN_FAILED: 'Неверный логин/пароль',
		LOGIN_WAIT: 'Превышен лимит попыток',
		LOGIN_EMPTY_CAPTCHA: 'Не введен код',
		LOGIN_INVALID_CAPTCHA: 'Неверный код',
		AUTHORIZATION_REQUIRED: 'Необходимо авторизоваться',
		description: 'Описание',
		create: 'Создать',
		save: 'Сохранить',
		longitude: 'Долгота',
		latitude: 'Широта',
		lonShort: 'д.',
		latShort: 'ш.',
		coordinates: 'Координаты',
		azimuth: 'Азимут',
		distance: 'Дистанция',
		length: 'Длина',
		metersPerSecond: 'м/с',
		loading: 'Загрузка...',
		showOnMap: 'Показывать на карте',
		addRecord: 'Добавить новую запись',
		editRecord: 'Изменить запись',
		copyRecord: 'Копировать запись',
		update: 'Обновить',
		send: 'Отправить',
		sending: 'Отправляется',
		export: 'Экспорт',
		import: 'Импорт',
		confirm: 'Подтвердить?',
		squareKm: 'кв.км',
		km: 'км',
		ga: 'га',
		all: 'Все',
		off: 'выкл.',
		date: 'Дата/Время',
		date1: 'Дата (от)',
		date2: 'Дата (до)',
		age1: 'Возраст (от)',
		age2: 'Возраст (до)',
		attachment: 'Прикрепленый файл',
		attachments: 'Прикрепленые файлов',
		default: 'По умолчанию',
		count: 'Количество',
		countShort: 'Кол-во',
		copy: 'Копирование',
		mainInfo: 'Основные данные',
		dateTime: 'Дата/время',
		dateFrom: 'от',
		at: 'в',
		downloadExcel: 'Скачать в Excel',
		no: 'нет',
		itemsPerPage: 'Кол-во на странице',
		opacity: 'Прозрачность',
		phoneNumber: {
			title: 'Номер телефона',
			plural: 'Номера телефонов'
		},
		site: {
			title: 'Сайт',
			plural: 'Сайты'
		},
		module: {
			fire: 'Оперативный мониторинг обстановки',
		},
		day: {
			sunday: 'Воскресенье',
			monday: 'Понедельник',
			tuesday: 'Вторник',
			wednesday: 'Среда',
			thursday: 'Четверг',
			friday: 'Пятница',
			saturday: 'Суббота',
		},
		month: {
			january: 'Январь',
			february: 'Февраль',
			march: 'Март',
			april: 'Апрель',
			may: 'Май',
			june: 'Июнь',
			july: 'Июль',
			august: 'Август',
			september: 'Сентябрь',
			october: 'Октябрь',
			november: 'Ноябрь',
			december: 'Декабрь',
		},
		validation: {
			REQUIRED_FIELDS: 'Не заполнены обязательные поля',
			REQUIRED_CREWTYPE: "Не заполнено поле 'Тип команды'",
			REQUIRED_EXTINGUISHING_AGENTS_TYPE: "Не заполнено поле 'Средство тушения'",
			ALREADY_EXIST: 'Такая запись уже существует',
			INCORRECT_AIR: "Наверно заполнено поле 'Авиа'",
			INCORRECT_EVACUATED: "Наверно заполнено поле 'Вывезено'",
		},
		dictionaries: {
			title: 'Справочник',
			plural: 'Справочники',
			group: {
				title: 'Группа справочников',
				norm: 'Нормативно-справочная информация',
				fires: 'Справочники реестра пожаров',
				air: 'Справочники налета воздушных судов',
				weather: 'Справочники метеостанции',
				other: 'Прочие',
				region: 'Региональные справочники',
				layers: 'Слои',
				settings: 'Настройки',
				closedLayers: 'Закрытые слои',
				publicLayers: 'Публичные слои',
			},
		},
		code: 'Код',
		externalCode: 'Код во внешней системе',
		weatherScaleValue: {
			title: 'Значение метеошкалы',
			plural: 'Значения метеошкал',
			startDate: 'Дата начала',
			endDate: 'Дата окончания',
			KPO1: 'КПО1',
			KPO2: 'КПО2',
			KPO3: 'КПО3',
			KPO4: 'КПО4',
		},
		forestUseType: {
			title: 'Вид использования лесов',
			plural: 'Виды использования лесов',
		},
		staffPosition: {
			title: 'Должность',
			plural: 'Должности',
		},
		paymentGroup: {
			title: 'Группа оплаты',
			plural: 'Группы оплаты',
		},
		report: {
			title: 'Отчет',
			plural: 'Отчеты',
			group: 'Группа отчетов',
			type: 'Вид отчета',
			download: 'Скачать отчет',
			downloadLO1: 'Скачать Форму 1-ЛО.Раздел 1',
			section: 'Раздел',
			date1: 'Дата (от)',
			date2: 'Дата (до)',
			inProgress: 'В разработке...',
			name: 'Название отчета',
			summary: 'Суммарный',
			detailed: 'Детальный',
		},
		forceAvailability: {
			title: 'Готовность сил и средств',
			plural: 'Готовность сил и средств',
			newDate: 'Дата новой записи',
			copyWarning: 'Копируются только сохраненные данные',
		},
		auth: {
			title: 'Авторизация',
			login: 'Войти',
			order: 'Получить доступ',
			insertLogin: 'Введите логин',
			insertPassword: 'Введите пароль',
			register: 'Зарегистрироваться',
			logout: 'Выйти',
			forgotPassword: 'Забыли пароль?',
			recover: 'Восстановить',
			required: 'Необходимо заполнить поле',
			wait: 'Подождите',
			seconds: 'секунд',
		},
		email: {
			title: 'E-mail',
			hint: 'mail@example.com',
			field: 'Электронная почта',
			input: 'Введите E-mail',
		},
		password: {
			title: 'Пароль',
			hint: 'Пароль...',
			input: 'Введите пароль',
			new: 'Введите новый пароль',
			repeat: 'Повторите пароль',
			newHint: 'Оставьте поля пустыми, чтобы не менять пароль',
			empty: 'Введите пароль в оба поля',
			noMatch: 'Пароли не совпадают!',
			match: 'Пароли совпадают',
		},
		person: {
			firstName: 'Имя',
			lastName: 'Фамилия',
			middleName: 'Отчество',
			fullName: 'Ф.И.О.',
		},
		user: {
			title: 'Пользователь',
			plural: 'Пользователи',
			lastAuthDate: 'Дата последней авторизации',
			create: 'Новый пользователь',
			profile: 'Профиль',
			email: 'E-mail',
			emailVerified: 'E-mail подтвержден',
			name: 'Логин',
			firstName: 'Имя',
			lastName: 'Фамилия',
			middleName: 'Отчество',
			leftComment: 'оставил(а) комментарий',
			createdTask: 'создал задачу',
			telegram: 'Telegram ID',
			username: 'Логин',
			password: 'Пароль',
			avatar: 'Аватар',
			fullName: 'ФИО',
			error: {
				email: {
					custom: {
						email: 'Неверный e-mail',
					},
					uniqueness: 'Пользователь с этим e-mail уже существует',
					verifySent: 'На e-mail отправлено письмо для подтвержения',
				},
			},
		},
		role: {
			title: 'Роль',
			plural: 'Роли',
			create: 'Новая роль',
			created: 'Созданo',
			modified: 'Изменено',
			name: 'Код',
			description: 'Название',
			isSaving: 'Права сохраняются...',
		},
		acl: {
			title: 'Право',
			plural: 'Права',
			create: 'Новое право',
			principalType: 'Вид субъекта',
			app: 'Приложение',
			role: 'Роль',
			user: 'Пользователь',
			accessType: 'Действие',
			read: 'Чтение',
			write: 'Запись',
			execute: 'Выполнение',
			all: 'Все',
			principalId: 'Субъект',
			model: {
				title: 'Модель',
				user: 'Пользователи',
				project: 'Проекты',
				person: 'Персоны',
				description: 'Описание',
			},
			permission: 'Право',
			allow: 'Разрешить',
			deny: 'Запретить',
			property: 'Свойство',
			allowAll: 'Разрешить все',
			list: 'Список прав',
		},
		roleMapping: {
			title: 'Назначение роли',
			plural: 'Назначенния ролей',
			create: 'Новое назначение роли',
		},
		comments: {
			title: 'Комментарий',
			plural: 'Комментарии',
			placeholder: 'Оставьте комментарий',
			submit: 'Отправить',
		},
		label: {
			title: 'Метка',
			plural: 'Метки',
			create: 'Новая метка',
		},
		file: {
			title: 'Файл',
			plural: 'Файлы',
			uploadFile: 'Загрузить файл',
			uploadImage: 'Загрузить изображение',
			uploadStart: 'начинаю загрузку',
			uploading: 'загрузка файла...',
			uploadComplete: 'загрузка завершена',
			notSelected: 'файл не выбран',
			willBeUploaded: 'Будет загружен',
			wrongType: 'Неверный тип файла',
		},
		organization: {
			title: 'Организация',
			plural: 'Организации',
			name: 'Название организации',
			shortName: 'Краткое наименование',
			ownershipForm: 'Форма собственности',
			address: 'Юридический адрес',
			headName: 'ФИО Руководителя',
			headPosition: 'Должность руководителя',
			inn: 'ИНН',
			ogrn: 'ОГРН',
			kpp: 'КПП',
			comment: 'Комментарий',
			externalId: 'ID организации во внешней системе',
			externalData: 'Содержимое организации из внешней системы',
		},
		appeal: {
			title: 'Обращение гражданина',
			plural: 'Обращения граждан',
			kanban: 'Реестр',
			date: 'Дата обращения',
			endDate: 'Отработано',
			status: 'Статус обращения',
			system: 'Система-источник',
		},
		appealStatus: {
			title: 'Состояние обращения',
			plural: 'Состояния обращений',
			default: 'По умолчанию',
			closing: 'Закрывающее',
		},
		appealSystem: {
			title: 'Внешняя система',
			plural: 'Внешние системы',
		},
		appealCategory: {
			title: 'Категория обращений',
			plural: 'Категории обращений',
		},
		period: {
			select: 'Период',
			day: 'День',
			week: 'Неделя',
			month: 'Месяц',
			quarter: 'Квартал',
			year: 'Год',
			everyday: 'Каждый день',
			everyweek: 'Каждую неделю',
			everymonth: 'Каждый месяц',
			lastDayOfMonth: 'Последний день месяца',
			dayOfMonth: 'числа каждого месяца`',
		},
		stats: {
			title: 'Статистика',
			catAvg: 'Средняя продолжительность обработки обращений по категориям',
			orgAvg: 'Средняя продолжительность обработки обращений по организациям',
			orgCount: 'Количество обращений по организациям',
			sources: 'Обращения по источникам',
			heatmap: 'Тепловая карта',
		},
		audit: {
			title: 'Журнал',
			date: 'Дата/время',
			model: 'Модель',
			description: 'Описание',
			action: 'Действие',
			create: 'Создание',
			delete: 'Удаление',
			update: 'Обновление',
			select: 'Выберите запись',
			data: 'Данные записи',
			owner: 'Пользователь',
			startDate: 'С даты',
			endDate: 'по дату',
			hidden: '*скрыто*',
		},
		heatPoint: {
			title: 'Термоточка',
			plural: 'Термоточки',
			source: 'Спутник',
			period: 'Период',
			periodHint: 'Максимальный период ограничен одним годом. При его превышении даты будут скорректированы.',
			reload: 'Загрузить',
			brightness: 'Яркость (в кельвинах)',
			dif: 'dif',
			eurasia: 'Евразия',
			today: 'сегодня',
			yesterday: 'вчера',
			earlier: 'ранее',
			dayNight: 'Day/Night',
			frp: 'FRP',
			scan: 'Scan',
			track: 'Track',
			createFire: 'Добавить пожар',
		},
		fire: {
			popup: {
				mainInfo: 'Основные данные',
				vpp: 'Данные о ВПП',
				activity: 'Задействовано в тушении',
			},
			allotmentNumber: 'Выдел',
			id: '№ по реестру ОМ-Пожары',
			delete: 'Удалить пожар',
			fieldsSelected: 'Выбрано полей',
			fireNumber: '№ пожара',
			fireAct: 'Акт о пожаре',
			distances: 'Расстояния',
			numbers: 'Нумерация',
			meteo: 'Метеоинформация',
			extinguishingCosts: 'Затраты на тушение',
			forestFireAct: 'Акт о лесном пожаре',
			forestryDamage: 'Затраты и ущерб, причиненные лесному хозяйству',
			cityAndAirfield: 'Город и аэропорт',
			dynamicDate: 'Время',
			new: 'Новый пожар',
			name: 'Наименование',
			shortName: 'Сокращенное наименоваие',
			status: 'Состояние пожара',
			title: 'Лесной пожар',
			plural: 'Лесные пожары',
			short: 'Пожар',
			registry: 'Реестр пожаров',
			map: 'Карта',
			add: 'Добавить',
			addForm: 'Добавление пожара',
			mainInfo: 'Основные данные',
			resources: 'Силы и средства',
			secondaryInfo: 'Дополнительная информация',
			jumpings: 'Прыжки',
			descents: 'Спуски',
			delivered: 'Грузов доставлено, тонн',
			deliveredAvia: 'В том числе авиа, тонн',
			threat: 'Угроза населенному пункту',
			fireManager: 'Руководитель тушения пожара',
			number: '№',
			numberLong: 'Номер пожара',
			created: 'Создана карточка пожара',
			geo: 'Геометрия',
			coordinates: 'Координаты пожара',
			coordsAndDistances: 'Координаты и расстояния',
			cityAzimuth: 'Азимут от н/п',
			cityDistance: 'Удаление от н/п',
			airfieldAzimuth: 'Азимут от аэропорта',
			airfieldDistance: 'Расстояние до места высадки',
			aviaDepAzimuth: 'Азимут от а/о',
			aviaDepDistance: 'Удаление от а/о',
			transportRoutesDistance: 'Расстояние до транспортных путей',
			fromMap: 'Рисовать на карте',
			time: 'Время',
			detectDate: 'Обнаружение',
			reportDate: 'Сообщение',
			startDate: 'Начало тушения',
			lastEditDate: 'Последнее изменение',
			lastEditPerson: 'Кто внес изменения',
			extinguishedDate: 'Дата/время тушения',
			localizationDate: 'Дата/время локализации',
			dynamic: 'Динамика',
			createDynamic: 'Создание новой динамики',
			edge: 'Кромка, км',
			edgeShort: 'Кромка',
			protectiveArea: 'Защитная площадь, га',
			protectiveAreaShort: 'Защитная',
			operationalArea: 'Эксплуатационная площадь, га',
			operationalAreaShort: 'Эксплуатационная',
			reserveArea: 'Резервная площадь, га',
			reserveAreaShort: 'Резервная',
			soilArea: 'Почвенная площадь, га',
			soilAreaShort: 'Почвенная',
			upperArea: 'Верховая площадь, га',
			upperAreaShort: 'Верховая',
			lowerArea: 'Низовая площадь, га',
			lowerAreaShort: 'Низовая',
			forestArea: 'Лесная площадь, га',
			forestAreaShort: 'Лесная',
			nonForestArea: 'Нелесная площадь, га',
			nonForestAreaShort: 'Нелесная',
			coverArea: 'Покрытая площадь, га',
			coverAreaShort: 'Покрытая',
			nonCoverArea: 'Непокрытая площадь, га',
			nonCoverAreaShort: 'Непокрытая',
			activeBurningArea: 'Зона активного горения, га',
			activeBurningAreaShort: 'Активн. гор.',
			description: 'Примечания',
			startDrawing: 'Рисовать',
			finishDrawing: 'Закончить',
			showCounties: 'Показывать районы',
			showForestryQuarters: 'Показывать квартальную сеть',
			area: 'Площадь, га',
			areaKm: 'Площадь, кв.км',
			areaShort: 'Общая',
			areaTitle: 'Площадь пожара, га',
			leasedArea: 'Площадь на арендуемых лесных участках, га',
			geoChanged: 'Изменена геометрия пожара',
			forestOwnership: 'Принадлежность лесов',
			combustionIntensity: 'Интенсивность горения',
			combustionPattern: 'Характер лесных насаждения',
			combustionType: 'Вид пожара',
			combustionCharacteristic: 'Характеристика горения',
			decisionDetailsOpb: 'Реквизиты решения КЧС и ОПБ РС(Я)',
			date: 'Дата',
			protocolNumber: 'Номер протокола',
			forestryPrecinct: 'Участковое лесничество',
			cause: 'Причина пожара',
			zone: 'Зона мониторинга',
			forestryFireNumber: '№ пожара по лесничеству',
			aviaDepFireNumber: '№ пожара по авиаотделению',
			bigFireNumber: '№ крупного пожара',
			isdmFireNumber: '№ в ИСДМ',
			isdmSync: 'Синхронизация с ИСДМ',
			notLandingReason: 'Причина непринятия мер',
			notInspectionReason: 'Причина неосмотра',
			windSpeed: 'Скорость ветра',
			extinguishingCostsSubject: 'Субъекта',
			rentBC: 'В т.ч. аренда ВС',
			totalCost: 'Общий ущерб',
			actDate: 'Дата акта',
			actPercent: '% акта',
			compiler: 'Составитель',
			woodLoss: 'Потери древесины на корню',
			youngWoodLoss: 'Погибло молодняков',
			burnedWood: 'Сгорело и повреждено лесной продукции',
			burnedHarvestedWood: 'В т.ч. заготовленной древесины',
			KPOdetected: 'КПО обнаружения',
			KPOliquidation: 'КПО ликвидации',
			frontalPrecipitation: 'Фронтальные осадки',
			origin: 'Координаты и расстояния',
			pickPoint: 'Указать на карте',
			pickPointHint: 'Указать точку курсором на карте',
			lastDynamic: 'Дата/время последней динамики',
			updateMainInfo: 'Автозаполнение',
			updateMainInfoHint: 'Автоматически заполнить некоторые поля на основе точки возникновения или геометрии пожара',
		},
		dynamic: {
			popup: {
				confirm: 'Подтвердить',
				message: 'Внесенные в редактируемую динамику данные будут утеряны',
			},
		},
		notifyRecipient: {
			title: 'Получатель рассылки',
			plural: 'Получатели рассылки',
			group_filter: 'Фильтр получателей по группе',
			group: 'Группа получателей',
			name: 'Имя',
		},
		notifyRecipientGroup: {
			title: 'Группа получателей рассылки',
			plural: 'Группы получателей рассылки',
			showAll: 'Показывать всех',
		},
		transportData: {
			title: 'Транспортный модуль',
			period: 'Период',
			schedule: 'Установленное расписание',
			report: 'Отчет',
			nextDate: 'Дата следующей отправки',
			disabled: 'Рассылка неактивна',
			active: 'Активность',
			minute: 'Мин',
			hour: 'Час',
			day: 'День месяца',
			dayOfWeek: 'День недели',
			subject: 'Заголовок письма',
			text: 'Текст письма',
		},
		notLandingReason: {
			title: 'Причина невысадки',
			plural: 'Причины невысадки',
		},
		notInspectionReason: {
			title: 'Причина неосмотра',
			plural: 'Причины	 неосмотра',
		},
		aviaDep: {
			title: 'Авиаотделение',
			plural: 'Авиаотделения',
			active: 'Активность',
			location: 'Расположение',
			area: 'Осматриваемая площадь',
		},
		forestry: {
			title: 'Лесничество',
			plural: 'Лесничества',
		},
		landCategory: {
			title: 'Категория земли',
			plural: 'Категории земель',
		},
		fireAnalyticalIndicator: {
			title: 'Аналитический показатель пожаров',
			plural: 'Аналитические показатели пожаров',
		},
		tenant: {
			title: 'Арендатор',
			plural: 'Арендаторы',
		},
		county: {
			title: 'Район',
			plural: 'Районы',
		},
		city: {
			title: 'Населенный. пункт',
			plural: 'Населенные пункты',
			pluralShort: 'Нас. пункты',
			add: 'Добавить населенный пункт',
			okato: 'Код ОКАТО',
			fireDanger: 'Угроза населенному пункту',
			population: 'Население',
		},
		crewType: {
			title: 'Тип противопожарной команды',
			title_short: 'Тип команды',
			plural: 'Типы противопожарных команд',
		},
		extinguishingAgentsType: {
			title: 'Тип средства тушения',
			title_short: 'Средство тушения',
			plural: 'Типы средств тушения',
		},
		discoveryMessageType: {
			title: 'Тип входящего сообщения о нарушениях в лесах',
			plural: 'Типы входящих сообщений о нарушениях в лесах',
		},
		breed: {
			title: 'Преобладающая порода',
			plural: 'Преобладающие породы',
		},
		cover: {
			title: 'Преобладающий покров',
			plural: 'Преобладающие покровы',
		},
		forestCategory: {
			title: 'Категория леса',
			plural: 'Категории лесов',
		},
		forestOwnership: {
			title: 'Принадлежность леса',
			plural: 'Принадлежность лесов',
			short: 'Принадлежность',
		},
		fireCause: {
			title: 'Причина пожара',
			plural: 'Причины пожаров',
		},
		fireStatus: {
			title: 'Cостояние пожара',
			plural: 'Состояния пожаров',
			selected: 'Выбрано статусов',
			codes: {
				abandoned: 'Прекращено тушение по решению КЧС',
				extinguished: 'Ликвидирован',
				resumed: 'Возобновился',
				localized: 'Локализован',
				notspreading: 'Не распространяется',
				weakens: 'Ослабевает',
				continues: 'Продолжается',
				intensifies: 'Усиливается',
				active: 'Активен',
				zik: 'На землях иных категорий',
			},
		},
		determiningMethod: {
			title: 'Способ обнаружения пожара',
			plural: 'Способы обнаружения пожаров',
		},
		combustionType: {
			title: 'Вид пожара',
			plural: 'Виды пожара',
		},
		combustionIntensity: {
			title: 'Интенсивность горения',
			plural: 'Виды интенсивности горения',
		},
		combustionPattern: {
			title: 'Характер леса',
			plural: 'Виды характера леса',
		},
		airRaid: {
			title: 'Налёт',
			plural: 'Налёты',
			pluralLong: 'Воздушные налеты',
			add: 'Добавление налёт',
			requestNumber: 'Номер заявки',
			requestDate: 'Дата заявки',
			departureDate: 'Дата/время вылета',
			arrivalDate: 'Дата/время прилета',
			mainInfo: 'Основная информация',
			addContract: 'Добавить договор',
			noContracts: 'Нет активных договоров',
			startDate: 'Дата (с) ',
			endDate: 'Дата (по)',
			pickAirRaid: 'Выберите налет',
		},
		airJob: {
			title: 'Воздушная работа',
			plural: 'Воздушные работы',
			add: 'Добавить воздушную работу',
			duration: 'Время выполнения',
			duration_short: 'Время',
			drains: 'Сливы',
			effects: 'Воздействия',
			jumpings: 'Тренировочные прыжки',
			jumpings_short: 'прыжки',
			descents: 'Тренировочные спуски',
			descents_short: 'спуски',
		},
		airJobType: {
			title: 'Вид воздушной работы',
			plural: 'Виды воздушных работ',
		},
		staff: {
			title: 'Личный состав',
			plural: 'Личный состав',
			number: 'Номер в табеле',
			fullName: 'ФИО',
			category: 'Категория',
		},
		unit: {
			title: 'Единица измерения',
			plural: 'Единицы измерения',
			nationalSymbol: 'Условное обозначение (национальное)',
			internationalSymbol: 'Условное обозначение (международное)',
			nationalCode: 'Кодовое обозначение (национальное)',
			internationalCode: 'Кодовое обозначение (международное)',
		},
		contract: {
			title: 'Договор',
			plural: 'Договора',
			number: 'Номер договора',
			date: 'Дата договора',
			term: 'Срок действия',
		},
		contractor: {
			title: 'Подрядчик',
			plural: 'Подрядчики',
		},
		vehicleType: {
			title: 'Тип транспортного средства',
			plural: 'Типы транспортный средств',
		},
		aircraftType: {
			title: 'Тип воздушного средства',
			plural: 'Типы воздушных средств',
			group: 'Группа',
			class: 'Класс',
			speed: 'Скорость',
			fuelConsumption: 'Расход топлива',
			fuelSupply: 'Запас топлива',
			runwayLength: 'Длина ВПП',
			aeronauticalSupply: 'Аэронавигационный запас',
		},
		weatherStation: {
			title: 'Метеостанция',
			plural: 'Метеостанции',
			latitude: 'Широта',
			longitude: 'Долгота',
			activity: 'Активность',
		},
		airfieldRunway: {
			title: 'ВПП аэродрома',
			plural: 'ВПП аэродрома',
			add: 'Добавить ВПП',
			size: 'Размеры',
			number: 'Номер',
			class: 'Класс',
			pcn: 'PCN',
			utpzCategory: 'Категория УТПЗ',
			icaoCategory: 'Категория ИКАО',
			coverage: 'Покрытие',
			magneticBearing: 'Магнитный курс посадки',
			lightingSystem: 'Светосигнальное оборудование',
		},
		airfield: {
			title: 'Аэродром',
			plural: 'Аэродромы',
			name: 'Наименование',
			add: 'Добавление аэродром',
			icaoCode: 'Код по ИКАО',
			icaoLandingCategory: 'Категория посадки по ИКАО',
			localTime: 'Местное время',
			latitude: 'Контрольная точка: широта',
			longitude: 'Контрольная точка: долгота',
			exceeding: 'Превышение аэродрома',
			magneticDeclination: 'Магнитное склонение',
			iataCode: 'Код аэропорта ИАТА',
		},
		fireFightingVehicle: {
			title: 'Противопожарное средство',
			plural: 'Противопожарные средства',
			plan: 'План',
			required: 'Требуется',
			current: 'Задейстовано сейчас',
			current_short: 'Задейстовано',
			date: 'Дата/время',
		},
		fireFightingPeople: {
			title: 'Противопожарная сила',
			plural: 'Противопожарные силы',
			plan: 'План',
			required: 'Требуется',
			current_short: 'Работает',
			evacuated_short: 'Вывезено',
			delivered: 'Доставлено',
			air_short: 'Авиа',
		},
		fireFightingFormation: {
			title: 'Лесопожарное формирование',
			plural: 'Лесопожарные формирования',
			title_short: 'Формирование',
		},
		FireCharacteristics: {
			location: 'Характеристики местности и пожара',
		},
		observerPilot: {
			title: 'Летчик-наблюдатель',
			plural: 'Летчики-наблюдатели',
			inspector: 'Проверяющий',
			intern: 'Стажёр',
			add: 'Добавить летчика-наблюдателя',
		},
		aircraftKind: {
			title: 'Вид летательного аппарата',
			title_short: 'Вид ЛА',
			plural: 'Виды летательных аппаратов',
		},
		silam: {
			title: 'Загрязнение воздуха',
			on: 'Показывать',
		},
		monitoringZone: {
			title: 'Зона мониторинга',
			plural: 'Зоны мониторинга',
			bigFireLimit: 'Лимит крупного пожара, га',
		},
		emergencySituation: {
			title: 'Режим ЧС и ОПР',
			plural: 'Режимы ЧС и ОПР',
			startAct: '№ акта введения',
			endAct: '№ акта снятия',
			republicStatus: 'На всей территории республики',
			comment: 'Примечание',
			startDate: 'Дата введения',
			endDate: 'Дата снятия',
			type: 'Тип',
			counties: 'Районы',
		},
		emergencySituationDamage: {
			title: 'Ущерб от режима ЧС',
			title_short: 'Ущерб',
			plural: 'Ущерб от режима ЧС',
			dead: 'Погибших',
			injured: 'Пострадавших',
			affectedObjects: 'Поcтрадавших объектов',
			comment: 'Примечание',
		},
		emergencySituationType: {
			title: 'Тип чрезвычайной ситуации',
			plural: 'Типы чрезвычайных ситуаций',
		},
		requestLog: {
			title: 'Журнал обращений',
			plural: 'Журнал обращений',
			number: 'Номер',
			date: 'Дата',
			location: 'Местоположение',
			description: 'Описание',
			measures: 'Принятые меры',
			result: 'Результат',
			contactInfo: 'Контактная информация',
			messageType: 'Тип сообщения',
			operator: 'Оператор',
			processed: 'Обработано',
		},
		fireReport: {
			title: 'Пожарный отчет',
			plural: 'Пожарные отчеты',
			coordinats: 'Координаты пожара',
			tenant: 'Арендатор',
			localization: 'Дата локализации/ликвидации пожара',
			peopleTypes: 'Дополнительные типы людей',
			stayed: 'Люди на конец дня',
			year: 'Год',
			month: 'Месяц',
			groupBy: 'Группировка',
			nonForestArea: 'Нелесная площадь',
			noAviaDep: 'Без авиаотделения',
			noCounty: 'Без района',
			noForestry: 'Без лесничества',
		},
		forestryQuarter: {
			title: 'Квартальная сеть',
			short: 'Квартал',
			plural: 'Квартальная сеть',
			area: 'Площадь кв.м.',
			add: 'Добавить квартал',
			name: 'Наименование квартала',
			code: 'Код квартала',
			forestry: 'Лесничество',
			forestryPrecinct: 'Участковое лесничество',
			forestCategory: 'Категория лесов',
			aviaDep: 'Авиаотделение',
			monitoringZone: 'Зона мониторинга',
		},
		forestryPrecinct: {
			title: 'Участковое лесничество',
			plural: 'Участковые лесничества',
			short: 'Уч.лесничество',
		},
		aircraft: {
			title: 'Воздушное судно',
			plural: 'Воздушные суда',
			number: 'Бортовой номер',
			number_short: 'б/н',
		},
		weather: {
			title: 'Погода',
			temp: 'Температура',
			tempDescription: 'Температура на высоте 2 м над землей',
			tempMeasure: '°C',
			pressure: 'Давление',
			pressureDescription: 'Атмосферное давление',
			pressureMeasure: 'мм рт.ст.',
			precipitation: 'Осадки',
			precipitationDescription: 'Накопление осадков за 3 часа',
			precipitationMeasure: 'кг/м²',
			humidity: 'Влажность',
			humidityDescription: 'Относительная влажность на высоте 2 м над землей',
			humidityMeasure: '%',
			pm25: 'PM2.5',
			pm25Description: 'Взвешенные твердые микрочастицы и мельчайшие капельки жидкости диаметром 2.5 мкм и менее',
			pm25Measure: 'мкг/м³',
			pm10: 'PM10',
			pm10Description: 'Взвешенные твердые микрочастицы и мельчайшие капельки жидкости диаметром 10 мкм и менее',
			pm10Measure: 'мкг/м³',
			co: 'CO',
			coDescription: 'Описание слоя CO',
			coMeasure: 'мкг/м³',
			no: 'NO',
			noDescription: 'Описание слоя NO',
			noMeasure: 'мкг/м³',
			no2: 'NO2',
			no2Description: 'Описание слоя NO2',
			no2Measure: 'мкг/м³',
			ozone: 'Озон',
			ozoneDescription: 'Описание слоя O3',
			ozoneMeasure: 'DU',
			so2: 'SO2',
			so2Description: 'Описание слоя SO2',
			so2Measure: 'кг/м²',
			feeling: 'Ощущается как',
		},
		firepopup: {
			fire: 'Пожар',
			point: 'Термоточка',
			info: 'Информация',
			weather: 'Погода',
		},
		wind: {
			title: 'Ветер',
			speedFactor: 'Скорость',
			fadeOpacity: 'Длина шлейфа',
			particleLife: 'Жизнь частицы',
			opacity: 'Прозрачность',
		},
		notifyStatus: {
			title: 'Статус сообщения',
			plural: 'Статусы сообщений',
			status: 'Статус',
		},
		notifyType: {
			title: 'Тип сообщения',
			plural: 'Типы сообщений',
			type: 'Тип',
			default: 'По умолчанию',
			select: 'Выберите тип сообщения',
			active: 'Активное API',
		},
		notifyTemplate: {
			title: 'Шаблон сообщения',
			plural: 'Шаблоны сообщений',
			template: 'Шаблон',
			noTemplates: 'Шаблоны не добавлены',
		},
		notifyLog: {
			title: 'Cообщение',
			plural: 'Сообщения',
			text: 'Текст сообщения',
			phone: 'Телефон получателя',
			recipient: 'Получатель',
			date: 'Дата отправления',
			add: 'Новая рассылка',
			pickTemplate: 'Выбрать шаблон',
			mailing: 'Информирование',
			recipientsCount: 'Количество получателей',
			notSent: 'Не отправлялось',
			noApi: 'API не подключено',
			accepted: 'Подтверждено',
			notAccepted: 'Не подтверждено',
			acceptedPlaceholder: 'Статус подверждения',
		},
		notify: {
			massNotify: 'Для подтверждения массовой рассылки требуется ввести код в конце формы',
			code: 'Код подтверждения',
		},
		case: {
			mainDate: 'Основные данные',
			messages: 'Сообщения',
			sendMessage: 'Отправить сообщение',
			notifyFilter: 'Фильтры по сообщениям',
			noCounty: 'Район не определен',
		},
		camera: {
			title: 'Камера',
			plural: 'Камеры',
			error: 'Ошибка подключения',
			maximize: 'развернуть',
			minimize: 'свернуть',
		},
		dzz: {
			title: 'ДЗЗ',
		},
		genShtab: {
			title: 'Генштаб СССР',
		},
		dashboard: {
			title: 'Сводка',
			total: 'Всего',
			active: 'Действует пожаров',
			fires: 'Пожаров',
			dynamics: 'Динамика ситуации',
			cityDanger: 'Угроза населенным пунктам',
			area: 'Площадь, Га',
			totalArea: 'Общая пл., Га',
			dailyIncrease: 'Прирост в сутки, Га',
			extinguished: 'Ликвидировано',
			firesCount: 'Количество пожаров',
			fullReport: 'Полная сводка',
			operationalReport: 'Оперативная сводка',
			sectionDescription: 'По ситуации с ландшафтными пожарами на территории Республики Саха (Якутия)',
			underway: 'Ведутся работы',
			suspended: 'Приост. Тушен.',
			unattended: 'Не обслуж.',
			activeArea: 'Действующих пожаров, Га',
			extinguishedArea: 'Ликвидировано пожаров, Га',
			noDanger: 'Угроз населенным пунктам нет',
			dailyExtinguished: 'Ликвидировано пожаров за сутки',
			dailyDetected: 'Обнаружено пожаров за сутки',
			dailyLocalized: 'Локализовано пожаров за сутки',
			dailyUnderway: 'Задействовано в работе за сутки',
			naturalGrouth: 'Естественный прирост пожаров',
			naturalGrouthShort: 'Ест. пр. пож.',
			personnel: 'Людей',
			vehicles: 'Тех. средств',
			seasonFires: 'Зарегистрировано пожаров с начала сезона',
			currentYear: 'Текущий год',
			lastYear: 'Аналогичный период прошлого года',
			seasonTotalCount: 'Всего, пожаров',
			seasonTotalArea: 'Общая площадь, Га',
			count: 'Кол-во',
			citiesCount: 'Населенных пунктов, шт',
			distance: 'Расст., км',
			citiesSpecial: 'На особом контроле',
			citiesUnderway: 'Ведутся работы',
			citiesUnattended: 'В пред. 10 км зоны',
			citiesSpecialTest: 'На особом контроле (20 км)',
			citiesUnderwayTest: 'Ведутся работы (50 км)',
			citiesUnattendedTest: 'В пред. 50 км зоны',
			dynamicsType: {
				forest: 'Динамика ситуации на территории лесного фонда (лесные пожары)',
				oopt: 'Динамика ситуации на особо охраняемых природных территориях',
				other: 'Динамика ситуации на иных категориях земель',
			},
		},
		address: {
			title: 'Адрес',
		},
		errand: {
			dashboard: 'Статистика исполнения поручений Главы РС(Я)',
			freeMode: 'Произвольный период',
			week: 'неделя',
			month: 'месяц',
			halfYear: 'полгода',
			year: 'год',
			org: 'Исполнитель',
		},
		map: {
			hpSelectModeDisabled: 'Включите слой термоточек',
			hpSelectAdd: 'Режим выделения: добавить термоточки',
			hpSelectSubtract: 'Режим выделения: удалить термоточки',
			hpSelectRadius: 'Радиус вокруг точки',
			metersShort: 'м',
			kmShort: 'км',
			searchHint: 'Поиск по широте/долготе',
			gridHint: 'Градусная сетка',
			export: 'Экспорт',
			exportHint: 'Экспортировать в PNG формате',
			exportFormat: 'Формат',
			exportResolution: 'Разрешение',
			startDrawing: 'Рисовать',
			startEditing: 'Редактировать',
			finishDrawing: 'Закончить',
			cancelDrawing: 'Отменить',
			hintEdit: 'Редактировать объекты.\\nДля удаления вершины Alt+клик.',
			hintPoint: 'Точка',
			hintPolygon: 'Полигон.\\nДля завершения клик по первой вершине.',
			hintLineString: 'Линия.\\nДля завершения повторный клик по последней вершине.',
			hintCircle: 'Окружность.\\nПервый клик - установить центр, второй - завершить.\\nПосле сохранения превратится в полигон.',
			hintDelete: 'Удалить объект',
		},
		technoZone: {
			title: 'Техногенная зона',
			plural: 'Техногенные зоны',
		},
		isdm: {
			title: 'ИСДМ',
			titleLong: 'Информационная система дистанционного мониторинга',
		},
		color: 'Цвет',
		smartDistrict: {
			title: 'SmartDistrict',
			map: 'Карта',
		},
		LayerConfig: {
			fill: 'Цвет заполнения',
			stroke: 'Цвет линии',
			width: 'Толщина линии',
			icon: 'Иконка',
		},
		LayerAdmBorder: {
			title: 'Административная граница',
			plural: 'Административные границы',
		},
		LayerAgriculture: {
			title: 'Агрокультура',
			plural: 'Агрокультура',
		},
		LayerAirTransportObj: {
			title: 'Аэропорт',
			plural: 'Аэропорты',
		},
		LayerAuthorityService: {
			title: 'Административное здание',
			plural: 'Административные здания',
		},
		LayerAutoService: {
			title: 'Автосервис',
			plural: 'Автосервисы',
		},
		LayerCemetery: {
			title: 'Кладбище',
			plural: 'Кладбища',
		},
		LayerCulture: {
			title: 'Объект культурно-досугового типа',
			plural: 'Объекты культурно-досугового типа',
		},
		LayerDrinkWaterProtectionZone: {
			title: 'LayerDrinkWaterProtectionZone',
			plural: 'LayerDrinkWaterProtectionZone',
		},
		LayerEducation: {
			title: 'Образовательное учреждение',
			plural: 'Образовательные учреждения',
		},
		LayerElectricLine: {
			title: 'Линия электропередачи',
			plural: 'Линии электропередачи',
		},
		LayerElectricPowerStation: {
			title: 'Электростанция',
			plural: 'Электростанции',
		},
		LayerElectricTransformer: {
			title: 'Трансформаторная подстанция',
			plural: 'Трансформаторные подстанции',
		},
		LayerEmergencyProtectionObj: {
			title: 'Объект информирования и оповещения',
			plural: 'Объекты информирования и оповещения',
		},
		LayerEngProtectionZone: {
			title: 'LayerEngProtectionZone',
			plural: 'LayerEngProtectionZone',
		},
		LayerEnvMonitoring: {
			title: 'Метеостанция',
			plural: 'Метеостанции',
		},
		LayerFloodArea: {
			title: 'Зона затопления',
			plural: 'Зоны затопления',
		},
		LayerForeshore: {
			title: 'LayerForeshore',
			plural: 'LayerForeshore',
		},
		LayerFunctionalZone: {
			title: 'LayerFunctionalZone',
			plural: 'LayerFunctionalZone',
		},
		LayerHazardArea: {
			title: 'LayerHazardArea',
			plural: 'LayerHazardArea',
		},
		LayerHealth: {
			title: 'Медицинская организация',
			plural: 'Медицинские организации',
		},
		LayerHeritage: {
			title: 'Объект культурного наследия',
			plural: 'Объекты культурного наследия',
		},
		LayerHeritageProtectionZone: {
			title: 'LayerHeritageProtectionZone',
			plural: 'LayerHeritageProtectionZone',
		},
		LayerManufacturing: {
			title: 'Производственный объект',
			plural: 'Производственные объекты',
		},
		LayerNaturalRiskZone: {
			title: 'LayerNaturalRiskZone',
			plural: 'LayerNaturalRiskZone',
		},
		LayerOilFacility: {
			title: 'Нефтебаза',
			plural: 'Нефтебазы',
		},
		LayerOtherProtectionZone: {
			title: 'LayerOtherProtectionZone',
			plural: 'LayerOtherProtectionZone',
		},
		LayerOtherZone: {
			title: 'LayerOtherZone',
			plural: 'LayerOtherZone',
		},
		LayerPublic: {
			title: 'Место публичного отдыха',
			plural: 'Места публичного отдыха',
		},
		LayerRecreation: {
			title: 'Гостиница',
			plural: 'Гостиницы',
		},
		LayerSanitaryProtectionZone: {
			title: 'LayerSanitaryProtectionZone',
			plural: 'LayerSanitaryProtectionZone',
		},
		LayerServiceFacility: {
			title: 'Объект производственной деятельности',
			plural: 'Объекты произв. деятельности',
		},
		LayerSewerFacility: {
			title: 'Канализационный объект',
			plural: 'Канализационные объекты',
		},
		LayerSewerPipeline: {
			title: 'Канализация',
			plural: 'Канализация',
		},
		LayerSocial: {
			title: 'Социальный объект',
			plural: 'Социальные объекты',
		},
		LayerSport: {
			title: 'Спортивный объект',
			plural: 'Спортивные объекты',
		},
		LayerStreet: {
			title: 'Улица',
			plural: 'Улицы',
		},
		LayerTechnoRiskArea: {
			title: 'LayerTechnoRiskArea',
			plural: 'LayerTechnoRiskArea',
		},
		LayerTelecomFacility: {
			title: 'Объект связи',
			plural: 'Объекты связи',
		},
		LayerThermalFacility: {
			title: 'Источник тепловой энергии',
			plural: 'Источники тепловой энергии',
		},
		LayerThermalPipeline: {
			title: 'Теплопровод',
			plural: 'Теплопроводы',
		},
		LayerTranspSanitaryGapZone: {
			title: 'LayerTranspSanitaryGapZone',
			plural: 'LayerTranspSanitaryGapZone',
		},
		LayerTransportObj: {
			title: 'Транспортный объект',
			plural: 'Транспортные объекты',
		},
		LayerWaterFacility: {
			title: 'Водопроводное сооружение',
			plural: 'Водопроводные сооружения',
		},
		LayerWaterPipeline: {
			title: 'Водопровод',
			plural: 'Водопровод',
		},
		LayerWaterProtectionZone: {
			title: 'LayerWaterProtectionZone',
			plural: 'LayerWaterProtectionZone',
		},
		LayerWaterTransportObj: {
			title: 'Причал',
			plural: 'Причалы',
		},
		LayerBridgeObj: {
			title: 'Мост',
			plural: 'Мосты',
		},
		LayerBathObj: {
			title: 'Баня',
			plural: 'Бани',
		},
		LayerSaunaObj: {
			title: 'Сауна',
			plural: 'Сауны',
		},
		LayerHairSalonObj: {
			title: 'Парикмахерская',
			plural: 'Парикмахерские',
		},
		LayerShopObj: {
			title: 'Магазин',
			plural: 'Магазины',
		},
		LayerEmergencyServiceObj: {
			title: 'Служба спасения',
			plural: 'Службы спасения',
		},
		LayerCategory: {
			title: 'Категория слоев',
			plural: 'Категории слоев',
			parentId: 'id родителя',
			parentName: 'Имя родителя',
			other: 'Остальное'
		},
		MapLayer: {
			title: 'Имя слоя',
			plural: 'Имена слоев',
			categoryName: 'Имя категории',
			categoryId: 'id категории',
		},
	},
};
