import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Form, Field, Button } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

@observer
export default class LayerCategory extends React.Component {
	@observable record = null;

	constructor(props) {
		super(props);
		this.init();
	}

	async init() {
		const id = parseInt(this.props.match.params.id);
		if (id) {
			this.record = await store.model.LayerCategory.findById(id);
		} else {
			this.record = new store.model.LayerCategory();
		}
	}

	getImageData = async (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = function () {
				const img = new Image();
				img.onload = function () {
					resolve({ base64: reader.result, width: img.width, height: img.height });
				};
				img.src = reader.result;
			};
			reader.onerror = function (error) {
				reject(error);
			};
			reader.readAsDataURL(file);
		});

	checkSvgSize = async (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = function (e) {
				const hdr = e.target.result.match(/.*(<svg[^>]+>).*/);
				if (hdr) {
					const widthMatch = hdr[1].match(/width=/);
					const heightMatch = hdr[1].match(/height=/);
					if (!widthMatch || !heightMatch) {
						reject(new Error('svg with no size'));
					}
					resolve(e.target.result);
				} else {
					reject(new Error('svg invalid'));
				}
			};
			reader.onerror = function (error) {
				reject(error);
			};
			reader.readAsText(file, 'UTF-8');
		});

	onAttach = async (e) => {
		this.error = null;
		const file = e.target.files[0];
		e.target.value = '';
		console.log('onAttach', file);
		this.icon = file;

		try {
			if (file.type === 'image/svg+xml') {
				await this.checkSvgSize(file);
			}
		} catch (e) {
			console.warn(e);
			this.error = e.message;
		}

		try {
			const { base64, width, height } = await this.getImageData(file);
			console.log('image', { width, height }, base64);
			this.record.icon = base64;
		} catch (e) {
			console.warn(e);
			this.error = e.message;
		}
	};

	deleteIcon = () => {
		this.record.icon = null;
	};

	render() {
		if (!this.record) return '...';
		const { icon } = this.record;

		return (
			<div className='layer-category-edit'>
				<h1>{t('LayerCategory.title')}</h1>
				<Form record={this.record} returnTo={`/smartdistrict/dictionaries/LayerCategory`}>
					<Field property='name' label={t('name')} />
					<Field>
						{icon ? <img src={icon} alt='' /> : <div className='no-icon' />}
						<div className='upload mt-2'>
							<input type='file' id='icon-upload' className='d-none' onChange={this.onAttach} accept='image/svg+xml,image/png' />
							<label htmlFor='icon-upload' className='sp-btn sp-btn-default'>
								Загрузить картинку
							</label>
							{icon && <Button onClick={this.deleteIcon}>Удалить картинку</Button>}
						</div>
					</Field>
				</Form>
			</div>
		);
	}
}
