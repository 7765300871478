import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import Profile from './Profile';
import { fio } from 'client/tools';
import store from 'client/store';

@observer
export default class User extends React.Component {
	@observable menuActive = false;
	@observable showProfilePopup = false;

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.onMouseDown);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.onMouseDown);
	}

	onMouseDown = (e) => {
		if (this.menuNode && !this.menuNode.contains(e.target)) {
			this.menuActive = false;
		}
	};

	onMount = (el) => (this.menuNode = el);

	profile = (e) => {
		e.preventDefault();
		this.showProfilePopup = true;
	};

	closeProfile = () => {
		this.showProfilePopup = false;
	};

	toggleMenu = () => {
		this.menuActive = !this.menuActive;
	};

	render() {
		return store.model.user ? (
			<div className='user-section' ref={this.onMount}>
				<a href='#' onClick={this.profile} className='profile-link'>
					<span className='fio'>{fio(store.model.user)}</span>
				</a>
				{this.showProfilePopup && <Profile onClose={this.closeProfile} />}
			</div>
		) : null;
	}
}
