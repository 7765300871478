import React from 'react';
import { observer } from 'mobx-react';

import { Column } from '@smartplatform/ui';
import { ModelList } from 'components';
import store from 'client/store';
import t from 'i18n';

@observer
export default class List extends React.Component {
	
	renderProperty = (property) => ({ record }) => {
		if (record[property] === null) return '-';
		if (typeof record[property] === 'object') return JSON.stringify(record[property]);
		return record[property];
	};
	
    render() {
		const { model } = this.props;
		
		const { id, ownerId, createdAt, updatedAt, deletedAt, ...properties } = store.model[model].PROPERTIES;
		
        return <div className="dict-list">
	        <ModelList
		        model={store.model[model]}
		        path={`/smartdistrict/dictionaries/${model}`}
		        pageSizeSelection
		        useExactModelName>
		        <Column property="id" label={t('id')} width={90} />
		        {Object.keys(properties).map((property) => (
					<Column
						key={property}
						property={property}
						label={properties[property].description || t(`${model}.${property}`)}>
						{this.renderProperty(property)}
					</Column>
		        ))}
	        </ModelList>
        </div>;
    }
}
