import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Popup, FormPlain, Row, Field, RelationSelect } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';

@observer
export default class Profile extends React.Component {

	@observable password = '';
	@observable passwordRepeat = '';
	@observable errors = [];

	constructor(props) {
		super(props);
	}

	onChangePassword = e => this.password = e.target.value;
	onChangePasswordRepeat = e => this.passwordRepeat = e.target.value;

	beforeSave = async user => {
		this.errors = [];
		if (this.password.length > 0 && this.passwordRepeat.length > 0 && this.password !== this.passwordRepeat) {
			this.errors.push(t('password.noMatch'));
		}

		if (this.errors.length === 0) {
			if (user.id) {
				if (this.password.length > 0) {
					user.password = this.password;
				}
				else {
					user.password = undefined;
				}
			}
			else {
				user.password = this.password;
			}
		}
		return this.errors.length === 0;
	};

	onSave = () => {
		this.props.onClose && this.props.onClose();
	};

	render() {
		const user = store.model.user;

		return <Popup className="profile" onClose={this.props.onClose}>
			<FormPlain record={user} noDelete beforeSave={this.beforeSave} onSave={this.onSave}>
				<Row>
					<Field property="lastName" label={t('user.lastName')} disabled />
					<Field property="firstName" label={t('user.firstName')} disabled />
					<Field property="middleName" label={t('user.middleName')} disabled />
				</Row>
				<Row>
					<Field property="username" label={t('user.username')} />
					<Field property="email" label={t('user.email')} disabled />
					<div />
				</Row>
				<Row>
					<div className="form-field password">
						<label>{t('password.new')}</label>
						<input
							type="password"
							autoComplete="new-password"
							value={this.password || ''}
							onChange={this.onChangePassword}
						/>
						<div className="password-hint">
							<i className="admin-icon-info-circled"/>
							{t('password.newHint')}
						</div>
					</div>
					<div className="form-field">
						<label>{t('password.repeat')}</label>
						<input
							type="password"
							value={this.passwordRepeat || ''}
							onChange={this.onChangePasswordRepeat}
						/>
					</div>
					<div/>
				</Row>
				<div className="form-errors">{this.errors.map((e, i) => <div key={i}>{e}</div>)}</div>
			</FormPlain>
		</Popup>;
	}

}
