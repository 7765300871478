import { observable } from 'mobx';

import store from 'client/store';
import t from 'i18n';

import silamLayers from './silamLayers';
import gfsLayers from './gfsLayers';

export const WEATHER_LAYERS = {
	...silamLayers,
	...gfsLayers,
};

export default class WeatherLayers {

	@observable layer = null;

	constructor(store) {
		this.store = store;
	}

	init = async (mapStore) => {
		this.mapStore = mapStore;
		this.addLayerToMap();
	};

	setLayer = (layerCode) => {
		console.log('setLayer', layerCode);
		this.store.weatherImage = null;
		this.store.layersSettings.weather.layer = layerCode;
		store.local.save();
		this.addLayerToMap();
	};

	addLayerToMap = () => {
		const weather = this.store.layersSettings.weather;
		if (this.mapLayer) this.mapStore.removeLayer(this.mapLayer);
		const weatherLayer = WEATHER_LAYERS[weather.layer];
		if (weatherLayer) {
			const { makeLayer, ...rest } = weatherLayer;
			this.layer = makeLayer(this.mapStore.map, {
				...rest,
				onLoad: this.store.onWebglImageLoad,
			});
			this.store.setWeatherLayer(weatherLayer);
			this.mapLayer = this.layer.getLayer();
			this.setVisible();
			this.mapStore.addLayer(this.mapLayer);
		}
		else {
			console.error('cannot add layer with code', weather.layer);
		}
	};

	// TODO: хак для установки zIndex слоёв с геометрией
	setFiresLayerZIndex = () => {
	};

	setVisible = () => {
		const weather = this.store.layersSettings.weather;
		if (this.mapLayer) {
			this.mapLayer.setVisible(weather.show);
		}
	};

	update = () => {
		// if (!this.store.layersSettings.weather.show) return;
		if (this.layer) this.layer.update();
	};

	isSilamLayer = (layerCode) => !!silamLayers[layerCode];

};
