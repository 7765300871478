import React from 'react';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';

import { Form, Button, Field, Loader } from '@smartplatform/ui';
import ACL from './ACL';
import { DeleteButton } from 'components';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

@observer
export default class Edit extends React.Component {
	
	@observable record = null;
	@observable acls = [];
	@observable isRole = false;
	@observable isSaving = false;

	@observable password = '';
	@observable passwordRepeat = '';
	@observable errors = [];

	constructor(props) {
		super(props);
		this.loadData();
	}

	@action loadData = async() => {
		this.id = this.props.match.params.id;
		if (this.id) {
			this.record = await store.model.Role.findById(this.id);
		}
		else {
			this.record = new store.model.Role();
		}
	};
	
	@action changeAcls = (acls) => {
		this.acls = acls;
	};

	setACLInstance = instance => this.aclInstance = instance;

	save = async () => {
		this.isSaving = true;
		try {
			await this.record.save();
			if (this.id && this.aclInstance) {
				await this.aclInstance.save();
				this.aclInstance.init();
			}
			if (!this.id) {
				store.route.push({ path: `/admin/roles/${this.record.id}` });
			}
		}
		catch(e) {
			this.error = e.message;
		}
		this.isSaving = false;
	};

	delete = async () => {
		await this.record.delete();
		if (this.aclInstance) {
			this.aclInstance.deleteAll();
		}
		store.route.push({ path: '/admin/roles' });
	};

	render() {
		if (!this.record) return '...';
		
		return <div className="block role-edit">
			{/*<div className="block-header">Роль</div>*/}
			<Form record={this.record} noSave noDelete>
				<Field property="name" label={t('role.name')}/>
				<Field property="description" label={t('role.description')}/>
			</Form>
			{this.record.id && <ACL role={this.record} instance={this.setACLInstance} />}
			<div className={'actions' + (this.isSaving ? ' disabled' : '')}>
				<Button onClick={this.save} className="btn-primary">{t('save')}</Button>
				{this.record.id && <DeleteButton onConfirm={this.delete} />}
				{this.isSaving && <span className="saving"><Loader size={18} /> {t('saving')}</span>}
			</div>
			<div className="form-errors">{this.errors.map((e, i) => <div key={i}>{e}</div>)}</div>
		</div>;
	}
}
