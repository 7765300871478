import React from 'react';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { getPointResolution } from 'ol/proj';
import { debounce } from 'lodash';

import './style.scss';
import t from 'i18n';

const VALUES = [
	{ value: 10, title: 10 + ' ' + t('map.metersShort') },
	{ value: 100, title: 100 + ' ' + t('map.metersShort') },
	{ value: 375, title: 375 + ' ' + t('map.metersShort') },
	{ value: 500, title: 500 + ' ' + t('map.metersShort') },
	{ value: 1000, title: 1 + ' ' + t('map.kmShort') },
	{ value: 10000, title: 10 + ' ' + t('map.kmShort') },
	{ value: 100000, title: 100 + ' ' + t('map.kmShort') },
	{ value: 1000000, title: 1000 + ' ' + t('map.kmShort') },
	{ value: 10000000, title: 10000 + ' ' + t('map.kmShort') },
];

const PREFERRED_WIDTH = 300;
const MARGIN = 50;

@observer
export default class ScaleLine extends React.Component {

	middlePoint = [ 0, 0 ];

	constructor(props) {
		super(props);
		this.onResize = debounce(this.onResize, 500, { leading: false, trailing: true });
	}

	componentDidMount() {
		this.props.mapStore.on('moveend', this.onMoveEnd);
		window.addEventListener('resize', this.onResize);
	}

	componentWillUnmount() {
		this.props.mapStore.off('moveend', this.onMoveEnd);
		window.removeEventListener('resize', this.onResize);
	}

	onMount = el => {
		this.el = el;
		this.calcMiddlePoint();
	};

	onResize = e => {
		this.calcMiddlePoint();
	};

	calcMiddlePoint = () => {
		if (this.el) {
			const rect = this.el.getBoundingClientRect();
			this.middlePoint = [ rect.left + rect.width / 2, rect.bottom ];
			// console.log('calcMiddlePoint', this.middlePoint);
		}
	};

	onMoveEnd = (e) => {
		const { map } = this.props.mapStore;
		const { resolution, projection } = e.frameState.viewState;

		const middleCoordinates = map.getCoordinateFromPixel(this.middlePoint);
		const pointResolution = getPointResolution(projection, resolution, middleCoordinates);

		// console.log('pointResolution', pointResolution, 'resolution', resolution);

		let min = 1000;
		let bestMatch = null;
		VALUES.forEach(valueObj => {
			const width = valueObj.value / pointResolution;
			const dif = Math.abs(width - PREFERRED_WIDTH);
			if (dif < min) {
				min = dif;
				bestMatch = valueObj;
			}
		});

		if (bestMatch) {
			const width = bestMatch.value / pointResolution;
			if (this.el) {
				this.el.style.width = width + 'px';
				const textEl = this.el.getElementsByClassName('scale-line-text')[0];
				if (textEl) textEl.innerHTML = bestMatch.title;
			}
		}
	};

	render() {
		return <div className="scale-line-container" ref={this.onMount}>
			<div className="scale-line" />
			<div className="scale-line-text" />
		</div>;
	}

}
