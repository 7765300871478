import React from 'react';
import { observer } from 'mobx-react';
import i18next from 'i18next';

import store from 'client/store';

@observer
export default class Language extends React.Component {
	onChange = async (lang) => {
		store.local.language = lang;
		store.local.save();
		i18next.changeLanguage(lang);

		setTimeout(() => {
			store.appKey++; // обновляем все приложение с задержкой
		}, 0);
	};

	render() {
		return (
			<div className='language-switch'>
				<span onClick={() => this.onChange('ru')} className={store.local.language === 'ru' ? 'sp-selected' : ''}>
					рус
				</span>{' '}
				|{' '}
				<span onClick={() => this.onChange('en')} className={store.local.language === 'en' ? 'sp-selected' : ''}>
					en
				</span>
			</div>
		);
	}
}
