import Airfield from './airfield';
import Camera from './camera';
import FireData from './heat-points';
import AviaDep from './aviadep';
import City from './city';

import LayerAdmBorder from './admborder';
import LayerAgriculture from './agriculture';
import LayerAirTransportObj from './airtransportobj';
import LayerAuthorityService from './authorityservice';
import LayerAutoService from './autoservice';
import LayerCemetery from './cemetery';
import LayerCulture from './culture';
import LayerDrinkWaterProtectionZone from './drinkwaterprotectionzone';
import LayerEducation from './education';
import LayerElectricLine from './electricline';
import LayerElectricPowerStation from './electricpowerstation';
import LayerElectricTransformer from './electrictransformer';
import LayerEmergencyProtectionObj from './emergencyprotectionobj';
import LayerEngProtectionZone from './engprotectionzone';
import LayerEnvMonitoring from './envmonitoring';
import LayerFloodArea from './floodarea';
import LayerForeshore from './foreshore';
import LayerFunctionalZone from './functionalzone';
import LayerHazardArea from './hazardarea';
import LayerHealth from './health';
import LayerHeritage from './heritage';
import LayerHeritageProtectionZone from './heritageprotectionzone';
import LayerManufacturing from './manufacturing';
import LayerNaturalRiskZone from './naturalriskzone';
import LayerOilFacility from './oilfacility';
import LayerOtherProtectionZone from './otherprotectionzone';
import LayerOtherZone from './otherzone';
import LayerPublic from './public';
import LayerRecreation from './recreation';
import LayerSanitaryProtectionZone from './sanitaryprotectionzone';
import LayerServiceFacility from './servicefacility';
import LayerSewerFacility from './sewerfacility';
import LayerSewerPipeline from './sewerpipeline';
import LayerSocial from './social';
import LayerSport from './sport';
import LayerStreet from './street';
import LayerTechnoRiskArea from './technoriskarea';
import LayerTelecomFacility from './telecomfacility';
import LayerThermalFacility from './thermalfacility';
import LayerThermalPipeline from './thermalpipeline';
import LayerTranspSanitaryGapZone from './transpsanitarygapzone';
import LayerTransportObj from './transportobj';
import LayerWaterFacility from './waterfacility';
import LayerWaterPipeline from './waterpipeline';
import LayerWaterProtectionZone from './waterprotectionzone';
import LayerWaterTransportObj from './watertransportobj';
import LayerBridgeObj from './bridgeobj';
import LayerBathObj from './bathobj';
import LayerSaunaObj from './saunaobj';
import LayerHairSalonObj from './hairsalonobj';
import LayerShopObj from './shopobj';
import LayerEmergencyServiceObj from './emergencyserviceobj';
import Organization from './organization';

export const modelsConfig = {
	Airfield,
	Camera,
	FireData,
	AviaDep,
	City,

	Organization,
	
	LayerAdmBorder,
	LayerAgriculture,
	LayerAirTransportObj,
	LayerAuthorityService,
	LayerAutoService,
	LayerCemetery,
	LayerCulture,
	LayerDrinkWaterProtectionZone,
	LayerEducation,
	LayerElectricLine,
	LayerElectricPowerStation,
	LayerElectricTransformer,
	LayerEmergencyProtectionObj,
	LayerEngProtectionZone,
	LayerEnvMonitoring,
	LayerFloodArea,
	LayerForeshore,
	LayerFunctionalZone,
	LayerHazardArea,
	LayerHealth,
	LayerHeritage,
	LayerHeritageProtectionZone,
	LayerManufacturing,
	LayerNaturalRiskZone,
	LayerOilFacility,
	LayerOtherProtectionZone,
	LayerOtherZone,
	LayerPublic,
	LayerRecreation,
	LayerSanitaryProtectionZone,
	LayerServiceFacility,
	LayerSewerFacility,
	LayerSewerPipeline,
	LayerSocial,
	LayerSport,
	LayerStreet,
	LayerTechnoRiskArea,
	LayerTelecomFacility,
	LayerThermalFacility,
	LayerThermalPipeline,
	LayerTranspSanitaryGapZone,
	LayerTransportObj,
	LayerWaterFacility,
	LayerWaterPipeline,
	LayerWaterProtectionZone,
	LayerWaterTransportObj,
	LayerBridgeObj,
	LayerBathObj,
	LayerSaunaObj,
	LayerHairSalonObj,
	LayerShopObj,
	LayerEmergencyServiceObj
};
