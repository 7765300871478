import React from 'react';
import { Fill, Stroke, Style } from 'ol/style';
import { asArray } from 'ol/color';
import { ColorUtils } from '@smartplatform/ui';

import OrganizationPopup from './OrganizationPopup';
import t from 'i18n';

const color = '#0af';

export default {
	modelName: 'Organization',
	title: t('organization.title'),
	filterTitle: t('organization.plural'),
	showInFilters: true,
	style: new Style({
		fill: new Fill({ color: asArray([...ColorUtils.hexToArray(color), 0.5]) }),
		stroke: new Stroke({ color, width: 1 }),
	}),
	priority: 2,
	renderPopup: record => <OrganizationPopup key={record.id} record={record} />,
};
