import React from 'react';
import HeatPointPopup from './HeatPointPopup';
import t from 'i18n';

export default {
	modelName: 'FireData',
	title: t('heatPoint.title'),
	filterTitle: t('heatPoint.plural'),
	showInFilters: false,
	priority: 2,
	renderPopup: record => <HeatPointPopup key={record.id} record={record} />,
};
