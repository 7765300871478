import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { MaskedInput } from '@smartplatform/ui';

const formatChars = {
	'+': '[-+]',
	1: '[0-1]',
	5: '[0-5]',
	7: '[0-7]',
	8: '[0-8]',
	9: '[0-9]',
};

/**
 * Ввод широты/долготы в градусах, минутах и секундах.
 * Помимо собственных пропов принимает все пропы <input />.
 */

@observer
export default class DegreeInput extends React.Component {

	@observable str = '';
	innerChange = false;

	static propTypes = {
		/** Тип ввода: широта или долгота (широта) */
		type: PropTypes.oneOf(['longitude', 'latitude']).isRequired,

		/** Значение, число в десятичном формате или null */
		value: PropTypes.oneOfType([ PropTypes.number, PropTypes.object ]),

		/**
		 * Обработчик изменения значения
		 * @param {number} value Значение в десятичном формате
		 * @param {SyntheticEvent} event Объект события
		 */
		onChange: PropTypes.func,

		disabled: PropTypes.bool,
		onFocus: PropTypes.func,
		onBlur: PropTypes.func,
	};

	static defaultProps = {
		disabled: false,
	};
	
	constructor(props) {
		super(props);
		this.str = props.value ? this.toDegrees(props.value) : '';
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!this.innerChange && prevProps.value !== this.props.value) {
			this.innerChange = false;
			this.str = this.props.value ? this.toDegrees(this.props.value) : '';
		}
	}

	onChange = (e) => {
		this.str = e.target.value;
		const decimalValue = this.toDecimal(this.str);
		this.innerChange = true;
		this.props.onChange && this.props.onChange(decimalValue, e);
	};

	onMount = (el) => this.el = el;

	onFocus = (e) => {
		if (!this.props.value) {
			this.str = '+';
			setTimeout(() => {
				if (this.el) this.el.setSelectionRange(1, 1);
			}, 0)
		}
		this.props.onFocus && this.props.onFocus(e);
	};

	onBlur = (e) => {
		this.str = this.str.replace(/_/g, '0');
		this.props.onBlur && this.props.onBlur(e);
	};

	toDecimal = (str) => {
		const [ hours, minutes, seconds ] = str.replace(/_/g, '0').slice(0, -1).split(/[^0-9-+]/).map(v => parseInt(v));
		// console.log('toDecimal', { hours, minutes, seconds }, 'str', str);
		let decimal = hours || 0;
		if (minutes) decimal += minutes / 60;
		if (seconds) decimal += seconds / 3600;
		return decimal;
	};

	toDegrees = (value) => {
		const pad = this.props.type === 'longitude' ? 3 : 2;
		const hours = Math.abs(Math.floor(value));
		const x = Math.round((value - hours) * 3600);
		const minutes = Math.floor(x / 60);
		const seconds = Math.round((x / 60 - minutes) * 60);
		// console.log('toDegrees', { hours, minutes, seconds });
		let str = String(hours).padStart(pad, '0') + '°';
		str += String(minutes).padStart(2, '0') + '\'';
		str += String(seconds).padStart(2, '0') + '\'';
		str = (value > 0 ? '+' : '-') + str;
		return str;
	};

	render() {
		const { type, onChange, className, value, ...rest } = this.props;
		const _className = classNames('monospaced', className);

		return <MaskedInput
			{...rest}
			mask={type === 'longitude' ? `+179°59'59"` : `+89°59'59"`}
			maskChar="_"
			value={this.str}
			onChange={this.onChange}
			className={_className}
			formatChars={formatChars}
			onBlur={this.onBlur}
			onFocus={this.onFocus}
			inputRef={this.onMount}
			alwaysShowMask
		/>;
	}
	
}
