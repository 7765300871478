import { action, observable } from 'mobx';

export default class UIStore {

	@observable topBar = true;
	@observable sideBar = true;

	topBarHeight = 80;
	sideBarWidth = 90;
	sideBarBtnSize = 80;

	constructor(store) {
		this.store = store;
	}

}
