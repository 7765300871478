import { observable } from 'mobx';
import { Tile } from 'ol/layer';
import { XYZ } from 'ol/source';

export default class GenShtabLayer {

	@observable counties = [];

	constructor(store) {
		this.store = store;
	}

	init = async (mapStore) => {
		this.mapStore = mapStore;

		this.layer = new Tile({
			source: new XYZ({
				projection: 'EPSG:3857',
				tileUrlFunction: function([z, x, y]) {
					const range = this.tileGrid.getFullTileRange(z);
					y = range.maxY - y;
					return '/gmap/' + z + '/' + x + '/' + y + '.png';
				},
				transition: 0,
			}),
			opacity: 0.79,
		});

		this.tileGrid = this.layer.getSource().getTileGrid();

		mapStore.addLayer(this.layer);
		this.layer.setZIndex(1);

		this.setVisible();
	};

	setVisible = () => {
		this.layer.setVisible(this.store.layersSettings.genShtab.show);
	};

};
