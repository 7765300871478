import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Hint } from 'client/components';
import { Checkbox, Loader } from '@smartplatform/ui';
import store from 'client/store';

@inject('settings') @observer
export default class FilterLayout extends React.Component {

	static propTypes = {
		layerName: PropTypes.string.isRequired,
		layerGroup: PropTypes.string,
		title: PropTypes.string.isRequired,
		onToggle: PropTypes.func,
		isLoading: PropTypes.bool,
		error: PropTypes.bool,
	};

	getConfig = () => {
		const { layerName, layerGroup, settings } = this.props;
		// console.log({ layerName, layerGroup, settings });
		return layerGroup ? settings[layerGroup][layerName] : settings[layerName];
	};

	onToggle = (value) => {
		const config = this.getConfig();
		console.log('onToggle', config, value);
		config.show = value;
		store.local.save();
		if (this.props.onToggle) this.props.onToggle(value);
	};

	toggleState = () => {
		const config = this.getConfig();
		config.minimized = !config.minimized;
		store.local.save();
	};

	render() {
		const { title, isLoading, error, children,legend } = this.props;
		const config = this.getConfig();

		return <div className="filters-layout">
			<div className="hdr">
				<div className="layer-toggle"><Checkbox value={config.show} onChange={this.onToggle} label={title} />
					{legend && <Hint text={legend} position='right' size='1x' offset={10} trigger='hover' />}
				</div>
				<div className="layer-actions">
					{error && <FontAwesomeIcon icon={faExclamationTriangle} className="error-icon" />}
					{isLoading && <Loader size={16} color="#000" />}
					{children && <div className="minimize-filter" onClick={this.toggleState}>
						<FontAwesomeIcon icon={faCaretUp} className={config.minimized ? 'minimized' : ''} />
					</div>}
				</div>
			</div>

			{children && !config.minimized && <div className={'settings'}>{children}</div>}
		</div>;
	}

}
