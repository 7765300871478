import { observable } from 'mobx';
import store from 'client/store';

class AuditStore {

	@observable records = [];
	@observable selected = null;

}

export default new AuditStore();
