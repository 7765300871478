import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AddIcon from '!!svg-react-loader!client/img/add-btn.svg';
import './style.scss';
import store from 'client/store';

export default class RowAddButton extends React.Component {
    static propTypes = {
        onClick: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        size: PropTypes.number,
        icon: PropTypes.object,
		model: PropTypes.string,
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        size: PropTypes.oneOfType([PropTypes.oneOf(['small', 'default', 'large', 'sm', 'md', 'lg']), PropTypes.number]),
    };

    static defaultProps = {
        disabled: false,
    };

    render() {
        let { disabled, model } = this.props;
		if (model) disabled = disabled || !store.model[model]?.INFO.WRITE;
        const className = classNames('add-row', { disabled });

        return (
            <div className={className}>
                <AddIcon {...this.props} className="add-btn" />
            </div>
        );
    }
}
