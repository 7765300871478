export const closedLayers = [
	'LayerAdmBorder',
	'LayerCemetery',
	'LayerDrinkWaterProtectionZone',
	'LayerElectricLine',
	'LayerElectricPowerStation',
	'LayerElectricTransformer',
	'LayerEmergencyProtectionObj',
	'LayerEngProtectionZone',
	'LayerEnvMonitoring',
	'LayerFloodArea',
	'LayerForeshore',
	'LayerFunctionalZone',
	'LayerHazardArea',
	'LayerHeritageProtectionZone',
	'LayerNaturalRiskZone',
	'LayerOtherProtectionZone',
	'LayerOtherZone',
	'LayerSanitaryProtectionZone',
	'LayerServiceFacility',
	'LayerSewerFacility',
	'LayerSewerPipeline',
	'LayerTechnoRiskArea',
	'LayerThermalFacility',
	'LayerThermalPipeline',
	'LayerTranspSanitaryGapZone',
	'LayerTransportObj',
	'LayerWaterFacility',
	'LayerWaterPipeline',
	'LayerWaterProtectionZone',
];

export const publicLayers = [
	'Organization',
	'LayerAirTransportObj',
	'LayerAgriculture',
	'LayerAuthorityService',
	'LayerAutoService',
	'LayerCulture',
	'LayerEducation',
	'LayerHealth',
	'LayerHeritage',
	'LayerManufacturing',
	'LayerOilFacility',
	'LayerPublic',
	'LayerRecreation',
	'LayerSocial',
	'LayerSport',
	'LayerStreet',
	'LayerTelecomFacility',
	'LayerWaterTransportObj',
	'LayerBridgeObj',
	'LayerBathObj',
	'LayerSaunaObj',
	'LayerHairSalonObj',
	'LayerShopObj',
	'LayerEmergencyServiceObj'
];
