import React from 'react';
import Popup from './Popup';
import t from 'i18n';

export default {
	modelName: 'LayerEmergencyServiceObj',
	title: t('LayerEmergencyServiceObj.title'),
	filterTitle: t('LayerEmergencyServiceObj.plural'),
	showInFilters: true,
	priority: 3,
	onlyGeo: true,
	renderPopup: record => <Popup key={record.id} record={record} />,
};
