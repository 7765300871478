import React from 'react';
import { inject, observer } from 'mobx-react';
import t from 'i18n';
import { Select, DatePicker, Checkbox } from '@smartplatform/ui';
import { DASHBOARD_SECTIONS } from './sections';
import { ExcelButton } from 'smartDistrict/components/buttons';
import { downloadReport } from 'smartDistrict/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

@inject('dashboardStore')
@observer
export default class ActionsPanel extends React.Component {
	constructor(props) {
		super(props);
		this.store = this.props.dashboardStore;
	}

	render() {
		return (
			<div className='action-panel'>
				<div className='actions'>
					<div className='left'>
						<div className='select-wrapper'>
							<Select
								items={DASHBOARD_SECTIONS.filter((i) => i.value !== this.store.section.value)}
								value={this.store.section}
								className='dashboard-section-select'
								onChange={this.store.onSectionChange}
								isRequired
								noSearch
								position='bottomRight'
							/>
						</div>
						<div>
							<span className='nav' onClick={this.store.prev}>
								<FontAwesomeIcon icon={faArrowLeft} />
							</span>
							<DatePicker
								value={this.store.date}
								onChange={this.store.onDateChange}
								showClearButton={false}
								isRequired
								position='bottomRight'
								className='green'
							/>
							<span className='nav' onClick={this.store.next}>
								<FontAwesomeIcon icon={faArrowRight} />
							</span>
						</div>
					</div>
					<div className='right'>
						{this.store.section.value === 2 && (
							<>
								<ExcelButton
									disabled={!this.store.report}
									onClick={() =>
										downloadReport({
											id: 'report-table',
											name: this.store.report.filename,
										})
									}
									text={t('report.download')}
								/>
								<Checkbox
									className='empty-sections ml-2'
									value={this.store.showEmptySections}
									onChange={this.store.onShowEmptySectionsChange}
									label='показать пустые секции(для демострации)'
								/>
							</>
						)}
					</div>
				</div>
				<span className='description'>{t('dashboard.sectionDescription')}</span>
			</div>
		);
	}
}
