import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';

import { Form, Field } from '@smartplatform/ui';
import store from '../../../store';
import GeoEdit from 'smartDistrict/dictionaries/geo-edit/GeoEdit';

@observer
export default class Edit extends React.Component {

	@observable record = null;
	@observable users = [];
	@observable isLoading = true;
	
	constructor (props) {
		super(props);
		this.init();
	}
	
	@action init = async () => {
		if (this.props.match.params.id) {
			this.record = await store.model.Organization.findById(this.props.match.params.id);
		} else {
			this.record = new store.model.Organization();
		}
		this.isLoading = false;
	};
	
	back = () => {
		this.props.history.push(`/admin/organizations`);
	};
	
	render () {
		if (this.isLoading) return '...';
		
		return !this.isLoading && (
			<Form record={this.record} stay onDelete={this.back} onSave={this.back}>
				<Field property="shortName" label="Наименование" />
				<Field property="name" label="Юридическое наименование" />
				<Field property="headName" label="Руководитель" />
				<Field property="inn" label="ИНН" />
				<Field property="kpp" label="КПП" />
				<Field property="address" label="Юридический адрес" />
				<Field property="geo" label="Координаты" ><GeoEdit /></Field>
				<Field property="phoneNumber" label="Телефон" />
				<Field property="email" label="E-mail" />
				<Field property="site" label="Сайт" />
				<Field property="schedule" label="Режим работы" />
			</Form>
		);
	}
}
