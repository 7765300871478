import React from 'react';
import { observer } from 'mobx-react';

import { Field } from '@smartplatform/ui';
import { ModelEdit } from 'components';
import GeoEdit from './geo-edit/GeoEdit';
import store from 'client/store';
import t from 'i18n';

@observer
export default class List extends React.Component {
	renderProperty = (property) => (record) => {
		return typeof record[property] !== 'object' ? record[property] : JSON.stringify(record[property]);
	};

	generateFields = (modelName) => {
		const { id, ownerId, createdAt, updatedAt, deletedAt, ...properties } = store.model[modelName].PROPERTIES;

		return Object.keys(properties).map((property) => {
			let editComponent = null;
			if (properties[property].type === 'Geography') {
				editComponent = <GeoEdit />;
			}

			const props = {
				property,
				key: property,
				label: properties[property].description || t(`${modelName}.${property}`),
				computed: editComponent ? undefined : this.renderProperty(property),
			};

			return <Field {...props}>{editComponent}</Field>;
		});
	};

	render() {
		const { model: modelName } = this.props;

		return (
			<div className='dict-edit'>
				<ModelEdit model={store.model[modelName]} path={`/smartdistrict/dictionaries/${modelName}`} useExactModelName>
					{this.generateFields(modelName)}
				</ModelEdit>
			</div>
		);
	}
}
