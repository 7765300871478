import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import Edit from './Edit';
import Content from '../Content';
import t from 'i18n';

export default class Roles extends React.Component {

	render() {
		return <Content title={t('role.plural')}>
			<Switch>
				<Route path="/admin/roles" exact component={List}/>
				<Route path="/admin/roles/create" component={Edit}/>
				<Route path="/admin/roles/:id" component={Edit}/>
			</Switch>
		</Content>;
	}
	
}
