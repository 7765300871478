import format from 'date-fns/format';
import formatRelative from 'date-fns/formatRelative';
import { ru } from 'date-fns/locale'
import { add, intervalToDuration } from "date-fns";

export const relative = date => {
	return date ? formatRelative(new Date(date), new Date, { locale: ru, weekStartsOn: 1 }) : null;
};

export const formatDate = (date, _format = 'dd.MM.yyyy') => date ? format(new Date(date), _format, { locale: ru, weekStartsOn: 1 }) : '';
export const formatTime = (int, _format = 'dd.MM.yyyy HH:mm:ss') => formatDate(new Date(int), _format);


const stringToTimeDuration = (string) => {
	const tempArray = string.split(':').map(el => parseInt(el));
	return {
		hours: tempArray[0],
		minutes: tempArray[1]
	}
};
// функция добавляет строковые интервалы типа '01:25' к дате
export const addTimeDurationToDate = (date, duration = '00:00') => add(new Date(date), stringToTimeDuration(duration));

// складывает два строковых интервала
export const sumTwoDurations = (duration1 = '00:00', duration2 = '00:00') => {
	const [d1h, d1m] = duration1.split(':');
	const [d2h, d2m] = duration2.split(':');
	const minutesSum = parseInt(d1m) + parseInt(d2m);
	const hoursSum = parseInt(d1h) + parseInt(d2h);
	const minutes = (minutesSum % 60).toString().padStart(2, 0);
	const hours = (hoursSum + Math.floor(minutesSum / 60)).toString().padStart(2, 0);
	return `${hours}:${minutes}`
}
// console.log(sumTwoDurations('00:42', '11:20'))
// console.log(sumTwoDurations('55:00', '00:1'))
// console.log(sumTwoDurations('00:02', '00:02'))