import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import Edit from './Edit';
import Content from '../Content';
import t from 'i18n';

export default class Organizations extends React.Component {
	
	render() {
		return <Content title={t('organization.plural')}>
			<Switch>
				<Route path="/admin/organizations" exact component={List}/>
				<Route path="/admin/organizations/create" component={Edit}/>
				<Route path="/admin/organizations/:id" component={Edit}/>
			</Switch>
		</Content>;
	}
	
}
