import React from 'react';
import PropTypes from 'prop-types';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { Form, Loader } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';

@withRouter @observer
export default class Edit extends React.Component {
	
	static propTypes = {
		model: PropTypes.any,
		path: PropTypes.string,
		returnTo: PropTypes.string,
		getRecord: PropTypes.func,
		beforeSave: PropTypes.func,
		onSave: PropTypes.func,
		stay: PropTypes.bool,
		noDelete: PropTypes.bool,
		disabled: PropTypes.bool,
		filter: PropTypes.object,
		noSave: PropTypes.bool,
		useExactModelName: PropTypes.bool, // использовать точное название модели в i18
	};
	
	static defaultProps = {
		useExactModelName: false,
	};

	@observable record = null;
	@observable error = null;
	@observable isLoading = true;

	isNew = false;
	
	constructor (props) {
		super(props);
		this.init();
	}

	@action init = async () => {
		this.isLoading = true;
		const id = this.props.id || this.props.match.params.id || null;
		if (id) {
			this.error = null;
			try {
				this.record = await this.props.model.findById(id, this.props.filter);
				this.props.getRecord && this.props.getRecord(this.record);
			} catch (e) {
				this.error = e.message;
			}
		} else {
			const { id, ...rest } = this.props.match.params;
			this.record = new this.props.model(rest);
			this.props.getRecord && this.props.getRecord(this.record);
			this.isNew = true;
		}
		this.isLoading = false;
	};
	
	onError = error => {
		console.log('onError', error);
	};
	
	onSave = async (record) => {
		if (this.props.onSave) await this.props.onSave(record);
		if (this.isNew && this.props.stay) {
			this.isNew = false;
			store.route.push({ path: `${this.props.path}/${record.id}` });
		}
		else {
			this.back();
		}
	};
	
	back = () => {
		store.route.push({ path: this.props.returnTo || this.props.path });
	};
	
	render () {
		let { children, beforeSave, noDelete, disabled, className, model, noSave, useExactModelName } = this.props;
		disabled = disabled || !model.INFO.WRITE;
		
		let output = <Loader />;
		if (this.error) {
			output = <div className="error">{this.error}</div>;
		}
		else if (!this.isLoading) {
			const props = {
				record: this.record,
				stay: true,
				onDelete: this.back,
				noSave,
				noDelete,
				disabled,
				onSave: this.onSave,
				onError: this.onError,
				beforeSave,
				className
			};
			output = <Form {...props}>{children}</Form>
		}
		
		const modelLowerName = useExactModelName ? model.name : model.name.charAt(0).toLowerCase() + model.name.slice(1);

		return !this.isLoading && <div className="fixed-page">
			<h1>{t(`${modelLowerName}.title`)}</h1>
			{output}
			{/*back: {this.makeRootPath(this.record)}*/}
		</div>;
	}
}
