import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Switch, Route } from 'react-router-dom';

import Content from '../Content';
import List from './List';
import Single from './Single';
import './style.scss';
import t from 'i18n';

@observer
export default class Audit extends React.Component {

	render() {
		return <Content title={t('audit.title')} className="audit">
			<List />
			<Switch>
				<Route path="/admin/audit/" exact><Single /></Route>
				<Route path="/admin/audit/:id"><Single /></Route>
			</Switch>
		</Content>;
	}

}
