import { observable } from 'mobx';
import { get as getProjection } from 'ol/proj';
import ImageStatic from 'ol/source/ImageStatic';
import WebglWindLayer from './webgl-wind-layer';

const worldExtent = [-20037508.342789244, -20037508.342789244, 20037508.342789244, 20037508.342789244];

export default class WindLayer {

	@observable isLoading = false;
	@observable error = false;

	level = '10 m above ground';
	min = -30;
	max = 30;

	constructor(store) {
		this.store = store;
	}

	init = async (mapStore) => {
		this.mapStore = mapStore;
		this.mapStore.on('cleanup', this.cleanup);

		this.source = this.makeSource();

		if (this.layer) {
			this.layer.remove();
			this.mapStore.removeLayer(this.layer);
		}

		const settings = this.store.layersSettings.wind;

		this.layer = new WebglWindLayer({
			extent: worldExtent,
			source: this.source,
			opacity: settings.opacity || 0.8,
			zIndex: 10,
			fadeOpacity: settings.fadeOpacity,
			speedFactor: settings.speedFactor,
			_mapRef: this.mapStore.map,
		});

		this.setVisible();
		this.mapStore.addLayer(this.layer);
	};

	makeSource = () => {
		this.isLoading = true;
		this.error = false;

		// const date = format(new Date(this.store.date), 'yyyy-MM-dd');
		const date = new Date(this.store.date);
		const hour = ((date.getHours() / 3) | 0) * 3;
		date.setHours(hour);

		const url = `/api/gfs?date=${date.toISOString()}&degree=0.25&layer=UGRD,VGRD&level=${this.level}&min=${this.min}&max=${this.max}&format=png`;

		const projection = getProjection('EPSG:4326');
		// const projection = getProjection('EPSG:3857');
		const imageExtent = projection.getExtent();

		const source = new ImageStatic({
			url,
			projection,
			imageExtent,
			crossOrigin: 'Anonimous',
		});

		source.on('imageloadend', () => this.isLoading = false);
		source.on('imageloaderror', this.onError);

		return source;
	};

	onError = () => {
		console.log('onError', this.store.date);
		this.isLoading = false;
		this.error = true;
	};

	setVisible = () => {
		const wind = this.store.layersSettings.wind;
		if (this.layer) {
			this.layer.setVisible(wind.show);
		}
		if (wind.show) {
			const animate = () => {
				this.frameId = requestAnimationFrame(() => {
					this.mapStore.map.render();
					animate();
				});
			};
			animate();
		}
		else {
			cancelAnimationFrame(this.frameId);
		}
	};

	update = () => {
		// if (this.layer) this.layer.update();
		this.source = this.makeSource();
		if (this.layer) {
			this.layer.setSource(this.source);
			this.layer.refreshSource();
		}
	};

	cleanup = (...args) => {
		// console.log('>>> cleanup!', args);
		cancelAnimationFrame(this.frameId);
	};

};
