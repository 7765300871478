import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Loader, Row } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { TextField } from 'smartDistrict/components';

@observer
export default class AviaDepPopup extends React.Component {

	@observable record = null;
	@observable isShowMore = false;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.record = await store.model.AviaDep.findById(this.props.record.id);
	};

	gotoRecord = (e) => {
		e.preventDefault();
		store.route.push({ path: `/smartdistrict/dictionaries/aviadep/${this.record.id}` });
	};

	render() {
		if (!this.record) return <Loader size={18} />;

		return <div className='aviaDep'>
			<div className='main-info'>
				<div className="title">
					<h2>{t('aviaDep.title')}: <span className='id' onClick={this.gotoRecord} >#{this.record.id}</span></h2>
				</div>
				<Row>
					<TextField label={t('aviaDep.name')} value={this.record.name} />
					<TextField label={t('aviaDep.code')} value={this.record.code} />
				</Row>
			</div>
		</div>;
	}

}
