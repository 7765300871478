import { observable } from 'mobx';
import { DASHBOARD_SECTIONS } from './sections';
import Chart from 'chart.js';
import { isToday, startOfDay, endOfDay, subDays, addDays } from 'date-fns';
import ru from 'date-fns/locale/ru';
import store from 'client/store';
import t from 'i18n';

export default class DashboardStore {
	
	@observable section = DASHBOARD_SECTIONS[0];
	@observable date = endOfDay(new Date());
	@observable isLoading = true;
	@observable citiesLoading = true;
	@observable data = {};
	@observable citiesData = {};
	@observable report = null;
	@observable showEmptySections = false;

	onShowEmptySectionsChange = async () => {
		this.showEmptySections = !this.showEmptySections;
		this.report = null;
		await this.getReportData()
	};

	onSectionChange = (section) => (this.section = DASHBOARD_SECTIONS.find((i) => i.value === section));

	//data requests
	getDashboardData = async () => {
		if (Object.keys(this.data).length === 0 || this.data.date !== this.date) {
			this.isLoading = true;
			this.data = await store.model.Fire.getDashboardData({
				date: this.date,
				startOfToday: startOfDay(this.date),
				tzOffset: this.date.getTimezoneOffset(),
				daysCount: 7,
				radius1: store.local.smartDistrict.citiesTest ? 20 : undefined,
				radius2: store.local.smartDistrict.citiesTest ? 50 : undefined,
			});
			this.data.date = this.date;
			console.log('> getDashboardData', this.date, this.data);
			this.isLoading = false;
		}
	};

	getDashboardCitiesData = async () => {
		if (Object.keys(this.citiesData).length === 0 || this.data.date !== this.date) {
			this.citiesLoading = true;
			this.citiesData = await store.model.Fire.getDashboardCitiesData({
				date: this.date,
				startOfToday: startOfDay(this.date),
				tzOffset: this.date.getTimezoneOffset(),
				daysCount: 7,
				radius1: store.local.smartDistrict.citiesTest ? 20 : undefined,
				radius2: store.local.smartDistrict.citiesTest ? 50 : undefined,
			});
			this.citiesLoading = false;
		}
	};

	getReportData = async () => {
		if (!this.report || this.report.date !== this.date) {
			this.isLoading = true;
			this.report = await store.model.FireReport.getReport({
				reportCode: 'FiresOnCountyAndDate',
				reportParams: {
					withCoordinates: true,
					endDate: this.date,
					showEmptySections: this.showEmptySections,
				},
			});
			this.report.date = this.date;
			this.isLoading = false;
		}
	};

	prev = () => {
		this.onDateChange(subDays(this.date, 1));
	};

	next = () => {
		this.onDateChange(addDays(this.date, 1));
	};

	onDateChange = (date) => {
		if (!date) return;
		this.date = endOfDay(date);
		if (this.section.value === 1) {
			this.getDashboardData();
			this.getDashboardCitiesData();
		} else if (this.section.value === 2) {
			this.getReportData();
		}
	};
}
