import { defaultsDeep } from 'lodash';
import { toJS } from 'mobx';
import store from 'client/store';
import { modelsConfig } from './map/models';

const models = {};

Object.values(modelsConfig).filter(modelConfig => !!modelConfig.showInFilters).forEach(modelConfig => {
	models[modelConfig.modelName] = { show: false };
});

const layers = {
	counties: {
		show: false,
	},
	genShtab: {
		show: false,
	},
	heatPoints: {
		show: false,
		minimized: true,
		period: 0,
		useMVT: false,
	},
	weather: {
		show: false,
		minimized: true,
		layer: 'pm25',
	},
	wind: {
		show: false,
		minimized: true,
		fadeOpacity: 0.995,
		speedFactor: 5,
		particleLife: 128,
		opacity: 0.8,
		webgl: true,
	},
	categories: {
		open: []
	},
	models,
};

const defaultConfig = {
	tileSource: 'yandex',
	latestData: false,
	startDate: null,
	filtersMaximized: false,
	showLonLat: false,
	hpSelectRadius: 375,
	hpRegion: 14,
	itemsPerPage: 10,
	layers,
	// миникарты в справочниках
	miniMap: {
		filtersMaximized: false,
		layers,
	}
}

export default function initLocal() {
	store.local.smartDistrict = defaultsDeep(toJS(store.local.smartDistrict), defaultConfig);
	store.local.save();
}
