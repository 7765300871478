import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import store from '../../store';
import './style.scss';

const DEFAULT_MARGIN = 15;

@withRouter
export default class Panel extends React.Component {

    static propTypes = {
        header: PropTypes.any,
        left: PropTypes.any,
        top: PropTypes.any,
        bottom: PropTypes.any,
        right: PropTypes.any,
        width: PropTypes.any,
        height: PropTypes.any,
        full: PropTypes.bool,
	    scrollable: PropTypes.bool,
        onClose: PropTypes.func,
        className: PropTypes.string,
	    wrapperClassName: PropTypes.string,
        margin: PropTypes.number,
	    getRef: PropTypes.func,
	    style: PropTypes.object,
    };

    static defaultProps = {
        left: 0,
        top: 0,
        width: 200,
        height: 200,
	    scrollable: true,
        bottom: 'auto',
        right: 'auto',
	    margin: DEFAULT_MARGIN,
	    style: {},
    };

    constructor(props) {
        super(props);
    }

    close = () => this.props.onClose && this.props.onClose();
	
	onMount = el => {
		if (el && this.props.getRef) this.props.getRef(el);
	};

    render() {
        const { top, right, bottom, left, width, height, full, margin, scrollable, header } = this.props;
	    const { isPublic } = store;

        const _topMargin = !isPublic && store.ui.topBar ? margin + store.ui.topBarHeight : margin;
        const _leftMargin = !isPublic && store.ui.topBar ? margin + store.ui.sideBarWidth : margin;

	    // const fullWidth = `calc(100% - ${margin + _leftMargin}px)`;
	    // const fullHeight = `calc(100% - ${margin + _topMargin}px)`;
	    const fullWidth = `calc(100% - ${store.ui.sideBarWidth}px)`;
	    const fullHeight = `calc(100% - ${store.ui.topBarHeight}px)`;

	    const style = full ?
            {
                // top: _topMargin,
                // left: _leftMargin,
                top: !isPublic ? store.ui.topBarHeight : 0,
                left: !isPublic ? store.ui.sideBarWidth : 0,
                width: fullWidth,
                height: fullHeight,
            }
            :
            {
                top: typeof top === 'number' ? top + _topMargin : top,
	            left: typeof left === 'number' ? left + _leftMargin  : left,
                right: typeof right === 'number' ? right + margin  : right,
                bottom: typeof bottom === 'number' ? bottom + margin : bottom,
                width,
                height,
            };

        const closeBtn = this.props.onClose && <span className="sc-panel-close-btn" onClick={this.close}>
	        <FontAwesomeIcon icon={faTimes}/>
        </span>;
        
	    const className = classNames('sc-panel-wrapper', this.props.wrapperClassName, {
		    scrollable,
		    'with-header': !!header,
		    'no-margin': margin === 0,
		    full,
	    });

	    const panelClassName = classNames('sc-panel', this.props.className, {
		    full,
	    })
	       
        return <div className={className} style={{ ...this.props.style, ...style }} ref={this.onMount}>
	        {this.props.header ?
		        <div className="header">
			        {this.props.header}
			        {closeBtn}
		        </div>
		        :
		        closeBtn
	        }
	        
            <div className={panelClassName}>
                {this.props.children}
            </div>
	        
        </div>;

    };

}
