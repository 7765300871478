import React from 'react';
import { observer, Provider } from 'mobx-react';
import { Panel } from 'components';
import './style.scss';
import ActionsPanel from './ActionsPanel';
import DashboardStore from './store';

@observer
export default class Dashboard extends React.Component {

	constructor(props) {
		super(props);
		this.store = new DashboardStore();
	}

	render() {

		return <Provider dashboardStore={this.store} >
			<Panel full className="sd-dashboard">
				<ActionsPanel />
				{this.store.section?.component}
			</Panel>
		</Provider>
	}

}
