import React from 'react';
import { Fill, Stroke, Style, Icon, Circle as CircleStyle } from 'ol/style';
import { asArray } from 'ol/color';
import { ColorUtils } from '@smartplatform/ui';

import Popup from './Popup';
import t from 'i18n';

export default {
	modelName: 'LayerSport',
	title: t('LayerSport.title'),
	filterTitle: t('LayerSport.plural'),
	showInFilters: true,
	priority: 3,
	onlyGeo: true,
	renderPopup: record => <Popup key={record.id} record={record} />,
};
