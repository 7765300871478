import { observable } from 'mobx';
import ImageStatic from 'ol/source/ImageStatic';
import { get as getProjection } from 'ol/proj';
import { format } from 'date-fns';

import { WebglImageLayer } from '@smartplatform/map/client';
import mainStore from '../../mainStore';
import * as colors from './colors';
import t from 'i18n';
import { ozoneColors } from './colors';

const extent = [-20037508.342789244, -20037508.342789244, 20037508.342789244, 20037508.342789244];

class SilamLayer {

	@observable isLoading = false;
	@observable error = false;

	source = null;
	layer = null;

	constructor(params = {}) {
		this.params = params;
		
		this.source = this.makeSource();
		
		this.layer = new WebglImageLayer({
			source: this.source,
			opacity: 0.7,
			colors: params.colors,
			extent,
			onLoad: this.onWebglImageLoad,
		});
	}
	
	getLayer = () => this.layer;

	update = () => {
		this.source = this.makeSource();
		this.layer.setSource(this.source);
		this.layer.refreshSource();
	};

	onWebglImageLoad = (img, colorRamp) => {
		const canvas = document.createElement('canvas');
		const context = canvas.getContext('2d');
		canvas.width = img.width;
		canvas.height = img.height;
		context.drawImage(img, 0, 0);
		this.params.onLoad(canvas, colorRamp);
	};

	makeSource = () => {
		this.isLoading = true;
		this.error = false;

		const date = format(new Date(mainStore.date), 'yyyy-MM-dd');
		let { code, min, max, maxLimit } = this.params;
		
		const url = `/api/silam?date=${date}&layer=${code}&min=${min}&max=${max}${maxLimit ? '&maxLimit=' + maxLimit : ''}&palette=greyscale`;
		
		// const projection = getProjection('EPSG:4326');
		const projection = getProjection('EPSG:3857');
		const imageExtent = projection.getExtent();

		const source = new ImageStatic({
			url,
			projection,
			imageExtent,
		});

		source.on('imageloadend', () => this.isLoading = false);
		source.on('imageloaderror', this.onError);

		return source;
	};

	onError = (e) => {
		this.isLoading = false;
		this.error = true;
	};

}

export default {
	pm25: {
		name: t('weather.pm25'),
		type: 'silam',
		description: t('weather.pm25Description'),
		colors: colors.silamColors,
		code: 'daymax_cnc_PM2_5',
		min: -5,
		max: 300,
		makeLayer: (map, params) => new SilamLayer(params),
		convert: value => Math.round(value),
		measure: t('weather.pm25Measure'),
	},
	pm10: {
		name: t('weather.pm10'),
		type: 'silam',
		description: t('weather.pm10Description'),
		colors: colors.silamColors,
		code: 'daymax_cnc_PM10',
		min: 1,
		max: 2000,
		maxLimit: 4000,
		makeLayer: (map, params) => new SilamLayer(params),
		convert: value => Math.round(value),
		measure: t('weather.pm10Measure'),
	},
	co: {
		name: t('weather.co'),
		type: 'silam',
		description: t('weather.coDescription'),
		colors: colors.silamColors,
		code: 'daymax_cnc_CO',
		min: 129.8,
		max: 10000,
		makeLayer: (map, params) => new SilamLayer(params),
		convert: value => Math.round(value),
		measure: t('weather.coMeasure'),
	},
	no: {
		name: t('weather.no'),
		type: 'silam',
		description: t('weather.noDescription'),
		colors: colors.silamColors,
		code: 'daymax_cnc_NO',
		min: 0,
		max: 175,
		makeLayer: (map, params) => new SilamLayer(params),
		convert: value => Math.round(value),
		measure: t('weather.noMeasure'),
	},
	no2: {
		name: t('weather.no2'),
		type: 'silam',
		description: t('weather.no2Description'),
		colors: colors.silamColors,
		code: 'daymax_cnc_NO2',
		min: 0,
		max: 220,
		makeLayer: (map, params) => new SilamLayer(params),
		convert: value => Math.round(value),
		measure: t('weather.no2Measure'),
	},
/*
	o3: {
		name: t('weather.ozone'),
		type: 'silam',
		description: t('weather.ozoneDescription'),
		colors: colors.ozoneColors,
		code: 'daymax_cnc_O3',
		min: 0,
		max: 200,
		makeLayer: (map, params) => new SilamLayer(params),
		convert: value => Math.round(value),
		measure: t('weather.ozoneMeasure'),
	},
*/
	so2: {
		name: t('weather.so2'),
		type: 'silam',
		description: t('weather.so2Description'),
		colors: colors.silamColors,
		code: 'daymax_cnc_SO2',
		min: 0,
		max: 250,
		makeLayer: (map, params) => new SilamLayer(params),
		convert: value => Math.round(value),
		measure: t('weather.so2Measure'),
	},
};
