import React from 'react';
import { Switch, Router, Route, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';

import store from './store';
import Layout from './layout/Layout';
import { Dashboard, Admin, DndContext, Test } from './components';
import MODULES from './modules';
// import { Widget } from './modules/covid/components';
import Login from './Login';

import 'bootstrap-4-grid/scss/grid.scss';
import './styles/main.scss';

@observer
export default class App extends React.Component {

	render() {
		if (!store.model.isInitialized) return null;
		if (!store.model.isAuthorized) return <Login />;

		let defaultRoute = store.config.defaultRoute;

		const modules = store.config.modules.map(moduleName => MODULES[moduleName]);
		const routes = [];
		for (const module of modules) {
			if (module.access()) routes.push(module.path);
		} 
		if (!routes.includes(defaultRoute) && routes.length > 0) {
			defaultRoute = routes[0];
		}

		return <DndContext key={store.appKey}>
			<Router history={store.history} onUpdate={() => window.scrollTo(0, 0)}>
				<Switch>
					{/*<Route path="/covid19/widget" component={Widget} />*/}
					<Route>
						<Layout>
							<Switch>
								{/*<Route path="/" exact component={Dashboard}/>*/}
								{/*<Route path="/test" component={Test}/>*/}
								<Route path="/" exact><Redirect to={defaultRoute}/></Route>
								<Route path="/admin" component={Admin}/>
								{modules.map(module => {
									return module.access() ?
										<Route key={module.path} path={module.path} component={module.component}/>
										:
										<Route key={module.path} path={module.path}><Redirect to="/" /></Route>;
								})}
							</Switch>
						</Layout>
					</Route>
				</Switch>
			</Router>
		</DndContext>;
	}
}
