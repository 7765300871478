import React from 'react';
import { observer } from 'mobx-react';
import { Switch, Route } from 'react-router-dom';	
import Dictionaries from './dictionaries'
import Map from './map';
import { Test } from 'components';
import Dashboard from './dashboard';
import Filters from './map/filters';
import store from 'client/store';
import mainStore from './mainStore';
import module from './index';
import t from 'i18n';

@observer
export default class Root extends React.Component {

	constructor(props) {
		super(props);
		if (!mainStore.isInitialized) mainStore.init();
		store.fires = mainStore;
	}

	componentDidMount() {
		document.title = module.name + '. ' + t('title');
		store.module = module;
	}

	componentWillUnmount() {
		document.title = t('title');
		store.module = null;
		// mainStore.destroy();
	}

	render() {
		if (!mainStore.isInitialized) return null;

		return <>
			<Map />
			<Filters />
			<Switch>
				<Route path={`${module.path}/dictionaries`} component={Dictionaries} />
				<Route path={`${module.path}/test`} component={Test} />
			</Switch>
		</>;
	}

}
