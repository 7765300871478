import React from 'react';
import { observer } from 'mobx-react';

import { Map, Popup } from '@smartplatform/map/client';
import mainStore, { PARAMS } from '../mainStore';
import Timeline from './timeline';
import MainPopup from './MainPopup';
import Scale from './Scale';
import ScaleLine from './scale-line';
import store from 'client/store';
import './style.scss';

@observer
export default class MainMap extends React.Component {

	render() {
		const { popup } = mainStore;

		const params = {
			...(store.config.map || PARAMS),
			defaultTileSource: store.local.smartDistrict.tileSource,
			onTileSourceChange: mainStore.onTileSourceChange,
		};
		
		const isMapInitialized = mainStore.mapInitialized;
		
		return <Map className="main-map full-map" onInit={mainStore.onMapInit} params={params} >
			{popup && <Popup {...popup} width={popup.width || 550}>
				<MainPopup {...popup} />
			</Popup>}
			<Timeline mainStore={mainStore} />
			{isMapInitialized && <ScaleLine mapStore={mainStore.mapStore} />}
			<Scale store={mainStore} hidden={!store.local.smartDistrict.layers.weather.show} />
		</Map>
	}

}
