import React from 'react';
import { observer } from 'mobx-react';

import ListHeader from './ListHeader';
import Items from './Items';

const ListTotal = observer(({ list }) => list.totalCount > list.items.length && <div className="kb-list-info">
		еще: {list.totalCount - list.items.length}
	</div>
);

export default props => (
	<div className="kb-list" data-listid={props.list.id}>
		<ListHeader list={props.list} />
		<Items {...props} />
		<ListTotal list={props.list} />
	</div>
);
