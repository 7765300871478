import React from 'react';
import { observer, Provider } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { Panel } from 'components';
import Counties from './Counties';
import GenShtab from './GenShtab';
import HeatPoints from './HeatPoints';
import Weather from './Weather';
import Models from './Models';
import Wind from './Wind';
import store from 'client/store';
import mainStore from '../../mainStore';
import './style.scss';

@observer
export default class Filters extends React.Component {

	render() {
		let { settings, store:controlStore = mainStore } = this.props;
		const { isPublic } = store;
		if (!settings) settings = store.local.smartDistrict.layers;

		return store.local.smartDistrict.filtersMaximized ?
			<Provider settings={settings} mainStore={controlStore}>
				<Panel left={-5} top={5} width={290} bottom={70} height="auto" scrollable={false} wrapperClassName={`${store.isPublic ? 'fires-filters-wrapper-public' : 'fires-filters-wrapper'}` + (!isPublic ? ' private' : '')} className="map-filters">
					<div className="minimize-btn" onClick={() => controlStore.switchFilters(false)}><FontAwesomeIcon icon={faFilter} /></div>
					{/*{!isPublic && <Countries layer={controlStore.countriesLayer} />}*/}
					{/*{!isPublic && <Dzz layer={controlStore.dzzLayer} />}*/}
					{/*{!isPublic && <GenShtab layer={controlStore.genShtabLayer} />}*/}
					<Models layer={controlStore.modelsLayer} />
					<Counties layer={controlStore.countiesLayer} />
					<HeatPoints layer={controlStore.heatPointsLayer} store={controlStore} />
					<Weather layer={controlStore.weatherLayer} toggleFunc={controlStore.toggleWeatherLayer} />
					{!isPublic && <Wind layer={controlStore.windLayer} />}
				</Panel>
			</Provider>
			:
			<div className={'fires-filters-minimized' + (!isPublic ? ' private' : '')} onClick={() => controlStore.switchFilters(true)}>
				<FontAwesomeIcon icon={faFilter} />
			</div>;
	}
}
