import React from 'react';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faSave } from '@fortawesome/free-solid-svg-icons';

import { ColorUtils } from '@smartplatform/ui';

import NewItem from './NewItem';
import kStore from './store';

@observer
export default class ListHeader extends React.Component {

	addItem = () => kStore.addingToList = this.props.list;
	
	submit = async text => {
		await kStore.addItem(text, this.props.list);
	}

	export = async () => {
		if (kStore.exportList) {
			await kStore.exportList(this.props.list);
		}
	};
	
	cancel = () => kStore.addingToList = null;
	
	render() {
		const { list } = this.props;

		const color = ColorUtils.textColor(list.color);

		return <>
			<div className="hdr" style={{ borderLeftColor: list.color, background: list.color, color }}>
				<div className="name">{list.name}</div>
				<div className="actions">
					<div className="count">{list.totalCount}</div>
					<div className="kb-btn" onClick={this.export}><FontAwesomeIcon icon={faSave} style={{ color }} /></div>
					<div className="kb-btn" onClick={this.addItem}><FontAwesomeIcon icon={faPlusCircle} style={{ color }} /></div>
				</div>
			</div>
			{(kStore.addingToList && kStore.addingToList.id === list.id) &&
				<NewItem list={list} onSubmit={this.submit} onCancel={this.cancel} />
			}
		</>;
	}
	
}
