import React from 'react';
import classNames from 'classnames';

import { Panel } from 'components';
import { MENU_MARGIN } from './Admin';
import { FILTERS_MARGIN } from 'components';

export default class Content extends React.Component {

	render() {
		const { title, className, children } = this.props;

		const _className = classNames('admin-content', className);

		return <Panel header={title} right={0} left={MENU_MARGIN} className={_className} top={0} bottom={0} width="auto" height="auto" scrollable>
			{children}
		</Panel>;
	}

}
