import React from 'react';
import { observer, inject } from 'mobx-react';
import { action, observable, autorun } from 'mobx';
import { differenceInDays, startOfDay, endOfDay, addDays, subDays, isBefore } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight, faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';

import { RecordSelect, Button, DatePicker, Checkbox } from '@smartplatform/ui';
import FilterLayout from './FilterLayout';
import Hint from './Hint';
import store from 'client/store';
import mainStore from '../../mainStore';
import t from 'i18n';

const MAX_PERIOD = 365; // максимальный период в днях

@inject('settings') @observer
export default class HeatPointsFilter extends React.Component {

	@observable changed = false;
	@observable count = null;

	constructor(props) {
		super(props);
		this.startDateValue = props.store.heatPointsStartDate;
		this.endDateValue = props.store.heatPointsEndDate;
	}

	componentDidMount() {
		if (this.props.settings.heatPoints.show) {
			this.getCount();
		}
	}

	onToggle = (value) => {
		this.props.layer.setVisible();
		if (value) this.props.layer.update();
	};

	setPeriod = (period) => {
		// console.log('setPeriod', period);
		this.props.settings.heatPoints.period = period;
		store.local.save();
		this.props.layer.update();
	};

	@action setStartDate = (date) => {
		if (date === null) {
			return;
		}
		this.changed = true;
		this.props.store.heatPointsStartDate = startOfDay(date);
		if (isBefore(this.props.store.heatPointsEndDate, this.props.store.heatPointsStartDate)) {
			this.props.store.heatPointsEndDate = endOfDay(this.props.store.heatPointsStartDate);
		}
		else {
			const dif = differenceInDays(this.props.store.heatPointsEndDate, this.props.store.heatPointsStartDate);
			if (dif > MAX_PERIOD) {
				this.props.store.heatPointsEndDate = endOfDay(addDays(this.props.store.heatPointsStartDate, MAX_PERIOD));
			}
		}
		this.startDateValue = this.props.store.heatPointsStartDate;
	};

	@action setEndDate = (date) => {
		if (date === null) {
			return;
		}
		this.changed = true;
		this.props.store.heatPointsEndDate = endOfDay(date);
		if (isBefore(this.props.store.heatPointsEndDate, this.props.store.heatPointsStartDate)) {
			this.props.store.heatPointsStartDate = startOfDay(this.props.store.heatPointsEndDate);
		}
		else {
			const dif = differenceInDays(this.props.store.heatPointsEndDate, this.props.store.heatPointsStartDate);
			if (dif > MAX_PERIOD) {
				this.props.store.heatPointsStartDate = startOfDay(subDays(this.props.store.heatPointsEndDate, MAX_PERIOD));
			}
		}
		this.endDateValue = this.props.store.heatPointsEndDate;
	};

	update = () => {
		this.changed = false;
		this.props.onLoadStart && this.props.onLoadStart();
		this.getCount();
		this.props.layer.update();
	}

	prev = () => {
		this.props.store.heatPointsStartDate = subDays(this.props.store.heatPointsStartDate, 1);
		this.props.store.heatPointsEndDate = subDays(this.props.store.heatPointsEndDate, 1);
		this.props.onLoadStart && this.props.onLoadStart();
		this.getCount();
		this.props.layer.update();
	};

	next = () => {
		this.props.store.heatPointsStartDate = addDays(this.props.store.heatPointsStartDate, 1);
		this.props.store.heatPointsEndDate = addDays(this.props.store.heatPointsEndDate, 1);
		this.props.onLoadStart && this.props.onLoadStart();
		this.getCount();
		this.props.layer.update();
	};

	getCount = async () => {
		const { layer, store: controlStore, settings } = this.props;
		const dataSourceId = layer.dataSource ? layer.dataSource.id : 3;
		const regionId = store.local.smartDistrict.hpRegion || undefined;
		this.count = await store.model.FireData.getCount({
			dataSourceId,
			regionId,
			start: this.props.store.heatPointsStartDate,
			end: this.props.store.heatPointsEndDate,
		});
	};

	setHeatPointsRegion = (value) => {
		mainStore.setHeatPointsRegion(value);
		this.getCount();
	};

	render() {
		const { store: controlStore } = this.props;
		const settings = this.props.settings.heatPoints;
		const layer = this.props.layer;

		return <FilterLayout layerName="heatPoints" onToggle={this.onToggle} title={t('heatPoint.plural')} isLoading={layer.isLoading}>
			<div className="mb-2">
				<label>{t('heatPoint.source')}</label>
				<RecordSelect
					model={store.model.FireDataSource}
					property="name"
					value={layer.dataSource}
					onChange={layer.switchSource}
					width={200}
					position="bottomLeft"
					isRequired
					noSearch
					disabled={!settings.show}
				/>
			</div>
			<div className="mb-2 heat-points-period">
				<label>{t('heatPoint.period')}</label>
				<DatePicker
					value={controlStore.heatPointsStartDate}
					onChange={this.setStartDate}
					showClearButton={false}
					isRequired
					className="start"
				/>
				<DatePicker
					value={controlStore.heatPointsEndDate}
					onChange={this.setEndDate}
					showClearButton={false}
					isRequired
				/>
				<Hint text={t('heatPoint.periodHint')} />
			</div>
			<div className="mt-1">
				<Button onClick={this.update} disabled={!this.changed || layer.isLoading} size="sm" variant="primary">{t('heatPoint.reload')}</Button>
				<Button className="arr-btn" onClick={this.prev} disabled={this.changed || layer.isLoading} variant="link"><FontAwesomeIcon icon={faArrowAltCircleLeft} /></Button>
				<Button className="arr-btn" onClick={this.next} disabled={this.changed || layer.isLoading} variant="link"><FontAwesomeIcon icon={faArrowAltCircleRight} /></Button>
				{' '}
				<Checkbox
					value={!store.local.smartDistrict.hpRegion}
					onChange={this.setHeatPointsRegion}
					label={t('heatPoint.eurasia')}
				/>
			</div>
			<div>
				<span className="hp-legend today" /> {t('heatPoint.today') + ' '}
				<span className="hp-legend yesterday" /> {t('heatPoint.yesterday') + ' '}
				<span className="hp-legend earlier" /> {t('heatPoint.earlier')}
			</div>
{/*
			<div>
				<Checkbox
					value={store.local.smartDistrict.layers.heatPoints.useMVT}
					onChange={mainStore.setHeatPointsVersion}
					label="MVT-версия (тест)"
				/>
			</div>
*/}
			<div>
				{t('heatPoint.plural')}: <>
					{settings.show ?
						<strong>{this.count === null ? '-' : this.count}</strong>
						:
						t('off')
					}
				</>
			</div>
		</FilterLayout>;
	}

}
