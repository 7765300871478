import React from 'react';
import Popup from './Popup';
import t from 'i18n';

export default {
	modelName: 'LayerSaunaObj',
	title: t('LayerSaunaObj.title'),
	filterTitle: t('LayerSaunaObj.plural'),
	showInFilters: true,
	priority: 3,
	onlyGeo: true,
	renderPopup: record => <Popup key={record.id} record={record} />,
};
