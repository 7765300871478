import React from 'react';
import { observable } from 'mobx';
import VectorTileSource from 'ol/source/VectorTile';
import TileGrid from 'ol/tilegrid/TileGrid';
import Feature from 'ol/Feature';
import { Point } from 'ol/geom';
import { transform, get as getProj, fromLonLat } from 'ol/proj';
import MVT from 'ol/format/MVT';
import { Geometry } from 'wkx';

import CustomMVTWebglLayer from './custom-mvt-webgl';
import store from 'client/store';

const TEXTURE_SIZE = 128;
const TILE_SIZE = 2048;

const serialize = function(obj, prefix) {
	const str = [];
	for (let p in obj) {
		if (obj.hasOwnProperty(p)) {
			const k = prefix ? prefix + "[" + p + "]" : p;
			const v = obj[p];
			str.push((v !== null && typeof v === "object") ?
				serialize(v, k)
				:
				encodeURIComponent(k) + "=" + encodeURIComponent(v));
		}
	}
	return str.join("&");
}


export default class HeatPoints {

	@observable isLoading = false;
	@observable source = null;
	@observable count = null;
	@observable error = null;
	@observable dataSource = null;

	initialized = false;
	overHeatPoint = false;

	constructor(mainStore) {
		this.mainStore = mainStore;
		this.mainStore.addFeatureGetter && this.mainStore.addFeatureGetter(this.getPointAtPixel);
	}

	init = async (mapStore) => {
		this.mapStore = mapStore;
		this.dataSource = await store.model.FireDataSource.findById(3);

		const resolutions = [];
		for (let i = 0; i <= 8; ++i) {
			resolutions.push(156543.03392804097 / Math.pow(2, i * 2));
		}

		this.source = new VectorTileSource({
			format: new MVT(),
			tileGrid: new TileGrid({
				extent: getProj('EPSG:3857').getExtent(),
				resolutions,
				tileSize: TILE_SIZE,
			}),
			tileUrlFunction: this.tileUrlFunction,
			tileSize: TILE_SIZE,
		});

		this.layer = new CustomMVTWebglLayer({
			source: this.source,
			pointSizeFunc: this.pointSize,
			bufferSetupFunc: this.bufferSetup,
			tileSize: TILE_SIZE,
			onLoadStart: this.onLoadStart,
			onLoadEnd: this.onLoadEnd,
			onMouseMove: this.onMouseMove,
			onInit: this.onInit,
		});

		this.layer.setZIndex(5);
		mapStore.addLayer(this.layer);

		mapStore.on('zoomend', this.onZoomEnd);

		this.setVisible();
		this.initialized = true;
	};
	
	onInit = (layer) => {
		const texture = this.createTexture(['#d00', '#f80', '#888']);
		layer.setTexture(texture, TEXTURE_SIZE, 3);
	}

	onLoadStart = () => this.isLoading = true;
	onLoadEnd = () => this.isLoading = false;

	onZoomEnd = () => {
		if (this.layer) this.layer.checkZoom();
	};

	update = async () => {
		if (this.layer) this.layer.update();
	};

	remove = () => {
		if (this.mapStore && this.layer) {
			this.layer.cleanUp();
			this.mapStore.removeLayer(this.layer);
			this.layer = null;
		}
	};

	tileUrlFunction = (coords) => {
		const settings = this.mainStore.layersSettings.heatPoints;
		if (!settings.show) return;

		const start = this.mainStore.heatPointsStartDate;
		const end = this.mainStore.heatPointsEndDate;

		let filters = [
			`sourceId=${this.dataSource.id}`,
			`technoZoneId is null`,
		];
		if (store.local.smartDistrict.hpRegion) filters.push(`regionId=${store.local.smartDistrict.hpRegion}`);

		// const url = `/api/mvt?model=firedata&x=${coords[1]}&y=${coords[2]}&z=${coords[0]}${filter}&columns=${columns}`;

		const query = {
			x: coords[1],
			y: coords[2],
			z: coords[0],
			filter: filters.join(' and '),
			start: start.toISOString(),
			end: end.toISOString(),
			size: TILE_SIZE,
			buffer: 256,
			// grid: 0.1,
		};
		const url = `/api/hp-mvt?${serialize(query)}`;

		// console.log('tileUrlFunction', url);
		return url;
	};

	bufferSetup = (gl, program) => {
		const aPosition = gl.getAttribLocation(program, 'a_position');
		gl.vertexAttribPointer(
			aPosition,
			2,                                      // number of elements per attribute
			gl.FLOAT,                               // type of elements
			false,                                  // normalize
			4 * Float32Array.BYTES_PER_ELEMENT,     // size of an element in bytes
			0                                       // offset to this attribute
		);
		gl.enableVertexAttribArray(aPosition);

		const aDif = gl.getAttribLocation(program, 'a_dif');
		gl.vertexAttribPointer(
			aDif,
			1,                                      // number of elements per attribute
			gl.FLOAT,                               // type of elements
			false,                                  // normalize
			4 * Float32Array.BYTES_PER_ELEMENT,     // size of an element in bytes
			2 * Float32Array.BYTES_PER_ELEMENT      // offset to this attribute
		);
		gl.enableVertexAttribArray(aDif);
	};

	pointSize = (viewState) => {
		const { resolution } = viewState;
		return this.calcPointsSize(resolution);
	};

	calcPointsSize = (resolution) => {
		const meters = this.dataSource.id === 1 ? 1000 : 375;
		const pixels = meters * 2 / resolution;
		return Math.max(pixels, 7);
	};

	setVisible = () => {
		this.layer.setVisible(this.mainStore.layersSettings.heatPoints.show);
	};

	createTexture = (colors) => {
		const size = TEXTURE_SIZE;

		const canvas = document.createElement('canvas', {
			antialias: false,
			premultipliedAlpha: false,
		});
		canvas.width = size * colors.length;
		canvas.height = size;
		const ctx = canvas.getContext('2d');
		ctx.imageSmoothingEnabled = false;

		for (let i = 0; i < colors.length; i++) {
			const color = colors[i];

			ctx.globalAlpha = 1;
			ctx.fillStyle = color;
			ctx.beginPath();
			ctx.arc(size / 2 + i * size, size / 2, size / 2 - 1, 0, 2 * Math.PI);
			ctx.fill();

			// ctx.globalAlpha = 0.1;
			// ctx.fillStyle = '#fff';
			// ctx.beginPath();
			// ctx.arc(size / 2 + i * size, size / 2, size / 2 - size / 4, 0, 2 * Math.PI);
			// ctx.fill();
		}

		// canvas.style.width = canvas.width + 'px';
		// canvas.style.height = canvas.height + 'px';
		// canvas.style.position = 'absolute';
		// canvas.style.left = '100px';
		// canvas.style.top = '100px';
		// canvas.style.zIndex = 5000;
		// document.body.appendChild(canvas);

		return canvas;
	};

	switchSource = (source) => {
		this.dataSource = source;
		this.mainStore.popup = null;
		this.update();
	};

	getPointAtPixel = async e => {
		if (!this.overHeatPoint) return null;

		const coords = e.map.getCoordinateFromPixel(e.pixel);
		const [ lon, lat ] = transform(coords, 'EPSG:3857', 'EPSG:4326');

		try {
			const heatPoint = await store.model.FireData.getClosestPoint({
				start: this.mainStore.heatPointsStartDate,
				end: this.mainStore.heatPointsEndDate,
				dataSourceId: this.dataSource ? this.dataSource.id : 3,
				regionId: store.local.smartDistrict.hpRegion || undefined,
				lon,
				lat,
			});
			console.log('heatPoint', heatPoint);
			const coords = fromLonLat(heatPoint.geo.coordinates);
			const geometry = new Point(coords);
			return new Feature({
				record: heatPoint,
				geometry,
			});
		} catch (e) {
			console.warn(e);
		}

		return null;
	};

	onMouseMove = (e, pixel) => {
		if (pixel[3] > 0) {
			this.mapStore.overOtherFeatures.heatPoint = true;
			// this.mapStore.element.classList.add('pointer');
			this.overHeatPoint = true;
		}
		else {
			delete this.mapStore.overOtherFeatures.heatPoint;
			this.overHeatPoint = false;
			// не убираем класс "pointer", об этом позаботится mapStore
			// this.mapStore.element.classList.remove('pointer');
		}
	};

};
