import { observable } from 'mobx';
import ImageStatic from 'ol/source/ImageStatic';
import { get as getProjection } from 'ol/proj';

import { WebglImageLayer } from '@smartplatform/map/client';
import mainStore from '../../mainStore';
import t from 'i18n';
import * as colors from './colors';

const extent = [-20037508.342789244, -20037508.342789244, 20037508.342789244, 20037508.342789244];

class GfsLayer {

	@observable isLoading = false;
	@observable error = false;

	source = null;
	layer = null;
	level = null;

	constructor(map, params = {}) {
		const {
			code,
			level = 'surface',
			opacity = 0.8,
			min = -60,
			max = 60,
			colors,
			alphaValue,
			alphaThreshold,
			onLoad,
		} = params;

		this.code = code;
		this.level = level;
		this.min = min;
		this.max = max;

		this.source = this.makeSource();

		this.layer = new WebglImageLayer({
			source: this.source,
			colors,
			alphaValue,
			alphaThreshold,
			zIndex: 1,
			opacity,
			extent,
			onLoad,
		});
	}

	getLayer = () => this.layer;

	update = () => {
		this.source = this.makeSource();
		// this.source.on('imageloadend', mainStore.onWeatherImageLoad)
		this.layer.setSource(this.source);
		this.layer.refreshSource();
	};

	makeSource = () => {
		this.isLoading = true;
		this.error = false;

		// const date = format(new Date(mainStore.date), 'yyyy-MM-dd');
		const date = new Date(mainStore.date);
		const hour = ((date.getHours() / 3) | 0) * 3;
		date.setHours(hour);

		const url = `/api/gfs?date=${date.toISOString()}&degree=0.25&layer=${this.code}&level=${this.level}&min=${this.min}&max=${this.max}&format=jpg`;

		const projection = getProjection('EPSG:4326');
		// const projection = getProjection('EPSG:3857');
		const imageExtent = projection.getExtent();

		const source = new ImageStatic({
			url,
			projection,
			imageExtent,
		});

		source.on('imageloadend', () => this.isLoading = false);
		source.on('imageloaderror', this.onError);

		return source;
	};

	onError = () => {
		console.log('onError');
		this.isLoading = false;
		this.error = true;
	};

}

export default {
	temp: {
		name: t('weather.temp'),
		type: 'gfs',
		description: t('weather.tempDescription'),
		colors: colors.temperatureColors,
		code: 'TMP',
		level: 'surface',
		min: -60,
		max: 60,
		opacity: 0.7,
		makeLayer: (map, params) => new GfsLayer(map, params),
		convert: value => Math.round(value),
		measure: t('weather.tempMeasure'),
	},
	pressure: {
		name: t('weather.pressure'),
		type: 'gfs',
		description: t('weather.pressureDescription'),
		colors: colors.pressureColors,
		code: 'PRES',
		level: 'surface',
		min: 49475.48,
		max: 104264.281,
		opacity: 0.7,
		makeLayer: (map, params) => new GfsLayer(map, params),
		convert: value => Math.round(value / 133.322),
		measure: t('weather.pressureMeasure'),
	},
	precipitation: {
		name: t('weather.precipitation'),
		type: 'gfs',
		description: t('weather.precipitationDescription'),
		colors: colors.precipitationColors,
		code: 'APCP',
		level: 'surface',
		min: 0,
		max: 60.062,
		opacity: 0.7,
		makeLayer: (map, params) => new GfsLayer(map, params),
		convert: value => value.toFixed(1),
		measure: t('weather.precipitationMeasure'),
	},
	humidity: {
		name: t('weather.humidity'),
		type: 'gfs',
		description: t('weather.humidityDescription'),
		colors: colors.humidityColors,
		code: 'RH',
		level: '2 m above ground',
		min: 5,
		max: 100,
		opacity: 0.7,
		makeLayer: (map, params) => new GfsLayer(map, params),
		convert: value => Math.round(value),
		measure: t('weather.humidityMeasure'),
	},
	ozone: {
		name: t('weather.ozone'),
		type: 'gfs',
		description: t('weather.ozoneDescription'),
		colors: colors.ozoneColors,
		code: 'TOZNE',
		level: 'entire atmosphere (considered as a single layer)',
		min: 94.765,
		max: 445.365,
		opacity: 0.7,
		makeLayer: (map, params) => new GfsLayer(map, params),
		convert: value => Math.round(value),
		measure: t('weather.ozoneMeasure'),
	},
};
