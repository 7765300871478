import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { Loader } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';

@observer
export default class CountyPopup extends React.Component {

	@observable fire = null;
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.isLoading = false;
	};

	render() {
		const { county } = this.props;

		return <div className="county-popup">
			{/*<div className="popup-header">{county.name}</div>*/}
			{county.name}
		</div>;
	}

}
