import React from 'react';
import Root from './Root';
import t from 'i18n';
import store from 'client/store';
import FireIcon from '!!svg-react-loader!img/icons/fire.svg';
import MapIcon from '!!svg-react-loader!img/icons/map.svg';
import DictIcon from '!!svg-react-loader!img/icons/dict.svg';
import DashboardIcon from '!!svg-react-loader!img/icons/dashboard.svg';

export const path = '/smartdistrict';

export default {
	name: t('smartDistrict.title'),
	code: 'smartDistrict',
	icon: <FireIcon />,
	path,
	component: Root,
	access: () => true,//store.model.Fire && store.model.Fire.INFO.READ,
	menu: [
		// { title: t('dashboard.title'), path, icon: <DashboardIcon />, exact: true },
		{ title: t('smartDistrict.map'), path: `${path}`, icon: <MapIcon />, exact: true },
		{ title: t('dictionaries.plural'), path: `${path}/dictionaries`, icon: <DictIcon /> },
	],
}
