import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link, matchPath } from 'react-router-dom';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

import store from 'client/store';
import './style.scss';

@observer
export default class ModuleMenu extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		if (!store.module) return null;

		return <div className="module-menu">
			{store.module.menu.map(item => {
				const match = matchPath(store.route.path, { path: item.path, exact: item.exact, strict: false });
				// console.log('>', item.path, store.route.path, match);
				const isActive = !!match;
				const className = classNames('sidebar-btn', {
					active: isActive,
				});
				return <Link key={item.title} to={item.path} className={className}>
					<div className="icon">{item.icon || <FontAwesomeIcon icon={faCircle} />}</div>
					<div className="title">{item.title}</div>
				</Link>;
			})}
		</div>;
	}

}
