import React from 'react';
import { observable, toJS } from 'mobx';
import { observer } from 'mobx-react';

import { Form, Field, Select, Row, ColorPicker, NumberInput } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

@observer
export default class LayerConfig extends React.Component {

	@observable record = null;
	@observable existingLayers = [];
	@observable availableLayers = [];
	
	constructor(props) {
		super(props);
		this.allLayers = Object.keys(store.model).filter(modelName => /^Layer/.test(modelName)).map(modelName => store.model[modelName]);
		this.init();
	}
	
	async init() {
		const id = parseInt(this.props.match.params.id);
		this.existingLayers = await store.model.LayerConfig.find();
		this.availableLayers = this.allLayers.filter(r => !this.existingLayers.includes(r));
		console.log('id', id);
		// console.log('availableLayers', toJS(this.availableLayers.map(r => r.INFO.name)));
		if (id) {
			this.record = await store.model.LayerConfig.findById(id);
		}
		else {
			this.record = new store.model.LayerConfig();
		}
		console.log('record', id, this.record);
	}
	
	onModelChange(modelName) {
	
	}
	
    render() {
		if (!this.record) return '...';
		
		return <div className="layer-config-edit">
	        <h1>{t(`${this.record.modelName}`)}</h1>
			<Form record={this.record} returnTo={`/smartdistrict/dictionaries/LayerConfig`}>
				<Field property="modelName" label={t('layer')}>
					<Select
						value={this.record.modelName}
						items={this.availableLayers.map(r => r.INFO.name)}
						onChange={this.onModelChange}
						// disabled={!!this.record.id}
						disabled
					/>
				</Field>
				<Field property="fill" label={t('LayerConfig.fill')}>
					<ColorPicker alpha showResetBtn />
				</Field>
				<Row>
					<Field property="stroke" label={t('LayerConfig.stroke')}>
						<ColorPicker alpha showResetBtn />
					</Field>
					<Field property="width" label={t('LayerConfig.width')}>
						<NumberInput style={{ width: 100 }} />
					</Field>
				</Row>
			</Form>
        </div>;
    }
}
