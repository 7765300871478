import React from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';

import { NumberValue } from '@smartplatform/ui';
import t from 'i18n';

const LOADING = <>&nbsp;</>;

@inject('dashboardStore') @observer
export default class Season extends React.Component {

	render() {
		const { dashboardStore } = this.props;
		const { data } = dashboardStore || {};
		const { seasonCountCurrent, seasonAreaCurrent } = data.season || {};
		const { seasonCountLastYear, seasonAreaLastYear } = data.prevSeason || {};

		return <div className="season block">
			<h2>{t('dashboard.seasonFires')}</h2>

			<table className="dashboard-table">
				<thead>
					<tr>
						<th />
						<th width="33%">{t('dashboard.currentYear')}</th>
						<th width="33%">{t('dashboard.lastYear')}</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td className="category">{t('dashboard.seasonTotalCount')}</td>
						<td className="number">
							{seasonCountCurrent !== undefined ? <NumberValue value={seasonCountCurrent} /> : LOADING}
						</td>
						<td className="number">
							{seasonCountLastYear !== undefined ? <NumberValue value={seasonCountLastYear} /> : LOADING}
						</td>
					</tr>
					<tr>
						<td className="category">{t('dashboard.seasonTotalArea')}</td>
						<td className="number">
							{seasonAreaCurrent !== undefined ? <NumberValue value={seasonAreaCurrent} fraction={1} /> : LOADING}
						</td>
						<td className="number">
							{seasonAreaLastYear !== undefined ? <NumberValue value={seasonAreaLastYear} fraction={1} /> : LOADING}
						</td>
					</tr>
				</tbody>
			</table>

		</div>;
	}

}
