import ReactDOM from 'react-dom';
import { action, observable, toJS } from 'mobx';
import { startOfDay, endOfDay, addDays, subDays } from 'date-fns';
import Feature from 'ol/Feature';
import { transform, toLonLat, fromLonLat, get as getProj } from 'ol/proj';

import { geoJSON, getFeatureCenter } from 'components';
import { heatPointsLayerCustomMVT, HeatPointsLayerCustomWebgl } from './map/heat-points';
import CountiesLayer from './map/counties';
import WeatherLayer from './map/weather';
import ModelsLayer from './map/models';
import WindLayer from './map/wind';
import GenShtabLayer from './map/gen-shtab';
import store from 'client/store';
import initLocal from './localStore';
import { WeatherTip } from './map/weather';

export const PARAMS = {
	// zoom: 5,
	zoom: 14,
	minZoom: 1,
	maxZoom: 20,
	center: [ 128.74929854276843, 63.27207348590292 ], // Якутск
	// center: [ 112.44867556385057, 68.5027600231621 ], // Оленек
};

class MainStore {

	@observable map = null;
	@observable isInitialized = false;
	@observable date;
	@observable heatPointsStartDate;
	@observable heatPointsEndDate;
	@observable popup = null;
	@observable weatherImage = null;
	@observable mapInitialized = false;

	minBrightness = 200;
	maxBrightness = 510;
	canShowPopup = true;
	featuresGetters = [];

	countiesLayer;
	weatherLayer;
	heatPointsLayer;
	windLayer;

	init = async (options) => {
		initLocal();

		try {
			const data = await store.model.FireData.getData();
			// console.log('[fires]initialData', data);
			this.minBrightness = data.minBrightness;
			this.maxBrightness = data.maxBrightness;
		}
		catch (e) {
			console.warn(e);
		}
		this.brightnessRange = this.maxBrightness - this.minBrightness;

		this.date = store.local.smartDistrict.startDate ? new Date(store.local.smartDistrict.startDate) : new Date();
		const hour = ((this.date.getHours() / 3) | 0) * 3;
		this.date.setHours(hour);
		this.date.setMinutes(0);
		this.date.setSeconds(0);
		this.date.setMilliseconds(0);

		// this.heatPointsEndDate = endOfDay(new Date(2021, 7, 25));
		this.heatPointsEndDate = endOfDay(new Date());
		this.heatPointsStartDate = startOfDay(subDays(this.heatPointsEndDate, 2));
		if (store.local.smartDistrict.startDate) {
			this.heatPointsEndDate = endOfDay(new Date(store.local.smartDistrict.startDate));
			this.heatPointsStartDate = startOfDay(subDays(new Date(store.local.smartDistrict.startDate), 6));
		}

		this.zoom = PARAMS.zoom;

		/** Слои, каждый со своим стором, попапоми и т.п. */

		this.layersSettings = store.local.smartDistrict.layers;

		this.countiesLayer = new CountiesLayer(this);
		// this.countriesLayer = new CountriesLayer(this);
		// this.countriesLayer = new CountriesLayer(this);
		// this.dzzLayer = new DzzLayer(this);
		this.genShtabLayer = new GenShtabLayer(this);
		this.weatherLayer = new WeatherLayer(this);
		this.windLayer = new WindLayer(this);
		this.modelsLayer = new ModelsLayer(this);

		this.createHeatPointsLayer();

		this.isInitialized = true;
	};

	onMapInit = async mapStore => {
		console.log('onMapInit', mapStore)
		this.mapStore = mapStore;
		this.map = mapStore.map;

		this.map.on('click', this.onClick);
		this.map.on('pointermove', this.onPointerMove);

		this.heatPointsLayer.init(mapStore);
		this.weatherLayer.init(mapStore);
		this.weatherTip = new WeatherTip(this.map);

		if (!store.isPublic) {
			this.countiesLayer.init(mapStore);
			// this.countriesLayer.init(mapStore);
			// this.dzzLayer.init(mapStore);
			this.genShtabLayer.init(mapStore);
		}
		this.modelsLayer.init(mapStore);
		this.windLayer.init(mapStore);

		mapStore.on('popupclose', this.onPopupClose);
		mapStore.on('cleanup', this.destroy);
		mapStore.on('mouseleave', this.onMouseLeave);
		this.mapInitialized = true;
	}

	onTileSourceChange = layerCode => {
		console.log('onTileSourceChange', layerCode);
		store.local.smartDistrict.tileSource = layerCode;
		store.local.save();
	};

	changeDate = date => {
		// this.date = startOfDay(date);
		date.setMinutes(0);
		date.setSeconds(0);
		date.setMilliseconds(0);
		this.weatherImage = null;
		this.date = date;
		this.popup = null;
		this.weatherLayer.update();
		this.windLayer.update();
		this.modelsLayer.load();
	};

	onScaleMount = el => {
		this.scaleElement = el;
	};

	setHeatPointsRegion = (value) => {
		store.local.smartDistrict.hpRegion = value ? null : 1;
		store.local.save();
		this.heatPointsLayer.update();
	};

	setHeatPointsVersion = (useMvt) => {
		store.local.smartDistrict.layers.heatPoints.useMVT = useMvt;
		store.local.save();
		this.heatPointsLayer.remove();
		this.createHeatPointsLayer();
		this.heatPointsLayer.init(this.mapStore);
		this.heatPointsLayer.update();
	};

	createHeatPointsLayer = () => {
		this.heatPointsLayer = new heatPointsLayerCustomMVT(this);
	};

	setWeatherLayer = (weatherLayerConfig) => {
		this.weatherLayerConfig = weatherLayerConfig;
		const { colors, min, max, measure, convert } = weatherLayerConfig;
		if (colors && this.scaleElement) {
			this.scaleElement.innerHTML = '';
			this.scaleElement.appendChild(this.getColorRamp(colors));
			if (min !== undefined && max !== undefined) {
				const minDiv = document.createElement('div');
				minDiv.className = 'min-value';
				minDiv.innerHTML = Math.floor(convert ? convert(min) : min) + (measure ? ' ' + measure : '');
				this.scaleElement.appendChild(minDiv);

				const maxDiv = document.createElement('div');
				maxDiv.className = 'max-value';
				maxDiv.innerHTML = Math.floor(convert ? convert(max) : max) + (measure ? ' ' + measure : '');
				this.scaleElement.appendChild(maxDiv);
			}
		}
	};

	@action prevDate = async (e) => {
		e.stopPropagation();
		const date = subDays(this.date, 1);
		this.changeDate(date);
	};

	@action nextDate = async (e) => {
		e.stopPropagation();
		const date = addDays(this.date, 1);
		this.changeDate(date);
	};

	toggleWeatherLayer = (value) => {
		const weather = store.local.smartDistrict.layers.weather;
		weather.show = value;
		store.local.save();
		this.weatherLayer.setVisible();
		if (value) this.weatherLayer.update();
	};

	toggleCategory = (value) => {
		const openCategories = store.local.smartDistrict.layers.categories.open;
		//delete - if exist, add - if not exist
		if (openCategories.includes(value)) {
			openCategories.splice(openCategories.indexOf(value), 1);
		} else {
			openCategories.push(value);
		}
		store.local.save();
	};

	addFeatureGetter = (func) => {
		this.featuresGetters.push(func);
	};

	getFeaturesAtPixel = async (e) => {
		const features = await this.map.getFeaturesAtPixel(e.pixel);
		const otherFeatures = [];
		for (let getter of this.featuresGetters) {
			const feature = await getter(e);
			if (feature) otherFeatures.push(feature);
		}
		return [...features, ...otherFeatures];
	};

	onClick = async e => {
		if (e.originalEvent.ctrlKey) return;
		const coords = this.map.getCoordinateFromPixel(e.pixel);
		const [ lon, lat ] = transform(coords, 'EPSG:3857', 'EPSG:4326');

		const features = await this.getFeaturesAtPixel(e);

		if (features.length === 0) {
			this.popup = null;
			return;
		}

		let popup = { lonLat: [lon, lat], offset: 0, records: [] };
		let initial = true;
		let coordsSet = false;
		const sameModels = {};
		console.log('features', features);
		for (let feature of features) {
			const data = feature.getProperties();
			if (data.record) {
				const modelName = data.record.MODEL.INFO.name;
				popup.records.push(data.record);
				// console.log('> record', data.record, popup.records.length);
				// if (!sameModels[modelName]) {
				// 	popup.records.push(data.record);
				// 	sameModels[modelName] = true;
				// }
				if (!coordsSet && data.record.geo.type === 'Point') {
					popup.lonLat = data.record.geo.coordinates;
					popup.offset = 15;
					coordsSet = true;
				}
				else if (!coordsSet && data.record.geo.type === 'MultiPoint' && data.record.geo.coordinates.length === 1) {
					popup.lonLat = data.record.geo.coordinates[0];
					popup.offset = 15;
					coordsSet = true;
				}
				if (initial) {
					// console.log('pin popup to', data.record);
					if (modelName === 'FireData') {
						popup.width = 300;
						popup.height = 220;
						popup.offset = 3;
					}
				}
				initial = false;
			}
			else if (feature.constructor.name) {
				if (data.layer === 'county' && features.length === 1) {
					this.popup = null;
					return;
				}
			}
		}

		this.popup = popup;
		console.log('popup', popup);
	};

	onWebglImageLoad = (image, colorRamp) => {
		// console.log('onWebglImageLoad (global)', image);
		this.weatherImage = image;
		this.colorRamp = colorRamp;
	};

	onMouseLeave = () => {
		if (this.weatherTip) this.weatherTip.hide();
	};

	onPointerMove = async (e) => {
		if (e.dragging || this.drawing) {
			if (this.weatherTip) this.weatherTip.hide();
			return;
		}

		const features = await this.map.getFeaturesAtPixel(e.pixel);

		if (store.local.smartDistrict.layers.weather.show) {
			if (this.weatherImage && this.weatherLayerConfig) {
				const coords = this.map.getCoordinateFromPixel([e.pixel[0], e.pixel[1] - 20]);
				this.weatherTip.update(coords, this.weatherImage, this.weatherLayerConfig, this.colorRamp);
			}
		}
		else {
			if (this.weatherTip) this.weatherTip.hide();
		}
	};

	getColorRamp = (colors) => {
		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d');
		
		const width = 300;
		
		canvas.width = width;
		canvas.height = 1;
		
		const gradient = ctx.createLinearGradient(0, 0, width, 20);
		
		if (Array.isArray(colors)) {
			for (let i = 0; i < colors.length; i++) {
				gradient.addColorStop(i / colors.length, colors[i]);
				ctx.fillStyle = gradient;
				ctx.fillRect(0, 0, width, 20);
			}
		}
		else {
			for (const breakpoint of colors.scale) {
				const [ value, pos, [ r, g, b, a ]] = breakpoint;
				gradient.addColorStop(pos, `rgba(${r},${g},${b},${a / 255})`);
			}
			ctx.fillStyle = gradient;
			ctx.fillRect(0, 0, width, 20);
		}

		ctx.fillStyle = gradient;
		ctx.fillRect(0, 0, 256, 1);

		return canvas;
	}

	onPopupClose = () => this.popup = null;

	destroy = () => {
		// деинициализация
		this.mapStore.off('cleanup', this.destroy);
		this.mapStore.off('popupclose', this.onPopupClose);
	};

	onZoomStart = () => {
		// console.log('onZoomStart');
	};

	onZoomEnd = () => {
		this.zoom = this.map.getZoom();
		// console.log('onZoomEnd', this.zoom);
	};

	log = (...args) => console.log('%c[fires]', 'color: #c80', ...args);

	switchFilters = (maximize) => {
		store.local.smartDistrict.filtersMaximized = maximize !== undefined ? maximize : !store.local.smartDistrict.filtersMaximized;
		store.local.save();
	};

}

export default new MainStore();
