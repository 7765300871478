import React from 'react';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Panel } from 'components';
import store from '../../store';
import { Users } from './users';
import { Roles } from './roles';
import RoleMappings from './role-mappings';
import Organizations from './organizations';
import Audit from './audit';
import './style.scss';

const MENU_WIDTH = 200;
export const MENU_MARGIN = MENU_WIDTH + 10;

@observer
export default class Admin extends React.Component {

	constructor(props) {
		super(props);
	}
	
	render() {
		if (!(store.model.RoleMapping && store.model.RoleMapping.INFO.WRITE)) return <Redirect to="/" />;

		return <>
			<Panel left={0} top={0} width={200} height={210} header="Администрирование" className="admin-menu">
				<Link to="/admin/users">Пользователи</Link>
				<Link to="/admin/roles">Роли</Link>
				<Link to="/admin/rolemappings">Назначения ролей</Link>
				<Link to="/admin/organizations">Организации</Link>
				<Link to="/admin/audit">Журнал</Link>
			</Panel>
			<Switch>
				<Route path="/admin" exact component={Users}/>
				<Route path="/admin/users" component={Users}/>
				<Route path="/admin/roles" component={Roles}/>
				<Route path="/admin/rolemappings" component={RoleMappings}/>
				<Route path="/admin/organizations" component={Organizations}/>
				<Route path="/admin/audit" component={Audit}/>
			</Switch>
		</>;
	}
	
}
