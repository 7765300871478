import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import Edit from './Edit';
import Content from '../Content';
import t from 'i18n';

export default class RoleMappings extends React.Component {
	
	render() {
		return <Content title={t('roleMapping.plural')}>
			<Switch>
				<Route path="/admin/rolemappings" exact component={List}/>
				<Route path="/admin/rolemappings/create" component={Edit}/>
				<Route path="/admin/rolemappings/:id" component={Edit}/>
			</Switch>
		</Content>;
	}
	
}
