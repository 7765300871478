import React from 'react';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faPlus, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import { Table, Column, Pager, Avatar, Button } from '@smartplatform/ui';
import { fio } from 'client/tools';
import store from '../../../store';
import t from '../../../../i18n';
import { format } from 'date-fns';

const PER_PAGE = 20;

@observer
export default class List extends React.Component {
	@observable isLoading = false;
	@observable records = null;
	@observable filter = {
		order: 'id asc',
		skip: 0,
		limit: PER_PAGE,
		where: {
			_search: '',
		},
		include: [
			{ relation: 'accessTokens', scope: { order: 'created desc', limit: 1, fields: ['created', 'id'] } },
			{ relation: 'organization', scope: { fields: ['name', 'id'] } },
		],
	};

	constructor(props) {
		super(props);
		this.store = props.store;
		this.init();
	}

	@action init = async () => {
		this.isLoading = true;
		this.records = await store.model.User.find(this.filter);
		this.isLoading = false;
	};

	@action onSearch = (value) => {
		this.filter.where._search = value;
		this.init();
	};

	create = () => {
		this.props.history.push(`/admin/users/create`);
	};

	edit = (record) => {
		this.props.history.push(`/admin/users/${record.id}`);
	};

	reload = (e) => {
		e.preventDefault();
		this.init();
	};

	onPageChange = async (page) => {
		this.page = page;
		this.filter.skip = (this.page - 1) * PER_PAGE;
		this.init();
	};

	onClose = () => store.route.push({ path: '/' });
	renderLastAccessTokenDate = (accessTokens) => {
		const tokenDate = accessTokens.length > 0 ? accessTokens[0].created : null;
		if (tokenDate) {
			return format(new Date(tokenDate), 'dd.MM.yyyy HH:mm');
		}
		return '';
	};

	get reportDownloadUrl() {
		const esc = encodeURIComponent;
		const path = '/api/Users/exportToExcel';
		const { limit, skip, ...filters } = this.filter;
		const cols = {
			id: t('ID'),
			username: t('user.username'),
			email: t('user.email'),
			lastName: t('user.lastName'),
			organization: {
				name: t('organization.title'),
			},
			emailVerified: t('user.emailVerified'),
			lastAuthDate: t('user.lastAuthDate'),
		};
		const params = { filters, cols, date: new Date() };
		const query = `?params=` + esc(JSON.stringify(params));
		return path + query;
	}

	render() {
		if (!this.records) return '...';

		return (
			<div className='block'>
				{/*<div className="block-header">Пользователи</div>*/}
				<div className='table-list-panel d-flex justify-content-between'>
					<div>
						<input type='text' value={this.search} onChange={(e) => this.onSearch(e.target.value)} placeholder='Поиск' />
						&nbsp;
						<Button onClick={this.create} className='btn-primary' icon={<FontAwesomeIcon icon={faPlus} />}>
							{t('create')}
						</Button>
						&nbsp;
						<Link to='#' onClick={(e) => this.reload(e)} className='btn-icon btn-link-type'>
							{<FontAwesomeIcon icon={faSyncAlt} spin={this.isLoading} />}
						</Link>
					</div>
					<div>
						<a href={this.reportDownloadUrl} rel='noopener noreferrer' download>
							<Button variant='primary'>
								<FontAwesomeIcon icon={faFileExcel} />
								{t('downloadExcel')}
							</Button>
						</a>
					</div>
				</div>

				<div className='table-list-pager'>
					<Pager itemsPerPage={PER_PAGE} totalCount={this.records.totalCount} current={this.page} onChange={this.onPageChange} />
				</div>

				<Table className='table-list-table' rows={this.records} onRowClick={this.edit}>
					{/*<Column label={t('User.avatar')} width={30}>{user => <Avatar user={user} size={30} />}</Column>*/}
					<Column width={60} property='id' label={t('ID')} />
					<Column property='username' label={t('user.username')} />
					<Column property='email' label={t('user.email')} />
					<Column computed={fio} property='lastName' label={t('user.fullName')} />
					<Column relation='organization' property='name' label={t('organization.title')} />
					<Column property='emailVerified' label={t('user.emailVerified')} />
					<Column relation='accessTokens' computed={this.renderLastAccessTokenDate} label={t('user.lastAuthDate')} />
				</Table>
			</div>
		);
	}
}
