import t from 'i18n';
import LayerConfig from './layer-config/LayerConfig';
import LayerCategory from './layer-config/LayerCategory';
import MapLayer from './layer-config/MapLayer';
import { closedLayers, publicLayers } from '../layers';

export default [
	{
		title: t('dictionaries.group.settings'),
		models: [
			{ name: 'LayerConfig', edit: LayerConfig },
			{ name: 'LayerCategory', edit: LayerCategory },
			{ name: 'MapLayer', edit: MapLayer },
		],
	},
	{
		title: t('dictionaries.group.closedLayers'),
		models: closedLayers,
	},
	{
		title: t('dictionaries.group.publicLayers'),
		models: publicLayers,
	},
];
