import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { formatDate, fioShort } from 'client/tools';
import store from 'client/store';
import t from 'i18n';

const HIDDEN_FIELDS = {
	'User': [ 'password', 'verificationToken' ],
	'AccessToken': [ 'id' ],
};

@withRouter @observer
export default class Single extends React.Component {

	@observable record = null;

	constructor(props) {
		super(props);
		this.init();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.id !== this.props.match.params.id) this.init();
	}

	init = async () => {
		this.id = this.props.match.params.id;
		if (this.id) {
			this.record = await store.model.Audit.findById(this.id);
		}
	};

	render() {
		if (!this.props.match.params.id) return <div className="audit-single">{t('audit.select')}</div>;
		if (!this.record) return <div className="audit-single">{t('audit.select')}</div>;

		const record = this.record;

		return <div className="audit-single">
			<div className="date">
				<label>{t('audit.date')}</label>
				{formatDate(record.createdAt, 'dd.MM.yyyy HH:mm:ss')}
			</div>
			<div className="model">
				<label>{t('audit.model')}</label>
				{record.model}
			</div>
			<div className={'action ' + record.action}>
				<label>{t('audit.action')}</label>
				{t(`audit.${record.action}`)}
			</div>
			<div className="owner">
				<label>{t('audit.owner')}</label>
				{record.owner ? fioShort(record.owner) : '-'}
			</div>
			{(record.instance && typeof record.instance === 'object') ?
				<div className="instance">
					{/*<label>{t('audit.data')}</label>*/}
					<div className="data">
						{Object.keys(record.instance)
							.map(key => {
								let value = record.instance[key];
								if (typeof value === 'string') {
									if (value === '') {
										value = '-';
									}
								}
								else {
									value = JSON.stringify(value);
								}
								if (HIDDEN_FIELDS[record.model] && HIDDEN_FIELDS[record.model].includes(key)) {
									value = <span className="hidden">{t('audit.hidden')}</span>;
								}
								return <div key={key} className="property">
									<label>{key}</label>
									<div className="value">{value}</div>
								</div>;
							})
						}
					</div>
				</div>
				:
				null
			}
		</div>;
	}

}
