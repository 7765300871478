import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { addDays } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { Button, DatePicker, SlideInput } from '@smartplatform/ui';
import { Panel } from 'components';
import { formatDate } from 'client/tools';
import './style.scss';

@observer
export default class Timeline extends React.Component {

	@observable hour = 0;

	constructor(props) {
		super(props);
		const date = new Date(this.props.mainStore.date);
		this.hour = date.getHours();
	}

	onMount = el => {
		if (el) {
			el.addEventListener('mouseenter', this.onMouseEnter);
		}
	};

	onMouseEnter = () => {
		if (this.props.mainStore.weatherTip) this.props.mainStore.weatherTip.hide();
	};

	onTimeSelect = value => {
		// console.log('onTimeSelect', value);
		let date = new Date(this.props.mainStore.date);
		if (value === 24) {
			this.hour = 0;
			date = addDays(date, 1);
			date.setHours(0);
		}
		else {
			this.hour = value;
			date.setHours(value);
		}
		this.props.mainStore.changeDate(date);
	};

	render() {
		const { mainStore } = this.props;

		return <div className="fires-timeline" ref={this.onMount}>
			<DatePicker value={mainStore.date} onChange={mainStore.changeDate} className="timeline-datepicker" offset={10}>
				<span className="day-shift" onClick={mainStore.prevDate}><FontAwesomeIcon icon={faArrowLeft} /></span>
				{formatDate(mainStore.date, 'dd.MM.yyyy HH:mm')}
				<span className="day-shift" onClick={mainStore.nextDate}><FontAwesomeIcon icon={faArrowRight} /></span>
			</DatePicker>
			<SlideInput
				value={this.hour}
				min={0}
				max={23}
				step={1}
				className="timeline-timeselect"
				onChange={this.onTimeSelect}
				showLabels
			/>
		</div>;
	}

}
