import { observable, runInAction, action } from 'mobx';

const MOUSE_THRESHOLD = 3;

class DndStore {
	
	startPos = null;
	currentPos = null;
	startDragPos = null;
	defaultListColor = '#bbb';
	
	list = null;
	item = null;
	
	@observable useAnimation = false;
	
	@observable project = null;
	@observable dragging = false;
	@observable targetList = null;
	@observable addingToList = null;
	@observable error = null;

	init = props => {
		Object.keys(props).forEach(key => this[key] = props[key]);
		// console.log('props', props);
		this.project.lists.forEach(list => {
			list.items.forEach(this.processItem);
		});
	};
	
	processItem = item => {
		item.isDragged = observable.box(false);
		item.isDropped = observable.box(false);
		item.isLoading = observable.box(false);
	};

	getItem = element => {
		if (element && element.classList.contains('kb-item')) {
			const id = parseInt(element.dataset.id);
			if (id) {
				const listNode = element.parentNode.parentNode;
				const listId = listNode ? parseInt(listNode.dataset.listid) : null;
				if (listId) {
					const list = this.project.lists.find(list => list.id === listId)
					if (list) {
						const index = list.items.findIndex(item => item.id === id);
						if (index !== -1) {
							const item = list.items[index];
							if (!item.__kb) item.__kb = {};
							item.__kb.element = element;
							// item.__kb.rect = element.getBoundingClientRect();
							return item ? { item, index, list } : null;
						}
					}
				}
			}
		}
		return null;
	}
	
	getList = element => {
		let list = null;
		if (element.classList.contains('kb-items')) {
			const id = parseInt(element.parentNode.dataset.listid);
			// console.log('kb-list', id);
			if (id) {
				list = this.project.lists.find(l => l.id === id);
			}
		}
		return list || null;
	}
	
	getListById = id => {
		const list = this.project.lists.find(l => l.id === id);
		return list || null;
	}
	
	onMouseDown = e => {
		this.error = null;
		if (e.button !== 0) return;
		// const evented = e.target.closest('.evented');
		// if (evented) return;
		const itemElement = e.target.closest('.kb-item');
		if (itemElement) {
			this.startPos = [e.clientX, e.clientY];
			const itemData = this.getItem(itemElement);
			if (itemData) {
				// console.log('itemData', itemData);
				this.item = itemData.item;
				this.list = itemData.list
				this.srcList = itemData.list;
				this.srcIndex = itemData.index;
			}
		}
	};
	
	@action onMouseUp = async e => {
		this.error = null;
		if (e.button !== 0) return;
		this.startPos = null;
		const itemData = this.getItem(e.target);
		const item = itemData ? itemData.item : null;
		if (this.item) {
			if (this.dragging) {
				this.itemAction(this.item, this.list, this.srcList, this.srcIndex);
			}
			else if (item === this.item) {
				this.gotoItem && this.gotoItem(item.path);
			}
		}
		runInAction(() => {
			this.dragging = false;
			this.item = null;
			this.list = null;
			this.srcList = null;
			this.srcindex = null;
		});
		// console.log('onMouseUp', e.target, this.item);
	};
	
	onMouseMove = e => {
		// console.log('onMouseMove', this.item, this.dragging);
		this.currentPos = [e.clientX, e.clientY];
		if (this.item && this.startPos) {
			if (!this.dragging) {
				const dx = e.clientX - this.startPos[0];
				const dy = e.clientY - this.startPos[1];
				const dist = Math.sqrt(dx * dx + dy * dy);
				if (dist > MOUSE_THRESHOLD) {
					const rect = this.item.__kb.element.getBoundingClientRect();
					const x = e.clientX - rect.left;
					const y = e.clientY - rect.top;
					this.startDragPos = [rect.left, rect.top + 5];
					this.item.__kb.start = [x, y];
					this.dragging = true; // пропускаем else, т.к. должен выполниться рендер draggable
					this.item.isDragged.set(true);
					this.targetList = this.list;
				}
			}
			else if (this.draggable) {
				const x = e.clientX - this.startDragPos[0] - this.item.__kb.start[0] + 'px';
				const y = e.clientY - this.startDragPos[1] - this.item.__kb.start[1] + 5 + 'px';
				this.draggable.style.transform = `translate3d(${x}, ${y}, 0)`;
				
				const itemIndex = this.list.items.findIndex(item => item.id === this.item.id);
				const itemData = this.getItem(e.target);
				const hoveredItem = itemData ? itemData.item : null;
				const targetItem = hoveredItem && hoveredItem.id !== this.item.id ? hoveredItem : null;
				const targetList = this.getList(e.target) || (hoveredItem ? this.getListById(hoveredItem.listId) : null);

				if (targetList) {
					let doNothing = !targetItem && this.list === targetList;
					if (doNothing) return;
					
					let insertIndex = 0;
					if (targetItem) {
						const targetRect = targetItem.__kb.element.getBoundingClientRect();
						const y = e.clientY - targetRect.top;
						const halfHeight = targetRect.height / 2;
						const upper = y < halfHeight;
						
						const targetItemIndex = targetList.items.findIndex(item => item.id === targetItem.id);
						insertIndex = targetItemIndex;
						
						if (targetList === this.list) {
							doNothing = itemIndex < insertIndex ? upper : !upper;
						}
						else if (!upper) {
							insertIndex++;
						}
					}
					else if (targetList !== this.list) {
						insertIndex = this.list.items.length;
					}
					
					if (!doNothing) {
						// console.log('update!', insertIndex);
						runInAction(() => {
							this.item.listId = targetList.id;
							this.list.items.splice(itemIndex, 1);
							
							// if (!this.list.allLoaded && this.list.items.length < this.itemsLimit) {
							// 	this.loadMoreToList(this.list);
							// }
							
							targetList.items.splice(insertIndex, 0, this.item);
							if (targetList !== this.list) {
								this.list.totalCount--;
								targetList.totalCount++;
								this.list = targetList;
								this.targetList = targetList;
							}
							
						});
					}
				}
			}
		}
	};
	
	loadMoreToList = async list => {
		const items = await this.loadMore(list);
		this.addItemsToList(items, list);
	};
	
	itemAction = async (item, list, srcList, srcIndex) => {
		this.dragging = false;
		item.isDragged.set(false);
		const index = list.items.findIndex(_item => _item.id === item.id);
		const changed = item.listId !== srcList.id || index !== srcIndex;
		
		const el = item.__kb.element;
		requestAnimationFrame(() => {
			el.style.transition = `all 100ms ease-in`;
			el.style.transform = `scale(0.95, 0.95)`;
			setTimeout(() => {
				el.style.transition = `all 200ms ease-out`;
				el.style.transform = `none`;
			}, 100);
		});

		if (changed) {
			console.log('changed', changed, srcList.id, '>', item.listId, srcIndex, '>', index);
			if (this.onChange) {
				item.isLoading.set(true);
				
				try {
					const prev = index > 0 ? list.items[index - 1] : null;
					const next = index < list.items.length - 1 ? list.items[index + 1] : null;
					const _item = await this.onChange({
						item: item.data,
						prev: prev ? prev.data : null,
						next: next ? next.data : null,
						list: list.data,
						index,
					});
					item.data = _item.data;
				}
				catch (e) {
					console.error('Error moving item!', e.message);
					
					const index = list.items.findIndex(_item => _item.id === item.id);
					list.items.splice(index, 1);
					
					item.listId = srcList.id;
					srcList.items.splice(srcIndex, 0, item);
					
					this.error = e.message;
					if (this.onError) this.onError(e);
				}
				
				item.isLoading.set(false);
			}
		}
	};
	
	addItem = async (text, list) => {
		console.log('addItem', list);
		const next = list.items.length > 0 ? list.items[0] : null;
		try {
			const item = await this.onChange({
				item: null,
				text,
				prev: null,
				next: next ? next.data : null,
				list: list.data,
				index: 0,
			});
			this.processItem(item);
			list.items.splice(0, 0, item);
		}
		catch(e) {
			this.error = e.message;
			console.error(e);
		}
		this.addingToList = null;
	};
	
	addItemsToList = (items, list) => {
		// console.log('addItemsToList', items, list);
		if (items.length === 0) {
			list.allLoaded = true;
		}
		else {
			items.forEach(this.processItem);
			list.items = [...list.items, ...items];
			if (list.items.length === list.totalCount) list.allLoaded = true;
		}
	};
	
}

export default new DndStore();
