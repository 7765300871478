export default {
	translation: {
		accept: 'Accept',
		active: 'Active',
		cleanFilters: 'Clear filters',
		week: 'Week',
		quarter: 'Quarter',
		year: 'Year',
		title: 'SmartDistrict',
		name: 'Name',
		noName: 'No name',
		shortName: 'Short name',
		LOGIN_FAILED: 'Wrong login or password',
		LOGIN_WAIT: 'Exceeded the limit of login attempts',
		LOGIN_EMPTY_CAPTCHA: 'Enter the code',
		LOGIN_INVALID_CAPTCHA: 'Wrong code',
		AUTHORIZATION_REQUIRED: 'Authorization is required',
		description: 'Description',
		create: 'Create',
		save: 'Save',
		longitude: 'Longitude',
		latitude: 'Latitude',
		lonShort: 'lon',
		latShort: 'lat',
		coordinates: 'Coordinates',
		azimuth: 'Azimuth',
		distance: 'Distance',
		metersPerSecond: 'm/s',
		loading: 'Loading...',
		showOnMap: 'Show on map',
		addRecord: 'New record',
		editRecord: 'Edit record',
		copyRecord: 'Copy record',
		update: 'Update',
		send: 'Send',
		sending: 'Sending',
		export: 'Export',
		import: 'Import',
		confirm: 'Confirm?',
		squareKm: 'sq.km',
		km: 'km',
		ga: 'ha',
		all: 'All',
		off: 'Off',
		date: 'Date/Time',
		date1: 'Date (from)',
		date2: 'Date (to)',
		age1: 'Age (from)',
		age2: 'Age (to)',
		attachment: 'attachment',
		attachments: 'attachments',
		default: 'Default',
		count: 'Count',
		count_short: 'Count',
		copy: 'Copying',
		mainInfo: 'Main info',
		dateTime: 'Date/time',
		dateFrom: 'from',
		at: 'at',
		downloadExcel: 'Export to excel',
		no: 'no',
		opacity: 'Opacity',
		module: {
			fire: 'Situation monitoring',
		},
		day: {
			sunday: 'Sunday',
			monday: 'Monday',
			tuesday: 'Tuesday',
			wednesday: 'Wednesday',
			thursday: 'Thursday',
			friday: 'Friday',
			saturday: 'Saturday',
		},
		month: {
			january: 'January',
			february: 'February',
			march: 'March',
			april: 'April',
			may: 'May',
			june: 'June',
			july: 'July',
			august: 'August',
			september: 'September',
			october: 'October',
			november: 'November',
			december: 'December',
		},
		validation: {
			REQUIRED_FIELDS: 'Not all required fields are filled',
			REQUIRED_CREWTYPE: 'Crew type is required',
			REQUIRED_EXTINGUISHING_AGENTS_TYPE: 'Extinguishing agents type is required',
			ALREADY_EXIST: 'Record already exist',
			INCORRECT_AIR: 'Wrong Air value',
			INCORRECT_EVACUATED: 'Wrong evacuated value',
		},
		dictionaries: {
			title: 'Handbook',
			plural: 'Handbooks',
			group: {
				title: 'Handbook group',
				norm: 'Reference information and information on regulations',
				fires: 'Fire registry',
				air: 'Aircraft flight',
				weather: 'Weather station',
				other: 'Other',
				region: 'Regional',
			},
		},
		code: 'Code',
		
		externalCode: 'External Code',
		weatherScaleValue: {
			title: 'Weather scale value',
			plural: 'Weather scale values',
			startDate: 'startDate',
			endDate: 'endDate',
			KPO1: 'KPO1',
			KPO2: 'KPO2',
			KPO3: 'KPO3',
			KPO4: 'KPO4',
		},
		forestUseType: {
			title: 'Forest use Type',
			plural: 'Forest use Typeы',
		},
		staffPosition: {
			title: 'Staff position',
			plural: 'Staff positions',
		},
		paymentGroup: {
			title: 'Payment group',
			plural: 'Payment group',
		},
		report: {
			title: 'Report',
			plural: 'Reports',
			group: 'Report group',
			type: 'Report type',
			download: 'Download report',
			downloadLO1: 'Download 1LO.Section 1',
			section: 'Section',
			date1: 'Date (from)',
			date2: 'Date (to)',
			inProgress: 'In progress...',
			name: 'Report name',
			summary: 'Summary',
			detailed: 'Detailed',
		},
		forceAvailability: {
			title: 'Forces and resources',
			plural: 'Forces and resources',
			newDate: 'Record date',
			copyWarning: 'Copy only saved data',
		},
		auth: {
			title: 'Authorization',
			login: 'Login',
			order: 'Get access',
			insertLogin: 'Enter login',
			insertPassword: 'Enter the password',
			register: 'Registration',
			logout: 'Logout',
			forgotPassword: 'Forgot your password?',
			recover: 'Recover',
			required: 'Required',
			wait: 'Wait for',
			seconds: 'seconds',
		},
		email: {
			title: 'E-mail',
			hint: 'mail@example.com',
			field: 'E-mail',
			input: 'Enter your E-mail',
		},
		password: {
			title: 'Password',
			hint: 'Password...',
			input: 'Enter the password',
			new: 'Enter new password',
			repeat: 'Repeat password ',
			newHint: 'If you dont want to change password leave the fields empty',
			empty: 'Enter the password in both fields',
			noMatch: 'Passwords do not match',
			match: 'Passwords match',
		},
		person: {
			firstName: 'First name',
			lastName: 'Last name',
			middleName: 'Middle name',
			fullName: 'Full name',
		},
		user: {
			title: 'User',
			plural: 'Users',
			create: 'New user',
			lastAuthDate: 'Last authorization date',
			profile: 'Profile',
			email: 'E-mail',
			emailVerified: 'E-mail confirmed',
			name: 'Login',
			firstName: 'First name',
			lastName: 'Last name',
			middleName: 'Middle name',
			leftComment: 'posted a comment',
			createdTask: 'created a task',
			telegram: 'Telegram ID',
			username: 'Login',
			password: 'Password',
			avatar: 'Avatar',
			fullName: 'Full name',
			error: {
				email: {
					custom: {
						email: 'Wrong e-mail',
					},
					uniqueness: 'Email address already registered',
					verifySent: 'To verify the data provided we have sent you a confirmation email',
				},
			},
		},
		role: {
			title: 'Role',
			plural: 'Roles',
			create: 'New role',
			created: 'Created',
			modified: 'Modified',
			name: 'Code',
			description: 'Description',
			isSaving: 'Saving...',
		},
		acl: {
			title: 'ACL',
			plural: 'ACLs',
			create: 'New ACL',
			principalType: 'Principal type',
			app: 'App',
			role: 'Role',
			user: 'User',
			accessType: 'Access type',
			read: 'Read',
			write: 'Write',
			execute: 'Execute',
			all: 'All',
			principalId: 'Principal id',
			model: {
				title: 'Model',
				user: 'Users',
				project: 'Projects',
				person: 'Persons',
				description: 'Description',
			},
			permission: 'Permission',
			allow: 'Allow',
			deny: 'Deny',
			property: 'Property',
			allowAll: 'Allow all',
			list: 'Permission list',
		},
		roleMapping: {
			title: 'Role mapping',
			plural: 'Role mapping',
			create: 'New role mapping',
		},
		comments: {
			title: 'Comment',
			plural: 'Comments',
			placeholder: 'Type a comment',
			submit: 'Submit',
		},
		label: {
			title: 'Label',
			plural: 'Labels',
			create: 'New label',
		},
		file: {
			title: 'Attachment',
			plural: 'Attachmens',
			uploadFile: 'Upload attachment',
			uploadImage: 'Upload image',
			uploadStart: 'Uploading begins',
			uploading: 'Uploading...',
			uploadComplete: 'Upload completed',
			notSelected: 'Attachment not selected',
			willBeUploaded: 'Will be uploaded',
			wrongType: 'Wrong attachment type',
		},
		organization: {
			title: 'Organization',
			plural: 'Organizations',
			name: 'Name',
			shortName: 'Short name',
			ownershipForm: 'Form of ownership ',
			address: 'Legal address',
			headName: 'Full name of the Head',
			headPosition: 'Position of the Head',
			inn: 'TIN',
			ogrn: 'PSRN',
			kpp: 'TRRC',
			comment: 'Comment',
			externalId: 'ID of organization in the external system',
			externalData: 'Data of organization in the external system',
		},
		appeal: {
			title: 'Appeal',
			plural: 'Appeals',
			kanban: 'Registry',
			date: 'Appeal date',
			endDate: 'Processing date',
			status: 'Appal status',
			system: 'Source system',
		},
		appealStatus: {
			title: 'Appeal status',
			plural: 'Appeal statuses',
			default: 'Default',
			closing: 'Closing',
		},
		appealSystem: {
			title: 'Source system',
			plural: 'Source systems',
		},
		appealCategory: {
			title: 'Appeal category',
			plural: 'Appeal categories',
		},
		period: {
			select: 'Period',
			day: 'Day',
			week: 'Week',
			month: 'Month',
			quarter: 'Quarter',
			year: 'Year',
			everyday: 'Everyday',
			everyweek: 'Every week',
			everymonth: 'Every month',
			lastDayOfMonth: 'Last day of month',
			dayOfMonth: 'of each month`',
		},
		stats: {
			title: 'Statistic',
			catAvg: 'Average duration of processing appeals by category',
			orgAvg: 'Average duration of processing appeals by organization',
			orgCount: 'Number of appeals by organization',
			sources: 'Appeals by sources',
			heatmap: 'Heat map',
		},
		audit: {
			title: 'Audit',
			date: 'Date/time',
			model: 'Model',
			description: 'Description',
			action: 'Action',
			create: 'Create',
			delete: 'Delete',
			update: 'Update',
			select: 'Select record',
			data: 'Record data',
			owner: 'Owner',
			startDate: 'Date (from)',
			endDate: 'Date (to)',
			hidden: '*hidden*',
		},
		heatPoint: {
			title: 'Heat point',
			plural: 'Heat points',
			source: 'Satellite',
			period: 'Period',
			periodHint: 'The maximum period is limited to one year. If it is exceeded, the dates will be adjusted.',
			reload: 'Reload',
			brightness: 'Brightness (in Kelvin)',
			dif: 'dif',
			eurasia: 'Eurasia',
			today: 'today',
			yesterday: 'yesterday',
			earlier: 'earlier',
			dayNight: 'Day/Night',
			frp: 'FRP',
			scan: 'Scan',
			track: 'Track',
			createFire: 'Add fire',
		},
		fire: {
			popup: {
				mainInfo: 'Main info',
				vpp: 'Runway info',
				activity: 'Involved in extinguishing',
			},
			allotmentNumber: 'Allotment number',
			id: 'SM Number',
			delete: 'Delete fire',
			fieldsSelected: 'Fields selected',
			fireNumber: 'Fire number',
			fireAct: 'Fire act',
			distances: 'Distance',
			numbers: 'Numbers',
			meteo: 'Meteo info',
			extinguishingCosts: 'Extinguishing costs',
			forestFireAct: 'Forest fire act',
			forestryDamage: 'Forestry damage and costs',
			cityAndAirfield: 'City and Airfield',
			dynamicDate: 'Date',
			new: 'New fire',
			name: 'Name',
			shortName: 'Short name',
			status: 'Status',
			title: 'Fire',
			plural: 'Fires',
			short: 'Fire',
			registry: 'Registry',
			map: 'Map',
			add: 'Add',
			addForm: 'Add fire',
			mainInfo: 'Main info',
			resources: 'Forces and resources',
			secondaryInfo: 'Additional information',
			jumpings: 'Jumping',
			descents: 'Descents',
			delivered: 'Cargo delivered, ton',
			deliveredAvia: 'Including delivered by air, ton',
			threat: 'City threat',
			fireManager: 'Head of extinguishing',
			number: '№',
			numberLong: 'Fire number',
			created: 'Fire card',
			geo: 'Geometry',
			coordinates: 'Coordinates',
			coordsAndDistances: 'Coordinates and distance',
			cityAzimuth: 'City azimuth',
			cityDistance: 'City distance',
			airfieldAzimuth: 'Airfield azimuth',
			airfieldDistance: 'Airfield distance',
			aviaDepAzimuth: 'Air division azimuth',
			aviaDepDistance: 'Air division distance',
			transportRoutesDistance: 'Transport routes distance',
			fromMap: 'Draw',
			time: 'Time',
			detectDate: 'Detection date',
			reportDate: 'Message date',
			startDate: 'Extinguishing start date',
			lastEditDate: 'Last modified',
			lastEditPerson: 'Who made the change',
			extinguishedDate: 'Extinguishing date',
			localizationDate: 'Localization date',
			dynamic: 'Firelog',
			createDynamic: 'Create new firelog',
			edge: 'Edge, km',
			edgeShort: 'Edge',
			protectiveArea: 'Protective area, ha',
			protectiveAreaShort: 'Protective',
			operationalArea: 'Operational area, ha',
			operationalAreaShort: 'Operational',
			reserveArea: 'Reserve area, ha',
			reserveAreaShort: 'Reserve',
			soilArea: 'Soil area, ha',
			soilAreaShort: 'Soil',
			upperArea: 'Upper area, ha',
			upperAreaShort: 'Upper',
			lowerArea: 'Lower area, ha',
			lowerAreaShort: 'Lower',
			forestArea: 'Forest area, ha',
			forestAreaShort: 'Forest',
			nonForestArea: 'Non-forest area, ha',
			nonForestAreaShort: 'Non-forest',
			coverArea: 'Covered area, ha',
			coverAreaShort: 'Covered',
			nonCoverArea: 'Uncovered area, ha',
			nonCoverAreaShort: 'Uncovered',
			activeBurningArea: 'Active burning area, ha',
			activeBurningAreaShort: 'Burning area',
			description: 'Description',
			startDrawing: 'Paint',
			finishDrawing: 'Finish',
			showCounties: 'Show counties',
			showForestryQuarters: 'Show forestry quarters',
			area: 'Total area, ha',
			areaKm: 'Total area, sq.km',
			areaShort: 'Total area',
			areaTitle: 'Forest fire area, ha',
			leasedArea: 'Leased area, ha',
			geoChanged: 'Geometry is modified',
			forestOwnership: 'Forest ownership',
			combustionIntensity: 'Combustion intensity',
			combustionPattern: 'Combustion pattern',
			combustionType: 'Combustion type',
			combustionCharacteristic: 'Combustion characteristic',
			decisionDetailsOpb: 'Details of the decision of the CoES and OPB of the Republic of Sakha (Yakutia)',
			date: 'Date',
			protocolNumber: 'Protocol number',
			forestryPrecinct: 'Forestry precinct',
			cause: 'Fire cause',
			zone: 'Monitoring zone',
			forestryFireNumber: 'Forestry number',
			aviaDepFireNumber: 'Air division number',
			bigFireNumber: 'Big fire number',
			isdmFireNumber: 'ISDM number',
			isdmSync: 'Synchronization with ISDM',
			notLandingReason: 'Reason for not landing',
			notInspectionReason: 'Reason for not inpection',
			windSpeed: 'Wind speed',
			extinguishingCostsSubject: 'Subject',
			rentBC: 'Rent BC',
			totalCost: 'Total cost',
			actDate: 'Act date',
			actPercent: '% act',
			compiler: 'Compiler',
			woodLoss: 'Wood loss',
			youngWoodLoss: 'Young wood loss',
			burnedWood: 'Burned or damaged wood',
			burnedHarvestedWood: 'Burned or damaged harvested wood',
			KPOdetected: 'KPO detection',
			KPOliquidation: 'KPO liquidation',
			frontalPrecipitation: 'Frontal precipitation',
			origin: 'Origin fire point',
			pickPoint: 'Point on the map',
			pickPointHint: 'Pick point on the map with your cursor',
			lastDynamic: 'Last firelog date',
			updateMainInfo: 'Auto suggestion',
			updateMainInfoHint: 'Automatically fill some fields with data based on origin point or geometry of the fire',
		},
		dynamic: {
			popup: {
				confirm: 'Confirm',
				message: 'The entered data will be lost',
			},
		},
		notifyRecipient: {
			title: 'Recipient',
			plural: 'Recipients',
			group_filter: 'Recipient group filter',
			group: 'Recipient group',
			name: 'Name',
		},
		notifyRecipientGroup: {
			title: 'Recipient group',
			plural: 'Recipient groups',
			showAll: 'Show all',
		},
		transportData: {
			title: 'Transport data',
			period: 'Period',
			schedule: 'Current schedule',
			report: 'Report',
			nextDate: 'Next mailing date',
			disabled: 'The mailing is inactive',
			active: 'Acitve',
			minute: 'Minute',
			hour: 'Hour',
			day: 'Day of the month',
			dayOfWeek: 'Day of the week',
			subject: 'Subject',
			text: 'Text',
		},
		notLandingReason: {
			title: 'Reason for not landing',
			plural: 'Reasons for not landing',
		},
		notInspectionReason: {
			title: 'Reason for not inpection',
			plural: 'Reasons for not inpection',
		},
		aviaDep: {
			title: 'Air division',
			plural: 'Air divisions',
			location: 'Location',
			active: 'Active',
			area: 'Area',
		},
		forestry: {
			title: 'Forestry',
			plural: 'Forestries',
		},
		landCategory: {
			title: 'Land category',
			plural: 'Land categories',
		},
		fireAnalyticalIndicator: {
			title: 'Analytical indicator of fires',
			plural: 'Analytical indicator of fires',
		},
		tenant: {
			title: 'Tenant',
			plural: 'Tenants',
		},
		county: {
			title: 'County',
			plural: 'Counties',
		},
		city: {
			title: 'City',
			plural: 'Cities',
			pluralShort: 'Cities',
			add: 'Add city',
			okato: 'OKATO code',
			fireDanger: 'Fire danger',
			population: 'Population',
		},
		crewType: {
			title: 'Crew type',
			title_short: 'Crew type',
			plural: 'Crew types',
		},
		extinguishingAgentsType: {
			title: 'Type of extinguishing agent',
			title_short: 'Extinguishing agent',
			plural: 'Extinguishing agents',
		},
		discoveryMessageType: {
			title: 'Discovery message',
			plural: 'Discovery message types',
		},
		breed: {
			title: 'Breed',
			plural: 'Breeds',
		},
		cover: {
			title: 'Forest cover',
			plural: 'Forest covers',
		},
		forestCategory: {
			title: 'Forest category',
			plural: 'Forest categories',
		},
		forestOwnership: {
			title: 'Forest ownership',
			plural: 'Forest ownerships',
			short: 'Ownership',
		},
		fireCause: {
			title: 'Fire cause',
			plural: 'Fire causes',
		},
		fireStatus: {
			title: 'Fire status',
			plural: 'Fire statuses',
			selected: 'Selected statuses',
			codes: {
				abandoned: 'Abandoned',
				extinguished: 'Extinguished',
				resumed: 'Resumed',
				localized: 'Localized',
				notspreading: 'Not spreading',
				weakens: 'Weakens',
				continues: 'Continues',
				intensifies: 'Intensifies',
				active: 'Active',
				zik: 'Other categories of land',
			},
		},
		determiningMethod: {
			title: 'Detection method',
			plural: 'Detection methods',
		},
		combustionType: {
			title: 'Combustion type',
			plural: 'Combustion types',
		},
		combustionIntensity: {
			title: 'Combustion intensity',
			plural: 'Combustion intensities',
		},
		combustionPattern: {
			title: 'Combustion pattern',
			plural: 'Combustion patterns',
		},
		airRaid: {
			title: 'Air raid',
			plural: 'Air raids',
			pluralLong: 'Air raid',
			add: 'Add air raid',
			requestNumber: 'Application number',
			requestDate: 'Application date',
			departureDate: 'Departure date',
			arrivalDate: 'Arrival date',
			mainInfo: 'Main info',
			addContract: 'add contract',
			noContracts: 'No active contracts',
			startDate: 'Date (from)',
			endDate: 'Date (to)',
			pickAirRaid: 'Select air raid',
		},
		airJob: {
			title: 'Aerial work',
			plural: 'Aerial works',
			add: 'Add Aerial work',
			duration: 'Duration time',
			duration_short: 'Duration',
			drains: 'Drains',
			effects: 'Effects',
			jumpings: 'Training jumps',
			jumpings_short: 'Jumps',
			descents: 'Training descents',
			descents_short: 'Descents',
		},
		airJobType: {
			title: 'Aerial work type',
			plural: 'Aerial work types',
		},
		staff: {
			title: 'Staff',
			plural: 'Staff',
			number: '№',
			fullName: 'Full name',
			category: 'Category',
		},
		unit: {
			title: 'Unit',
			plural: 'Units',
			nationalSymbol: 'Symbol (national)',
			internationalSymbol: 'Symbol (international)',
			nationalCode: 'Code designation (national)',
			internationalCode: 'Code designation (international)',
		},
		contract: {
			title: 'Contract',
			plural: 'Contracts',
			number: '№',
			date: 'Date',
			term: 'Validity period',
		},
		contractor: {
			title: 'Contractor',
			plural: 'Contractors',
		},
		vehicleType: {
			title: 'Vehicle type',
			plural: 'Vehicles',
		},
		aircraftType: {
			title: 'Aircraft type',
			plural: 'Aircraft types',
			group: 'Group',
			class: 'Class',
			speed: 'Speed',
			fuelConsumption: 'Fuel consumption',
			fuelSupply: 'Fuel reserve',
			runwayLength: 'Runway length',
			aeronauticalSupply: 'Aeronautical reserve',
		},
		weatherStation: {
			title: 'Weather station',
			plural: 'Weather stations',
			latitude: 'Latitude',
			longitude: 'Longitude',
			activity: 'Active',
		},
		airfieldRunway: {
			title: 'Airfield runway',
			plural: 'Airfield runways',
			add: 'Add airfield runway',
			size: 'Sizes',
			number: '№',
			class: 'Class',
			pcn: 'PCN',
			utpzCategory: 'USPZ category',
			icaoCategory: 'ICAO category',
			coverage: 'Coverage',
			magneticBearing: 'Magnetic landing course',
			lightingSystem: 'Lighting equipment',
		},
		airfield: {
			title: 'Airfield',
			plural: 'Airfields',
			name: 'Name',
			add: 'add airfield',
			icaoCode: 'The ICAO code',
			icaoLandingCategory: 'Landing category according to ICAO',
			localTime: 'Local time',
			latitude: 'Reference point: latitude',
			longitude: 'Reference point: longitude',
			exceeding: 'Exceeding the airfield',
			magneticDeclination: 'Magnetic declination',
			iataCode: 'IATA Airport Code',
		},
		fireFightingVehicle: {
			title: 'Firefighting equipment',
			plural: 'Firefighting equipments',
			plan: 'Plan',
			required: 'Required',
			current: 'Current',
			current_short: 'Current',
			date: 'Date',
		},
		fireFightingPeople: {
			title: 'Firefighting force',
			plural: 'Firefighting forces',
			plan: 'Plan',
			required: 'Required',
			current_short: 'Current',
			evacuated_short: 'Evacuated',
			delivered: 'Delivered',
			air_short: 'Air',
		},
		fireFightingFormation: {
			title: 'Firefighting formation',
			plural: 'Firefighting formations',
			title_short: 'Formation',
		},
		FireCharacteristics: {
			location: 'Terrain characteristics',
		},
		observerPilot: {
			title: 'Observer pilot',
			plural: 'Observer pilots',
			inspector: 'Inspector',
			intern: 'Intern',
			add: 'Add observer pilot',
		},
		aircraftKind: {
			title: 'Kind of flying machine',
			title_short: 'Kind of FM',
			plural: 'Kind of flying machines',
		},
		silam: {
			title: 'Air pollution',
			on: 'Show',
		},
		monitoringZone: {
			title: 'Monitoring zone',
			plural: 'Monitoring zones',
			bigFireLimit: 'Big fire limit, ha',
		},
		emergencySituation: {
			title: 'Emergency mode',
			plural: 'Emergency modes',
			startAct: 'Implementation act number',
			endAct: 'Removal act number',
			republicStatus: 'On the entire territory of the Republic',
			comment: 'Note',
			startDate: 'Implementation date',
			endDate: 'Removal date',
			type: 'Type',
			counties: 'Counties',
		},
		emergencySituationDamage: {
			title: 'Damage from the emergency mode',
			title_short: 'Damage',
			plural: 'Damage from the emergency modes',
			dead: 'Dead',
			injured: 'Injured',
			affectedObjects: 'Affected objects',
			comment: 'Comment',
		},
		emergencySituationType: {
			title: 'Emergency situation type',
			plural: 'Emergency situation types',
		},
		requestLog: {
			title: 'Request log',
			plural: 'Request logs',
			number: '№',
			date: 'Date',
			location: 'Location',
			description: 'Description',
			measures: 'Measures',
			result: 'Result',
			contactInfo: 'Contact info',
			messageType: 'Message type',
			operator: 'Operator',
			processed: 'Processed',
		},
		fireReport: {
			title: 'Fire report',
			plural: 'Fire reports',
			coordinats: 'Coordinates of the fire',
			tenant: 'Tenant',
			localization: 'Localization/extinguished date',
			peopleTypes: 'Additional types of people',
			stayed: 'People at the end of the day',
			year: 'Year',
			month: 'Month',
			groupBy: 'Group by',
			nonForestArea: 'Non-forest area',
			noAviaDep: 'No air division',
			noCounty: 'No county',
			noForestry: 'No forestry',
		},
		forestryQuarter: {
			title: 'Forestry quarter',
			short: 'quarter',
			plural: 'Forestry quarters',
			area: 'area sq.km.',
			add: 'Add quarter',
			name: 'Name',
			code: 'Code',
			forestry: 'Forestry',
			forestryPrecinct: 'Forestry precinct',
			forestCategory: 'Forestry category',
			aviaDep: 'Air division',
			monitoringZone: 'Monitoring zone',
		},
		forestryPrecinct: {
			title: 'Forestry precinct',
			plural: 'Forestry precincts',
			short: 'Forestry prec.',
		},
		aircraft: {
			title: 'Aircraft',
			plural: 'Aircrafts',
			number: 'Aircraft number',
			number_short: '№',
		},
		weather: {
			title: 'Weather',
			temp: 'Temperature',
			Temperature: 'Temperature at a height of 2 m above the ground',
			tempMeasure: '°C',
			pressure: 'Pressure',
			pressureDescription: 'Atmospheric pressure',
			pressureMeasure: 'mmHg.',
			precipitation: 'Precipitation',
			precipitationDescription: 'Accumulation of precipitation in 3 hours',
			precipitationMeasure: 'kg/m2',
			humidity: 'Humidity',
			humidityDescription: 'Relative humidity at a height of 2 m above the ground',
			humidityMeasure: '%',
			pm25: 'PM2.5',
			pm25Description: 'Suspended solid microparticles and the smallest droplets of liquid with a diameter of 2.5 microns or less',
			pm25Measure: 'mcg/m3',
			pm10: 'PM10',
			pm10Description: 'Suspended solid microparticles and the smallest droplets of liquid with a diameter of 10 microns or less',
			pm10Measure: 'mcg/m3',
			co: 'CO',
			coDescription: 'Description of the CO layer',
			coMeasure: 'mcg/m3',
			no: 'NO',
			noDescription: 'Description of the NO layer',
			noMeasure: 'mcg/m3',
			no2: 'NO2',
			no2Description: 'Description of the NO2 layer',
			no2Measure: 'mcg/m3',
			ozone: 'Ozone',
			ozoneDescription: 'Description of the O3 layer',
			ozoneMeasure: 'DU',
			so2: 'SO2',
			so2Description: 'Description of the SO2 layer',
			so2Measure: 'kg/m2',
			feeling: 'Feels like',
		},
		firepopup: {
			fire: 'Fire',
			point: 'Heat point',
			info: 'Info',
			weather: 'Weather',
		},
		wind: {
			title: 'Wind',
			speedFactor: 'Speed',
			fadeOpacity: 'Fade opacity',
			particleLife: 'Particle life',
			opacity: 'Opacity',
		},
		notifyStatus: {
			title: 'Notification status',
			plural: 'Notification statuses',
			status: 'Status',
		},
		notifyType: {
			title: 'Notification type',
			plural: 'Notification types',
			type: 'Type',
			default: 'Default',
			select: 'Select notification type',
			active: 'Active API',
		},
		notifyTemplate: {
			title: 'Notification template',
			plural: 'Notification templates',
			template: 'Template',
			noTemplates: 'No templates',
		},
		notifyLog: {
			title: 'Notification log',
			plural: 'Notification logs',
			text: 'Text',
			phone: 'Recipient phone',
			recipient: 'Recipient',
			date: 'Notification date',
			add: 'New Notification',
			pickTemplate: 'Select template',
			mailing: 'Informing',
			recipientsCount: 'Number of recipients',
			notSent: 'Not sent',
			noApi: 'The API is not connected',
			accepted: 'Accepted',
			notAccepted: 'Not accepted',
			acceptedPlaceholder: 'Confirmation status',
		},
		notify: {
			massNotify: 'To confirm the mass mailing, you need to enter the code at the end of the form',
			code: 'Confirmation code',
		},
		case: {
			mainDate: 'Main info',
			messages: 'Messages',
			sendMessage: 'Send message',
			notifyFilter: 'Notify filters',
			noCounty: 'No county',
		},
		camera: {
			title: 'Camera',
			plural: 'Cameras',
			error: 'Connection error',
			maximize: 'Maximize',
			minimize: 'Minimize',
		},
		dzz: {
			title: 'Earth remote sensing',
		},
		genShtab: {
			title: 'General Staff of the USSR',
		},
		dashboard: {
			title: 'Dashboard',
			total: 'Total',
			active: 'Total active fires',
			fires: 'Fires',
			dynamics: 'Dynamics of Situation',
			cityDanger: 'Threat to settlements',
			area: 'Area, ha',
			totalArea: 'Total area, ha',
			dailyIncrease: 'Daily increase, ha',
			extinguished: 'Extinguished',
			firesCount: 'Number of fires',
			fullReport: 'Full report',
			operationalReport: 'Operational report',
			sectionDescription: 'On the situation with forest firefighters in the Republic of Sakha (Yakutia)',
			underway: 'Work is underway',
			suspended: 'Suspended',
			unattended: 'Unattended',
			activeArea: 'Total fires area, ha',
			extinguishedArea: 'Extinguished area, ha',
			noDanger: 'No threats',
			dailyExtinguished: 'Fires extinguished over the past day',
			dailyDetected: 'Fires detected over the past day',
			dailyLocalized: 'Fires localized over the past day',
			dailyUnderway: 'Fires underway over the past day',
			naturalGrouth: 'Natural grouth of fires',
			naturalGrouthShort: 'Natural grouth',
			personnel: 'Personnel',
			vehicles: 'Technical means',
			seasonFires: 'Fires registered this year',
			currentYear: 'This year',
			lastYear: 'Last year',
			seasonTotalCount: 'Fires',
			seasonTotalArea: 'Total area, ha',
			count: 'Сount',
			citiesCount: 'Cities count',
			distance: 'Distance, km',
			citiesSpecial: 'Under special control',
			citiesUnderway: 'Extinguishing in progress',
			citiesUnattended: 'Within 10 km range',
			citiesSpecialTest: 'Under special control (20 km)',
			citiesUnderwayTest: 'Extinguishing in progress (50 km)',
			citiesUnattendedTest: 'Within 50 km range',
			dynamicsType: {
				forest: 'Forest fires: dynamics of situation',
				oopt: 'Specially protected natural areas: dynamics of situation',
				other: 'Other categories of lands: dynamics of situation',
			},
		},
		address: {
			title: 'Address',
		},
		errand: {
			dashboard: 'Statistics on the execution of orders of the Head of the Republic of Sakha (Yakutia)',
			freeMode: 'Free mode',
			week: 'Week',
			month: 'Month',
			halfYear: 'Half year',
			year: 'Year',
			org: 'Perpetrator',
		},
		map: {
			hpSelectModeDisabled: 'Turn on the heat point layer',
			hpSelectAdd: 'Selection mode: add heat points',
			hpSelectSubtract: 'Selection mode: remove heat points',
			hpSelectRadius: 'Radius around the point',
			metersShort: 'm',
			kmShort: 'km',
			searchHint: 'Search by longitude/latitude',
			export: 'Export',
			exportHint: 'Export to and PNG image',
			exportFormat: 'Format',
			exportResolution: 'Resolution',
		},
		technoZone: {
			title: 'Technogenic zone',
			plural: 'Technogenic zones',
		},
		isdm: {
			title: 'RMIS',
			titleLong: 'Remote monitoring information system',
		},
		color: 'Color',
		add: 'Add',
		smartDistrict: {
			title: 'SmartDistrict',
			map: 'Карта',
		},
	},
};
