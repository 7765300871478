import React from 'react';
import { observer, inject } from 'mobx-react';

import { Checkbox, SlideInput } from '@smartplatform/ui';
import FilterLayout from './FilterLayout';
import store from 'client/store';
import t from 'i18n';

@inject('settings') @observer
export default class WindFilter extends React.Component {

	onToggle = (value) => {
		this.props.settings.wind.webgl = value;
		store.local.save();
		this.props.layer.setVisible();
	};

	onSpeedFactorChange = (value) => {
		this.props.settings.wind.speedFactor = value;
		store.local.save();
		this.props.layer.layer.setSpeedFactor(value);
	};

	onFadeOpacityChange = (value) => {
		this.props.settings.wind.fadeOpacity = value;
		store.local.save();
		this.props.layer.layer.setFadeOpacity(value);
	};

	onParticleLifeChange = (value) => {
		this.props.settings.wind.particleLife = value;
		store.local.save();
		this.props.layer.layer.setParticleLife(value);
	};

	onOpacityChange = (value) => {
		this.props.settings.wind.opacity = value;
		store.local.save();
		this.props.layer.layer.setOpacity(value);
	};

	render() {
		const settings = this.props.settings.wind;
		const windLayer = this.props.layer;

		const isLoading = settings.show && windLayer ? windLayer.isLoading : false;
		const error = settings.show && windLayer ? windLayer.error : undefined;

		return <FilterLayout layerName="wind" onToggle={this.onToggle} title={t('wind.title')} error={error} isLoading={isLoading}>
{/*
			<div className="mb-2">
				<Checkbox
					value={settings.webgl}
					onChange={this.onToggle}
					label={t('webgl')}
				/>
			</div>
*/}
			<div>
				<label>{t('wind.speedFactor')}</label>
				<SlideInput
					value={settings.speedFactor}
					onChange={this.onSpeedFactorChange}
					min={1}
					max={10}
					step={1}
					disabled={!settings.show}
				/>
			</div>
			<div style={{ marginTop: -15 }}>
				<label>{t('wind.fadeOpacity')}</label>
				<SlideInput
					value={settings.fadeOpacity}
					onChange={this.onFadeOpacityChange}
					min={0.980}
					max={0.999}
					step={0.001}
					disabled={!settings.show}
				/>
			</div>
			<div style={{ marginTop: -15 }}>
				<label>{t('wind.opacity')}</label>
				<SlideInput
					value={settings.opacity}
					onChange={this.onOpacityChange}
					min={0.4}
					max={0.9}
					step={0.05}
					disabled={!settings.show}
				/>
			</div>
{/*
			<div className="mb-2">
				<label>{t('wind.particleLife')}</label>
				<SlideInput
					value={settings.particleLife}
					onChange={this.onParticleLifeChange}
					min={64}
					max={255}
					step={1}
					disabled={!settings.show}
				/>
			</div>
*/}
		</FilterLayout>;
	}

}
