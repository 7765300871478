import React from 'react';
import { Fill, Stroke, Style, Icon, Circle as CircleStyle } from 'ol/style';
import { asArray } from 'ol/color';
import { ColorUtils } from '@smartplatform/ui';

import CityPopup from './CityPopup';
import icon from 'client/img/icons/city.svg';
import iconGeo from 'client/img/icons/city-geo.svg';
import t from 'i18n';

const color = '#0af';

export default {
	modelName: 'City',
	title: t('city.title'),
	filterTitle: t('city.plural'),
	showInFilters: true,
	style: new Style({
		fill: new Fill({ color: asArray([...ColorUtils.hexToArray(color), 0.5]) }),
		stroke: new Stroke({ color, width: 1 }),
	}),
	icon,
	pointGeoField: 'pointGeo',
	marker: new Icon({ src: icon, anchor: [4, 4], anchorXUnits: 'pixels', anchorYUnits: 'pixels' }),
	markerGeo: new Icon({ src: iconGeo, anchor: [4, 4], anchorXUnits: 'pixels', anchorYUnits: 'pixels' }),
	priority: 2,
	renderPopup: record => <CityPopup key={record.id} record={record} />,
};
