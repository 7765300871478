/* package vector_tile;

option optimize_for = LITE_RUNTIME;

message Tile {

        // GeomType is described in section 4.3.4 of the specification
        enum GeomType {
             UNKNOWN = 0;
             POINT = 1;
             LINESTRING = 2;
             POLYGON = 3;
        }

        // Variant type encoding
        // The use of values is described in section 4.1 of the specification
        message Value {
                // Exactly one of these values must be present in a valid message
                optional string string_value = 1;
                optional float float_value = 2;
                optional double double_value = 3;
                optional int64 int_value = 4;
                optional uint64 uint_value = 5;
                optional sint64 sint_value = 6;
                optional bool bool_value = 7;

                extensions 8 to max;
        }

        // Features are described in section 4.2 of the specification
        message Feature {
                optional uint64 id = 1 [ default = 0 ];

                // Tags of this feature are encoded as repeated pairs of
                // integers.
                // A detailed description of tags is located in sections
                // 4.2 and 4.4 of the specification
                repeated uint32 tags = 2 [ packed = true ];

                // The type of geometry stored in this feature.
                optional GeomType type = 3 [ default = UNKNOWN ];

                // Contains a stream of commands and parameters (vertices).
                // A detailed description on geometry encoding is located in 
                // section 4.3 of the specification.
                repeated uint32 geometry = 4 [ packed = true ];
        }

        // Layers are described in section 4.1 of the specification
        message Layer {
                // Any compliant implementation must first read the version
                // number encoded in this message and choose the correct
                // implementation for this version number before proceeding to
                // decode other parts of this message.
                required uint32 version = 15 [ default = 1 ];

                required string name = 1;

                // The actual features in this tile.
                repeated Feature features = 2;

                // Dictionary encoding for keys
                repeated string keys = 3;

                // Dictionary encoding for values
                repeated Value values = 4;

                // Although this is an "optional" field it is required by the specification.
                // See https://github.com/mapbox/vector-tile-spec/issues/47
                optional uint32 extent = 5 [ default = 4096 ];

                extensions 16 to max;
        }

        repeated Layer layers = 3;

        extensions 16 to 8191;
}
 */
'use strict'; // code generated by pbf v3.2.1

// Tile ========================================

var Tile = exports.Tile = {};

Tile.read = function (pbf, end) {
    return pbf.readFields(Tile._readField, {layers: []}, end);
};
Tile._readField = function (tag, obj, pbf) {
    if (tag === 3) obj.layers.push(Tile.Layer.read(pbf, pbf.readVarint() + pbf.pos));
};
Tile.write = function (obj, pbf) {
    if (obj.layers) for (var i = 0; i < obj.layers.length; i++) pbf.writeMessage(3, Tile.Layer.write, obj.layers[i]);
};

Tile.GeomType = {
    "UNKNOWN": {
        "value": 0,
        "options": {}
    },
    "POINT": {
        "value": 1,
        "options": {}
    },
    "LINESTRING": {
        "value": 2,
        "options": {}
    },
    "POLYGON": {
        "value": 3,
        "options": {}
    }
};

// Tile.Value ========================================

Tile.Value = {};

Tile.Value.read = function (pbf, end) {
    return pbf.readFields(Tile.Value._readField, {string_value: "", float_value: 0, double_value: 0, int_value: 0, uint_value: 0, sint_value: 0, bool_value: false}, end);
};
Tile.Value._readField = function (tag, obj, pbf) {
    if (tag === 1) obj.string_value = pbf.readString();
    else if (tag === 2) obj.float_value = pbf.readFloat();
    else if (tag === 3) obj.double_value = pbf.readDouble();
    else if (tag === 4) obj.int_value = pbf.readVarint(true);
    else if (tag === 5) obj.uint_value = pbf.readVarint();
    else if (tag === 6) obj.sint_value = pbf.readSVarint();
    else if (tag === 7) obj.bool_value = pbf.readBoolean();
};
Tile.Value.write = function (obj, pbf) {
    if (obj.string_value) pbf.writeStringField(1, obj.string_value);
    if (obj.float_value) pbf.writeFloatField(2, obj.float_value);
    if (obj.double_value) pbf.writeDoubleField(3, obj.double_value);
    if (obj.int_value) pbf.writeVarintField(4, obj.int_value);
    if (obj.uint_value) pbf.writeVarintField(5, obj.uint_value);
    if (obj.sint_value) pbf.writeSVarintField(6, obj.sint_value);
    if (obj.bool_value) pbf.writeBooleanField(7, obj.bool_value);
};

// Tile.Feature ========================================

Tile.Feature = {};

Tile.Feature.read = function (pbf, end) {
    return pbf.readFields(Tile.Feature._readField, {id: 0, tags: [], type: 0, geometry: []}, end);
};
Tile.Feature._readField = function (tag, obj, pbf) {
    if (tag === 1) obj.id = pbf.readVarint();
    else if (tag === 2) pbf.readPackedVarint(obj.tags);
    else if (tag === 3) obj.type = pbf.readVarint();
    else if (tag === 4) pbf.readPackedVarint(obj.geometry);
};
Tile.Feature.write = function (obj, pbf) {
    if (obj.id) pbf.writeVarintField(1, obj.id);
    if (obj.tags) pbf.writePackedVarint(2, obj.tags);
    if (obj.type) pbf.writeVarintField(3, obj.type);
    if (obj.geometry) pbf.writePackedVarint(4, obj.geometry);
};

// Tile.Layer ========================================

Tile.Layer = {};

Tile.Layer.read = function (pbf, end) {
    return pbf.readFields(Tile.Layer._readField, {version: 0, name: "", features: [], keys: [], values: [], extent: 0}, end);
};
Tile.Layer._readField = function (tag, obj, pbf) {
    if (tag === 15) obj.version = pbf.readVarint();
    else if (tag === 1) obj.name = pbf.readString();
    else if (tag === 2) obj.features.push(Tile.Feature.read(pbf, pbf.readVarint() + pbf.pos));
    else if (tag === 3) obj.keys.push(pbf.readString());
    else if (tag === 4) obj.values.push(Tile.Value.read(pbf, pbf.readVarint() + pbf.pos));
    else if (tag === 5) obj.extent = pbf.readVarint();
};
Tile.Layer.write = function (obj, pbf) {
    if (obj.version) pbf.writeVarintField(15, obj.version);
    if (obj.name) pbf.writeStringField(1, obj.name);
    if (obj.features) for (var i = 0; i < obj.features.length; i++) pbf.writeMessage(2, Tile.Feature.write, obj.features[i]);
    if (obj.keys) for (i = 0; i < obj.keys.length; i++) pbf.writeStringField(3, obj.keys[i]);
    if (obj.values) for (i = 0; i < obj.values.length; i++) pbf.writeMessage(4, Tile.Value.write, obj.values[i]);
    if (obj.extent) pbf.writeVarintField(5, obj.extent);
};

