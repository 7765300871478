import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import t from 'i18n';
import User from './User';
import Language from './Language';
// import ModuleSelect from './module-select';
import { getRoleNames } from 'client/tools';
import logo from 'client/img/logo.svg';
import signOut from 'client/img/icons/signOut.svg';
import './style.scss';
import store from 'client/store';
import TestClicker from './TestClicker';

@observer
export default class Header extends React.Component {
	@observable isAdmin = false;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const roleNames = getRoleNames();
		if (roleNames.includes('admin')) this.isAdmin = true;
	};

	logout = (e) => {
		e.preventDefault();
		store.model.logout();
	};

	render() {
		return (
			<header className='main-header'>
				<div className='left'>
					<div className='site-title'>
						<Link to='/'>
							<img className='logo' src={logo} width={28} height={50} alt='' />
							<div className='title'>{t('title')}</div>
						</Link>
					</div>
					{/*<ModuleSelect />*/}
				</div>
				<div className='right'>
					<User /> |
					<Language /> |
					{this.isAdmin && (
						<div className='admin-link'>
							<Link to='/admin'>Admin</Link>
						</div>
					)}
					<a href='#' onClick={this.logout}>
						<img className='signOut' src={signOut} width={28} height={28} alt='signOut' />
					</a>
				</div>
				<TestClicker />
			</header>
		);
	}
}
