import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Form, Field, RelationSelect } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

@observer
export default class MapLayer extends React.Component {
	@observable record = null;

	constructor(props) {
		super(props);
		this.init();
	}

	async init() {
		const id = parseInt(this.props.match.params.id);
		if (id) {
			this.record = await store.model.MapLayer.findById(id, { include: ['category'] });
		} else {
			this.record = new store.model.MapLayer();
		}
	}

	render() {
		if (!this.record) return '...';

		return (
			<div className='layer-category-edit'>
				<h1>{t('MapLayer.title')}</h1>
				<Form record={this.record} returnTo={`/smartdistrict/dictionaries/MapLayer`}>
					<Field property='name' label={t('name')} />
					<Field
						relation='category'
						property='name'
						label={t('MapLayer.categoryName')}
					>
						<RelationSelect noTotal maxItems={10} itemsPerPage={1000} />
					</Field>
				</Form>
			</div>
		);
	}
}
