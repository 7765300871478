import React from 'react';
import PropTypes from 'prop-types';
import { observable, toJS } from 'mobx';
import { observer } from 'mobx-react';

import List from './List';
import kStore from './store';

const project = {
	name: 'Project',
	//...any data
	lists: [
		{
			id: 1, // обязательно
			name: 'list1',
			color: '#0af',
			data: {}, // any data
			items: [
				{
					id: 1, // обязательно
					listId: 1,
					path: '/tasks/1',
					data: {}, // any data
				},
				{
					id: 2,
					listId: 1,
					path: '/tasks/2',
					data: {}, // any data
				},
			]
		}
	]
}

@observer
export default class Kanban extends React.Component {

	static propTypes = {
		project: PropTypes.object.isRequired,
		renderItem: PropTypes.any.isRequired,
		itemClassName: PropTypes.string,
		itemsLimit: PropTypes.number,
		onChange: PropTypes.func,
		loadMore: PropTypes.func,
		onError: PropTypes.func,
		exportAll: PropTypes.func,
		exportList: PropTypes.func,
	};
	
	static defaultProps = {
		itemsLimit: 20,
	};
	
	constructor(props) {
		super(props);
		if (this.props.instance) this.props.instance({
			reload: this.reload,
		});
		this.reload();
	}
	
	reload = () => {
		kStore.init(this.props);
	};
	
	render() {
		if (!kStore.project) return null;
		
		const { renderItem, itemClassName, loadMore } = this.props;
		
		return <div className="kb-container">
			{kStore.project.lists.map((list, i) => (
				<List
					key={i}
					list={list}
					renderItem={renderItem}
					loadMore={loadMore}
					itemClassName={itemClassName}
				/>
			))}
			
		</div>;
	}
	
}
